<template>
  <div class="container-live">
    <div class="return" @click="OnClickReturn">返回</div>
    <div class="nav">
      <div class="timeBox">
        <div class="inputBoxLeft">
          <select
            class="liveSelectList"
            name="select1"
            id="selectId1"
            v-model="categorySelectId"
            @change="getSubjectList()"
          >
            <option disabled value="">选择分类</option>
            <option
              :value="item.CategoryId"
              v-for="(item, index) in categoryList"
              :key="index"
            >
              {{ item.CategoryName }}
            </option>
          </select>
        </div>
        <div class="inputBoxLeft">
          <select
            class="liveSelectList"
            name="select2"
            id="selectId2"
            v-model="subjectSelectId"
          >
            <option
              :value="item2.SubjectId"
              v-for="(item2, index2) in subjectList"
              :key="index2"
            >
              {{ item2.SubjectName }}
            </option>
          </select>
        </div>
        <div class="inputBoxLeft">
          <input
            class="inputBox inputBox1"
            type="text"
            name="calendar2"
            :value="liveTime"
            placeholder="直播时间"
            @click="showCalendar = true"
            readonly
          />
          <van-calendar
            v-model="showCalendar"
            @confirm="onConfirm"
            color="#004995"
            title="选择直播时间"
            :min-date="minDate"
            :max-date="maxDate"
            row-height="40"
          />
        </div>
      </div>
      <button class="searchBox" @click="onSearch">搜索</button>
    </div>
    <div class="form" v-for="(item, index) in liveList" :key="index">
      <div class="list">
        <div class="listLeft">科目级别</div>
        <div class="listRight">{{ item.SubjectType }}</div>
      </div>
      <div class="list">
        <div class="listLeft">课程</div>
        <div class="listRight">{{ item.CourseName }}</div>
      </div>
      <div class="list">
        <div class="listLeft">直播名称</div>
        <div class="listRight">{{ item.LiveName }}</div>
      </div>
      <div class="list">
        <div class="listLeft">讲师</div>
        <div class="listRight">{{ item.LecturerName }}</div>
      </div>
      <div class="list">
        <div class="listLeft">直播时间</div>
        <div class="listRight">{{ item.StartDate| timeDate }} - {{ item.EndDate| HHMMSST }}  </div>
      </div>
      <div class="buttonBox">
        <button class="bottomButton" @click="initiateEvaluation(item)">
          发起评价
        </button>
      </div>
    </div>

  </div>
</template>
<script>
import router from "@/router/index.js";
import { Notify } from "vant";
import {post,get} from '@/request/http'
import {transformTimestamp,HHMMSS} from '@/common/common';
import {GetCategory,GetSubject,GetLiveBrodcastList,PublishAppraise}  from '@/request/api'
export default {
  name: "search",
  data() {
    return {
      liveTime: "",
      showCalendar: false,
      minDate: new Date(2012, 1, 1),
      maxDate: new Date(),
      categorySelectId:"",
      subjectSelectId:"",
      categoryList:[],
      subjectList:[],
      liveList:[]

    };
  },
  components: {},
  mounted() {
    this.getCategoryList();
  },
  filters:{
    timeDate(time){
      return transformTimestamp(time);
    },
    HHMMSST(time){
      return HHMMSS(time);
    }
  },
  methods: {
    onConfirm(date) {
      this.liveTime = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
      this.showCalendar = false;
    },
    //获取分类
    getCategoryList(){
      get(GetCategory,{}).then(res=>{
        this.categoryList = res;
      },err=>{
          console.log(err)
      }).catch()
    },
    //获取科目
    getSubjectList(){
      this.subjectSelectId = "";
      this.subjectList = [];
      get(GetSubject,{params: {CategoryId: this.categorySelectId,}}).then(res=>{
        this.subjectList = res;
      },err=>{
          console.log(err)
      }).catch()
    },
    
    // 点击搜索
    onSearch() {
      if (this.liveTime !=="") {
        get(GetLiveBrodcastList,{params: {SubjectId:this.subjectSelectId,LiveDate:this.liveTime}}).then(res=>{
          this.liveList = res;
        },err=>{
          console.log(err)
        }).catch()
      }else{
        Notify({
          type: "warning",
          duration: 2500,
          message: "请选择日期",
        });
      }
    },
    // 发起评价
    initiateEvaluation(item){
      if (item.LiveId!=="" && item.LiveId!==null) {
        post(PublishAppraise+"?LoginId="+sessionStorage.getItem('token')+'&LiveId='+item.LiveId,{}).then(res=>{
          if (res.code == '0') {
            Notify({
              type: "success",
              duration: 2500,
              message: "发起评价成功！",
            });
          } else {
            Notify({
              type: "warning",
              duration: 2500,
              message: "发起评价失败！",
            });
          }
        },err=>{
          console.log(err)
        }).catch()
      }
    },
    OnClickReturn() {
      router.go(-1); //返回上一层
    },
  },
};
</script>
<style>
* {
  padding: 0;
  margin: 0;
}
.container-live {
  width: 100%;
  height: 100%;
  border: 1px solid #c9c9c9;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ebebeb;
  overflow-x: hidden;
}
.container-live .nav {
  width: 3.55rem;
  height: 0.6rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding-left: 0.1rem;
  padding-right: 0.1rem;
}

.container-live .selectBox {
  font-size: 0.1rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #004995;
  height: 0.38rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;
}
.van-dropdown-menu__bar {
  width: 0.7rem;
  height: 0.38rem;
  box-sizing: border-box;
  text-align: center;
}
.selectBox .van-dropdown-menu .van-dropdown-menu__bar {
  box-shadow: unset !important;
  background-color: #fff !important;
  height: 0.38rem !important;
  width: 0.7rem !important;
  height: 0.31rem !important;
}
.selectBox .van-ellipsis {
  font-size: 0.13rem;
  color: #004995;
  font-weight: 400;
}
.container-live .nav .searchBox {
  width: 0.8rem;
  height: 0.3rem;
  background: #004995;
  border: 1px solid #004995;
  border-radius: 0.04rem;

  font-size: 0.13rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 0.3rem;
}
.timeBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  margin-right: 0.05rem;
}
.inputBoxLeft {
  width: 0.8rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  border-radius: 0.05rem;
}
.inputBoxLeft .liveSelectList{
  width: 0.8rem;
  height: 0.3rem;
  font-size: 0.13rem;
  color: #004995;
  padding-left: 0.06rem;
  border: 1px solid #cacaca;
}


.inputBox {
  width: 0.8rem;
  height: 0.3rem;
  font-size: 0.13rem;
  color: #004995;
  padding-left: 0.06rem;
  border: 1px solid #cacaca;
}
.inputBox1 {
  margin-left: 0.02rem;
}
.tableBox {
  border-collapse: collapse;
}
tr,
th {
  padding: 0;
  font-size: 0.15rem;
  color: #004995;
  font-weight: 300;
}
.form {
  width: 100%;
  margin: auto;
  margin-top: 0.2rem;
  margin-left: 0.2rem;
  margin-bottom: 2rem;
}
.form caption {
  width: 100%;
  margin: auto;
  font-size: 0.33rem;
  text-align: center;
  color: #fd081e;
  margin-bottom: 0.05rem;
}
.van-field {
  margin: 0rem;
  padding: 0rem;
  width: 0.6rem;
  height: 0.4rem;
  color: #004995;
  position: relative;
  display: flex;
  box-sizing: border-box;
  overflow: hidden;
  line-height: 0.4rem;
  background-color: transparent;
}
.van-cell__value {
  width: 1rem;
  font-size: 0.08rem;
  color: #004995;
}
.return {
  font-size: 0.15rem;
  color: #fff;
  position: fixed;
  right: 16px;
  bottom: 88px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  z-index: 1500;
  overflow: hidden;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  background-color: #004995;
}
.calenarBox {
  width: 100%;
  height: 1.5rem;
  border: 1px solid #333333;
  background: #ffffff;

  position: fixed;
  bottom: 0rem;
  left: 0rem;
}


.container-live .form {
  width: 3.55rem;
  height: 2.8rem;
  background: #ffffff;
  border-radius: 0.05rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0.17rem;
  padding-bottom: 0.15rem;
  margin: 0.2rem auto;
}
.container-live .form .list {
  width: 3.15rem;
  height: 0.32rem;
  border-bottom: 1px solid #ebebeb;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 0.1rem;
}
.list .listLeft {
  height: 0.21rem;
  font-size: 0.15rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
}
.list .listRight {
  height: 0.21rem;
  font-size: 0.15rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #555555;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bottomButton {
  width: 1.5rem;
  height: 0.5rem;
  background: #004995;
  border-radius: 0.05rem;

  font-size: 0.17rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
}
</style>
