/*  
    originalOrderPrice 
    orderDiscounts     //订单优惠价格
    orderCurrentPrice  //订单现价
    orderPaymentPrice  //订单付款价格
*/


    //订单原价
export  function  originalOrderPrice(list){
    if (list.length > 0) {
        let arr = [];
        for (let index = 0; index < list.length; index++) {
            for (let i = 0; i < list[index].productItemList.length; i++) {
                arr.push(list[index].productItemList[i]) 
            }
        }
        let price = 0;
        arr.forEach(j=>{
            price = price + j.OriginalAmount;
        })
        return price;
    } else {
        return  0;
    }
}
//时间转换
export  function  transformTimestamp(timestamp){
    if (timestamp == null) {
        return "";
    } else {
        let a = new Date(timestamp).getTime();
        const date = new Date(a);
        const Y = date.getFullYear() + '-';
        const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        const D = (date.getDate() < 10 ? '0'+date.getDate() : date.getDate()) + '  ';
        const h = (date.getHours() < 10 ? '0'+date.getHours() : date.getHours()) + ':';
        const m = (date.getMinutes() <10 ? '0'+date.getMinutes() : date.getMinutes()) + ':';
        const s = (date.getSeconds() <10 ? '0'+date.getSeconds() : date.getSeconds()) ;
        const dateString = Y + M + D + h + m + s;
        return dateString;
    }

    
}
//时间转换        HHMMSS
export  function  HHMMSS(timestamp){
    if (timestamp == null) {
        return "";
    } else {
        let a = new Date(timestamp).getTime();
        const date = new Date(a);
        const Y = date.getFullYear() + '-';
        const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        const D = (date.getDate() < 10 ? '0'+date.getDate() : date.getDate()) + '  ';
        const h = (date.getHours() < 10 ? '0'+date.getHours() : date.getHours()) + ':';
        const m = (date.getMinutes() <10 ? '0'+date.getMinutes() : date.getMinutes()) + ':';
        const s = (date.getSeconds() <10 ? '0'+date.getSeconds() : date.getSeconds()) ;
        const dateString = h + m + s;
        return dateString;
    }

    
}