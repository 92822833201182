// 审批折扣
<template>
  <div class="container-index">
    <div class="box">
      <div class="buttonBox">
        <button
          class="btn1"
          @click="onclick1"
          :style="mystyle1"
          :disabled="disabledNUM1"
        >
          业绩查询
        </button>
      </div>
      <div class="buttonBox">
        <button
          class="btn2"
          @click="onclick2"
          :style="mystyle2"
          :disabled="disabledNUM2"
        >
           {{JobRoleID=='66'||JobRoleID=='111'||JobRoleID=='138' ||JobRoleID=='136'||JobRoleID=='141'||JobRoleID=='142'||JobRoleID=='140'||JobRoleID=='144' ||JobRoleID=='139'  ? '审核管理' : '订单查询' }}
        </button>
      </div>
      <div class="buttonBox">
        <button
          class="btn3"
          @click="onclick3"
          :style="mystyle3"
          :disabled="disabledNUM3"
        >
          订单创建
        </button>
      </div>
      <div class="buttonBox">
          <button
            class="btn4"
            @click="onclick4"
            :style="mystyle4"
            :disabled="disabledNUM4"
          >
            信息查询
          </button>
      </div>
      <div class="buttonBox" v-if="liveEvaluation">
        <button
          class="btn5"
          @click="goLiveEvaluation"
          :style="mystyle5"
        >
          直播评价
        </button>
      </div>
      
      
      
    
    </div>
  </div>
</template>

<script>
import router from "@/router/index.js";
export default {
  data() {
    return {
      disabledNUM1: false,
      disabledNUM2: false,
      disabledNUM3: false,
      disabledNUM4: false,
      liveEvaluation:false,
      mystyle1: {
        background: "#004995",
      },
      mystyle2: {
        background: "#004995",
      },
      mystyle3: {
        background: "#004995",
      },
      mystyle4: {
        background: "#004995",
      },
      mystyle5: {
        background: "#004995",
      },
    };
  },
  created() {
    var JobRoleID = this.$route.query.JobRoleID;
    this.JobRoleID = JobRoleID;
    if (this.JobRoleID == "111" || this.JobRoleID == "136") {
      this.liveEvaluation = true;
    }

    if (
      this.JobRoleID == "136" ||
      this.JobRoleID == "129" ||
      this.JobRoleID == "79" ||
      this.JobRoleID == "61" ||
      this.JobRoleID == "135" ||
      this.JobRoleID == "141"||
      this.JobRoleID == "68" ||
      this.JobRoleID == "80" ||
      this.JobRoleID == "89" ||
      //  updata  2022/7/21
      this.JobRoleID == "78" ||
      this.JobRoleID == "116" ||
      this.JobRoleID == "111" ||
      this.JobRoleID == "66" ||
      this.JobRoleID == "138" ||
      this.JobRoleID == "142" ||
      this.JobRoleID == "140" ||
      this.JobRoleID == "144" ||
      this.JobRoleID == "139"
    ) {
      console.log("都有");
      // 136 售后主管 129销售经理     79 61 135 销售主管      141质检主管    68 80 89 销售   78 103 108 116   售后  
    } else if (
      this.JobRoleID == "60"
    ) {
      console.log("只有业绩查询和订单创建和信息查询");
      this.disabledNUM2 = true;
      this.mystyle2.background = "#b2b2b2";
    } else {
      console.log("只有业绩查询和信息查询");
      this.disabledNUM2 = true;
      this.disabledNUM3 = true;
      this.mystyle2.background = "#b2b2b2";
      this.mystyle3.background = "#b2b2b2";
    }
    localStorage.removeItem('tabName');
    localStorage.removeItem('upgradePhone');
    localStorage.removeItem('relearnPhone');

  },
  methods: {
    onclick1() {
      router.push({ path: "/search", query: { JobRoleID: this.JobRoleID } });
    },
    onclick2() {
      //  updata  2022/7/21
      // if (this.JobRoleID == "136" || this.JobRoleID == "141") {
      if (this.JobRoleID == "66" || this.JobRoleID == "111" || this.JobRoleID == "138"|| this.JobRoleID == "136"|| this.JobRoleID == "141"|| this.JobRoleID == "142"|| this.JobRoleID == "140"|| this.JobRoleID == "144"|| this.JobRoleID == "139" ) {
        //  updata  2022/7/21
        // router.push({ path: "/approval",query: { JobRoleID: this.JobRoleID }, });
        router.push({ path: "/auditManagement",query: { JobRoleID: this.JobRoleID }, });
      } else {
        router.push({ path: "/manager", query: { JobRoleID: this.JobRoleID } });
      }
    },
    onclick3() {
      router.push({ path: "/pushOrder", query: { JobRoleID: this.JobRoleID } });
    },
    onclick4() {
      router.push({
        path: "/information",
        query: { JobRoleID: this.JobRoleID },
      });
    },
    goLiveEvaluation(){
      router.push({
        path: "/liveEvaluation",
        query: { JobRoleID: this.JobRoleID },
      });
    }
  },
};
</script>

<style>
* {
  padding: 0;
  margin: 0;
}
.container-index {
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #c9c9c9;
}
button {
  border: 0px solid;
  width: 0.8rem;
  height: 0.4rem;
  border-radius: 0.2rem;
  color: #ffffff;
}
.container-index .box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  /* flex-direction: row;
  justify-content: space-evenly; */
  align-items: center;
  font-size: 0.13rem;
  color: #ffffff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.container-index .box .buttonBox{
  width: 25%;
  height: 0.5rem;
  padding: 10px;
  box-sizing: border-box;
}


</style>
