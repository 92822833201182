<template>
  <div class="container-search">
    <div class="return" @click="OnClickReturn">返回</div>
    <div class="nav">
      <div class="selectBox">
        <van-dropdown-menu active-color="#004995">
          <van-dropdown-item
            v-model="value"
            :options="option1"
            @change="onChange"
          />
        </van-dropdown-menu>
      </div>

      <div class="timeBox">
        <div class="inputBoxLeft">
          <input
            class="inputBox"
            type="text"
            name="calendar1"
            :value="value1"
            placeholder="开始时间"
            @click="showCalendar1 = true"
            readonly
          />
          <van-icon name="arrow-down" color="004995" size="0.1rem" />
          <van-calendar
            v-model="showCalendar1"
            @confirm="onConfirm1"
            color="#004995"
            title="选择开始时间"
            :min-date="minDate"
            :max-date="maxDate"
            row-height="40"
          />
        </div>
        <div class="inputBoxLeft">
          <input
            class="inputBox inputBox1"
            type="text"
            name="calendar2"
            :value="value2"
            placeholder="结束时间"
            @click="showCalendar2 = true"
            readonly
          />
          <van-icon name="arrow-down" color="004995" size="0.1rem" />
          <van-calendar
            v-model="showCalendar2"
            @confirm="onConfirm2"
            color="#004995"
            title="选择结束时间"
            :min-date="minDate"
            :max-date="maxDate"
            row-height="40"
          />
        </div>
      </div>
      <button class="searchBox" @click="onSearch">搜索</button>
    </div>
    <div class="form">
      <table border="1" class="tableBox" width="350">
        <caption>
          <em>战绩：{{ num }}</em>
        </caption>
        <thead>
          <tr>
            <th>排名</th>
            <th>销售名称</th>
            <th>组名</th>
            <th>总计</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in this.Array" :key="index">
            <th>{{ index + 1 }}</th>
            <th>{{ item.UserName }}</th>
            <th>{{ item.Team }}</th>
            <th>{{ item.TotalAmount }}</th>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import router from "@/router/index.js";
import baseUrl from '@/request/baseURL.js'
import { Notify } from "vant";
export default {
  name: "search",
  data() {
    return {
      num: 0,
      value1: "",
      showCalendar1: false,
      value2: "",
      showCalendar2: false,
      minDate: new Date(2012, 1, 1),
      maxDate: new Date(),
      Array: [],
      value: 6,
      option1: [
        { text: "本日", value: 0 },
        { text: "昨日", value: 1 },
        { text: "本周", value: 2 },
        { text: "上周", value: 3 },
        { text: "本月", value: 4 },
        { text: "上月", value: 5 },
        { text: "不限", value: 6 },
      ],
    };
  },
  components: {},
  methods: {
    mounted() {},
    onConfirm1(date, e) {
      console.log(e);
      this.value1 = `${date.getFullYear()}-${
        date.getMonth() + 1
      }-${date.getDate()}`;
      this.showCalendar1 = false;
    },
    onConfirm2(date) {
      this.value2 = `${date.getFullYear()}-${
        date.getMonth() + 1
      }-${date.getDate()}`;
      this.showCalendar2 = false;
    },
    // 切换选项
    onChange() {
      console.log(this.value);

      var now = new Date(); //当前日期
      var nowDayOfWeek = now.getDay(); //今天本周的第几天
      var nowDay = now.getDate(); //当前日
      var nowMonth = now.getMonth(); //当前月
      var nowYear = now.getYear(); //当前年
      nowYear += nowYear < 2000 ? 1900 : 0; //
      var lastMonthDate = new Date(); //上月日期
      lastMonthDate.setDate(1);
      lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);
      // var lastYear = lastMonthDate.getYear();
      var lastMonth = lastMonthDate.getMonth();

      //格式化日期：yyyy-MM-dd
      Date.prototype.format = function (fmt) {
        var o = {
          "M+": this.getMonth() + 1, //月份
          "d+": this.getDate(), //日
          "h+": this.getHours(), //小时
          "m+": this.getMinutes(), //分
          "s+": this.getSeconds(), //秒
          "q+": Math.floor((this.getMonth() + 3) / 3), //季度
          S: this.getMilliseconds(), //毫秒
        };
        if (/(y+)/.test(fmt)) {
          fmt = fmt.replace(
            RegExp.$1,
            (this.getFullYear() + "").substr(4 - RegExp.$1.length)
          );
        }
        for (var k in o) {
          if (new RegExp("(" + k + ")").test(fmt)) {
            fmt = fmt.replace(
              RegExp.$1,
              RegExp.$1.length == 1
                ? o[k]
                : ("00" + o[k]).substr(("" + o[k]).length)
            );
          }
        }
        return fmt;
      };
      //获得某月的天数
      function getMonthDays(myMonth) {
        var monthStartDate = new Date(nowYear, myMonth, 1);
        var monthEndDate = new Date(nowYear, myMonth + 1, 1);
        var days = (monthEndDate - monthStartDate) / (1000 * 60 * 60 * 24);
        return days;
      }

      switch (this.value) {
        case 0:
          this.value1 = new Date().format("yyyy-MM-dd");
          this.value2 = new Date().format("yyyy-MM-dd");
          this.onSearch();
          console.log("本日", this.value1, this.value2);
          break;

        case 1:
          var today = new Date();
          today.setDate(today.getDate() - 1);
          this.value1 = today.format("yyyy-MM-dd");
          this.value2 = today.format("yyyy-MM-dd");
          this.onSearch();
          console.log("昨日", this.value1, this.value2);
          break;

        case 2:
          var weekStartDate = new Date(
            nowYear,
            nowMonth,
            nowDay - nowDayOfWeek
          ).format("yyyy-MM-dd");
          var weekEndDate = new Date(
            nowYear,
            nowMonth,
            nowDay + (6 - nowDayOfWeek)
          ).format("yyyy-MM-dd");
          this.value1 = weekStartDate;
          this.value2 = weekEndDate;
          this.onSearch();
          console.log("本周", this.value1, this.value2);
          break;
        case 3:
          var weekStartDateBefore = new Date(
            nowYear,
            nowMonth,
            nowDay - nowDayOfWeek - 7
          ).format("yyyy-MM-dd");
          var weekEndDateBefore = new Date(
            nowYear,
            nowMonth,
            nowDay - nowDayOfWeek - 1
          ).format("yyyy-MM-dd");
          this.value1 = weekStartDateBefore;
          this.value2 = weekEndDateBefore;
          this.onSearch();
          console.log("上周", this.value1, this.value2);
          break;

        case 4:
          var monthStartDate = new Date(nowYear, nowMonth, 1).format(
            "yyyy-MM-dd"
          );
          var monthEndDate = new Date(
            nowYear,
            nowMonth,
            getMonthDays(nowMonth)
          ).format("yyyy-MM-dd");
          this.value1 = monthStartDate;
          this.value2 = monthEndDate;
          this.onSearch();
          console.log("本月", this.value1, this.value2);
          break;

        case 5:
          var lastMonthStartDate = new Date(nowYear, lastMonth, 1).format(
            "yyyy-MM-dd"
          );
          var lastMonthEndDate = new Date(
            nowYear,
            lastMonth,
            getMonthDays(lastMonth)
          ).format("yyyy-MM-dd");
          this.value1 = lastMonthStartDate;
          this.value2 = lastMonthEndDate;
          this.onSearch();
          console.log("上月", this.value1, this.value2);
          break;

        default:
          this.value1 = "";
          this.value2 = "";
          this.onSearch();
          console.log("不限", this.value1, this.value2);
          break;
      }
    },
    // 点击搜索
    onSearch() {
      var _this = this;
      if (_this.value1 !== "" && _this.value2 !== "") {
        _this.axios
          .get(baseUrl+
            "api/Performance/GetPerformance",
            {
              params: {
                StartTime: _this.value1,
                EndTime: _this.value2,
              },
            }
          )
          .then(function (res) {
            _this.Array = res.data;
            console.log(res.data, "页面数据");
            var NUM = 0;
            for (var i = 0; i < _this.Array.length; i++) {
              NUM += _this.Array[i].TotalAmount;
            }
            NUM = Math.round(NUM * 100) /100;
            _this.num = NUM;
            console.log(_this.num, "战绩");
          })
          .catch(function (err) {
            console.log(err);
          });
      } else {
        Notify({
          type: "warning",
          duration: 1000,
          message: "请选择日期",
        });
      }
    },
    OnClickReturn() {
      router.go(-1); //返回上一层
    },
  },
};
</script>
<style>
* {
  padding: 0;
  margin: 0;
}
.container-search {
  width: 100%;
  height: 100%;
  border: 1px solid #c9c9c9;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ebebeb;
  overflow-x: hidden;
}
.container-search .nav {
  width: 3.55rem;
  height: 0.6rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding-left: 0.1rem;
  padding-right: 0.1rem;
}

.container-search .selectBox {
  font-size: 0.1rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #004995;
  height: 0.38rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;
}
.van-dropdown-menu__bar {
  width: 0.7rem;
  height: 0.38rem;
  box-sizing: border-box;
  text-align: center;
}
.selectBox .van-dropdown-menu .van-dropdown-menu__bar {
  box-shadow: unset !important;
  background-color: #fff !important;
  height: 0.38rem !important;
  width: 0.7rem !important;
  height: 0.31rem !important;
}
.selectBox .van-ellipsis {
  font-size: 0.13rem;
  color: #004995;
  font-weight: 400;
}
.container-search .nav .searchBox {
  width: 0.8rem;
  height: 0.3rem;
  background: #004995;
  border: 1px solid #004995;
  border-radius: 0.04rem;

  font-size: 0.13rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 0.3rem;
}
.timeBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  margin-right: 0.05rem;
}
.inputBoxLeft {
  width: 1rem;

  display: flex;
  flex-direction: row;
  align-items: center;

  box-sizing: border-box;
  border-radius: 0.05rem;
}
.inputBox {
  width: 0.8rem;
  height: 0.3rem;
  font-size: 0.13rem;
  color: #004995;
  padding-left: 0.06rem;
  border: 1px solid #cacaca;
}
.inputBox1 {
  margin-left: 0.02rem;
}
.tableBox {
  border-collapse: collapse;
}
tr,
th {
  padding: 0;
  font-size: 0.15rem;
  color: #004995;
  font-weight: 300;
}
.form {
  width: 100%;
  margin: auto;
  margin-top: 0.2rem;
  margin-left: 0.2rem;
  margin-bottom: 2rem;
}
.form caption {
  width: 100%;
  margin: auto;
  font-size: 0.33rem;
  text-align: center;
  color: #fd081e;
  margin-bottom: 0.05rem;
}
.van-field {
  margin: 0rem;
  padding: 0rem;
  width: 0.6rem;
  height: 0.4rem;
  color: #004995;
  position: relative;
  display: flex;
  box-sizing: border-box;
  overflow: hidden;
  line-height: 0.4rem;
  background-color: transparent;
}
.van-cell__value {
  width: 1rem;
  font-size: 0.08rem;
  color: #004995;
}
.return {
  font-size: 0.15rem;
  color: #fff;
  position: fixed;
  right: 16px;
  bottom: 88px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  z-index: 1500;
  overflow: hidden;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  background-color: #004995;
}
.calenarBox {
  width: 100%;
  height: 1.5rem;
  border: 1px solid #333333;
  background: #ffffff;

  position: fixed;
  bottom: 0rem;
  left: 0rem;
}
</style>
