<template>
  <div class="relearn">
    <div class="relearnSearch">
      <van-field v-model="PhoneNumber" center clearable label="电话" type="tel"  placeholder="请输入手机号" @clear="clearPhoneNumber"  @input="phoneNumberChange"  >
        <template #button>
          <van-button size="small"  color="#004995"    @click="onSearch" >搜索</van-button>
        </template>
      </van-field>
    </div>
    <div class="form" v-for="(item, index) in dataList" :key="index">
      <div class="list">
        <div class="listLeft">学员姓名</div>
        <div class="listRight">{{ item.RealName }}</div>
      </div>
      <div class="list">
        <div class="listLeft">申请状态</div>
        <div class="listRight">{{ item.Status }}</div>
      </div>
      <div class="list">
        <div class="listLeft">申请人</div>
        <div class="listRight">{{ item.CreatedUserName }}</div>
      </div>
      <div class="list">
        <div class="listLeft">申请时间</div>
        <div class="listRight">{{ item.CreatedDate | timeDate  }}</div>
      </div>
      <div class="list">
        <div class="listLeft">审核人</div>
        <div class="listRight">
          {{ item.ApproveUserName}}
        </div>
      </div>
      <div class="list">
        <div class="listLeft">审核时间</div>
        <div class="listRight">{{ item.ApproveDate | timeDate }}</div>
      </div>
      <div class="buttonBox" v-if="item.Status=='已同意'||item.Status=='已拒绝'">
        <button class="bottomButton" @click="giftCheck(item)">
          查看
        </button>
      </div>
      <div class="buttonBox" v-else>
        <button class="bottomButton" @click="giftReview(item)">
          审核
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import router from "@/router/index.js";
import { Toast } from "vant";
import { transformTimestamp } from "@/common/common.js";

export default {
  name: "gift",
  data() {
    return {
      PhoneNumber: "",
      dataList:[],
    };
  },
  filters: {
    timeDate(time) {
        return transformTimestamp(time);
    }
  },
  methods: {
    onSearch() {
      var _this = this;
      if (_this.PhoneNumber == "") {
        Toast("手机号码不得为空！");
      } else {
         _this.axios.get("https://newims.phoneapi.lekaowang.cn/api/Customer/Load_GiftVerificationList", {
            params: {
              Tel: _this.PhoneNumber,
            },
          })
          .then(function (res) {
            // Code=200 为成功 Code=0为数据为空
            if (res.data.Code == "200") {
              res.data.Data.sort(function(a, b) {
                return b.CreatedDate < a.CreatedDate ? -1 : 1
              })  
              _this.dataList = res.data.Data;
            } else {
              Toast(res.data.Msg);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    //输入框清除按钮点击后操作
    clearPhoneNumber(){
      this.dataList = [];
    },
    //输入框手机号变化时操作
    phoneNumberChange(){
      if (this.PhoneNumber=="") {
         this.dataList = [];
      }
    },
    OnClickReturn() {
      router.go(-1); //返回上一层
    },
    giftReview(item){
      router.push({ path: "/giftReview", query: { ApplyMainId: item.ApplyMainId } });
    },
    giftCheck(item){
      router.push({ path: "/giftCheck", query: { ApplyMainId: item.ApplyMainId } });
    },

  },
  activated() {
    if (sessionStorage.getItem('tabName') == 'gift' && this.PhoneNumber !== "" ) {
      this.onSearch(); // 重新加载
    }
  },
};
</script>

<style scoped>
.form{
  margin-left: 0 !important;
  margin-bottom: 0 !important;
}

.relearn .form {
  width: 3.75rem;
  /* height: 5.02rem; */
  background: #ffffff;
  border-radius: 0.05rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding-top: 0.17rem; */
  padding-bottom: 0.15rem;
  margin-top: 0.2rem;
}


.relearn .form .list {
  width: 3.15rem;
  height: 0.32rem;
  border-bottom: 1px solid #ebebeb;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 0.1rem;
}
.list .listLeft {
  height: 0.21rem;
  font-size: 0.15rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
}
.list .listRight {
  height: 0.21rem;
  font-size: 0.15rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #555555;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.buttonBox{
  display: flex;
  justify-content: space-evenly;
  width: 85%;
}


.bottomButton {
  width: 1.5rem;
  height: 0.5rem;
  background: #004995;
  border-radius: 0.05rem;
  font-size: 0.17rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  margin-top: 0.2rem;
}
.return {
  font-size: 0.15rem;
  color: #fff;
  position: fixed;
  right: 16px;
  bottom: 88px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  z-index: 15;
  overflow: hidden;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  background-color: #004995;
}
/deep/ .van-field__button{
  padding-left: 25px;
}
</style>
