<template>
  <div class="reviewRelearn">
    <!-- 基本信息 -->
    <div class="basicInformation">
      <ul>
        <li>
          <span class="reviewTitle">用户名：</span>
          <span class="reviewContent">{{relearnData.Name}}</span>
        </li>
        <li>
          <span class="reviewTitle">学员姓名：</span>
          <span class="reviewContent">{{relearnData.RealName}}</span>
        </li>
        <li>
          <span class="reviewTitle">身份证：</span>
          <span class="reviewContent">{{relearnData.IdentityCard}}</span>
        </li>
        <li>
          <span class="reviewTitle">产品名称：</span>
          <span class="reviewContent">{{relearnData.ProductName}}</span>
        </li>
        <li class="between">
          <span class="reviews">
            <span class="reviewTitle">购买时间：</span>
            <span class="reviewContent">{{relearnData.ContractDate}}</span>
          </span>
          <span class="reviews">
            <span class="reviewTitle">金额：</span>
            <span class="reviewContent">{{relearnData.Amounts}}</span>
          </span>          
        </li>
        <li class="between">
          <span class="reviews">
            <span class="reviewTitle">学习时间：</span>
            <span class="reviewContent">{{relearnData.LearnTimes}}</span>
          </span>
          <span class="reviews">
            <span class="reviewTitle">合同剩余时间：</span>
            <span class="reviewContent">{{relearnData.ContractDays}}</span>
          </span>          
        </li>
        <li style="display:flex;justify-content: space-between;align-items: center;">
          <van-checkbox v-model="relearnData.ChkisFreezed" shape="square" disabled><span class="reviewTitle">冻结状态</span></van-checkbox>
          <van-checkbox v-model="relearnData.ChkisClosed" shape="square" disabled><span class="reviewTitle">关闭状态</span></van-checkbox>
          <van-checkbox v-model="relearnData.ChkisPassed" shape="square" disabled><span class="reviewTitle">是否终止合同</span></van-checkbox>
        </li>
      </ul>
      <p @click="showStatus=!showStatus" class="Status">{{showStatus==false ? '展开': '收起' }}</p>
      <ul v-show="showStatus==true">
        <li>
          <span class="reviewTitle">协会账号：</span>
          <span class="reviewContent">{{relearnData.Account}}</span>
        </li>
        <li>
          <span class="reviewTitle">协会密码：</span>
          <span class="reviewContent">{{relearnData.Password}}</span>
        </li>
        <li>
          <span class="reviewTitle">报考省市：</span>
          <span class="reviewContent">{{relearnData.City}}</span>
        </li>
        <li>
            <span class="reviewTitle">申请时间：</span>
            <span class="reviewContent">{{relearnData.ApplicationTime}}</span>      
        </li>
        <li class="between" style="height:100%">
          <span class="reviewTitle">成绩截图：</span>
          <van-uploader v-model="fileList" :deletable="false" :disabled="true" />
        </li>
        <li>
          <span class="reviewTitle">当前期数：</span>
          <span class="reviewContent">{{relearnData.Periods}}</span>        
        </li>
        <li>
          <span class="reviewTitle">审核次数：</span>
          <span class="reviewContent">{{relearnData.Frequency}}</span>         
        </li>
        <li>
          <span class="reviewTitle">申请原因：</span>
          <span class="reviewContent">{{relearnData.Reason}}</span>
        </li>
      </ul>
    </div>
    <div class="basicInformation" style="border:none;">
       <ul>
        <li>          
              <van-radio-group v-model="resultRadio" direction="horizontal">
                <span class="reviewTitle">审核结果：</span>
                <van-radio name="true">同意</van-radio>
                <van-radio name="false">拒绝</van-radio>
                <span class="reviewtips">拒绝必须填写备注</span>
              </van-radio-group>
        </li>
        <li>          
              <van-radio-group v-model="testRadio" direction="horizontal">
                <span class="reviewTitle">是否参加考试：</span>
                <van-radio name="true">是</van-radio>
                <van-radio name="false">否</van-radio>
                <span class="reviewtips">选择“是”，必须填写分数</span>
              </van-radio-group>
        </li>
        <li>
          <span class="reviewTitle">分数：</span>
          <span class="reviewContent">
            <input type="text"  @input="handleScoreChange(score)" v-model="score"   >
          </span>
        </li>
        <li>
          <span class="reviewTitle lin">审核备注：</span> <br>
        </li>
        <li class="hei2">
          <textarea  class="remarks" v-model="CheckRemark" ></textarea>
        </li>
      </ul>
    </div>
    <div class="submitBtn">
      <button @click="SubmitRelearn"  :disabled="btnShow">
        提交
    </button>
    </div>
    <div class="return" @click="OnClickReturn">返回</div>

  </div>
</template>
<script>
import router from "@/router/index.js";
import { Toast } from "vant";
import {post} from '@/request/http'
import {GetCSRLean,RLearnSave}  from '@/request/api'
export default {
  name: "reviewRelearn",
  data() {
    return {
      relearnData:{},
      CheckRemark:"",
      status:[],//状态数组
      showStatus:false,
      resultRadio:'',
      testRadio:'',
      score:'0',
      fileList:[],
      //冻结状态
      ChkisFreezed:false,
      // 关闭状态
      ChkisClosed:false,
      // 是否终止
      ChkisPassed:false,
      rawInput: '',
      btnShow:false
    };
  },
  mounted(){
    this.RelearnCheck();
  },
  methods: {
    RelearnCheck(){
      var _this = this;
      post(GetCSRLean+'?ContractItemID='+_this.$route.query.ContractItemID+'&RequestRLearnID='+_this.$route.query.RequestRLearnID)
      .then(function (res) {
        // Code=200 为成功 Code=0为数据为空
        if (res.Code == "200") {
          _this.relearnData = res.Data;
          if (_this.$route.query.id==1) {
            _this.CheckRemark = res.Data.CheckRemark;
            _this.resultRadio = (res.Data.CheckStatus=='同意' ? 'true' :'false');
            _this.testRadio = (res.Data.IsJoinExam==true ? 'true' :'false');
            _this.score = res.Data.Score;
          }
          if (res.Data.ScorePictureUrl!="" ||res.Data.ScorePictureUrl!=null ) _this.fileList.push({url:res.Data.ScorePictureUrl})
        } else {
          Toast(res.Msg);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    handleScoreChange(amount) {
      amount = amount
        .replace(/[^\d.]/g, "") //只能输入数字
        .replace(/^()*(\d+)\.(\d\d).*$/, "$1$2.$3") //只能输入两个小数
        .replace(/\.{2,}/g, "."); //出现多个点时只保留第一个
      // 第一位不让输小数点
      if (amount == ".") {
        amount = "";
      }
      // 如果第一位是0，第二位必须大于0或者小数点
      if (amount.substring(0, 1) == 0) {
        if (amount.substring(1, 2) > 0) {
          amount = amount.substring(1, 2);
        } else if (
          amount.substring(1, 2) === 0 ||
          amount.substring(1, 2) === "0"
        ) {
          amount = "0";
        }
      } else {
        // 如果第一位数字大于0（不等于0肯定就大于0），仅需考虑第二位是小数点的情况
        if (amount.indexOf(".") !== -1) {
          if (amount.substring(0, 1) > 0) {
            console.log("第一位大于0");
          } else {
            console.log("第一位等于0");
            if (amount.substring(2, 3) > 0) {
              console.log("小数点后第一位大于0");
            } else {
              console.log("小数点后第一位等于0");
              amount = "0.";
            }
          }
        } else {
          console.log("没有小数点，正常输入");
        }
      }
      
      if (amount == "") {
        amount = "0";
      }
      this.score = amount;
    },
    SubmitRelearn(){
      var _this = this;
      if (_this.resultRadio=="") {
        Toast("请选择审核结果！");
        return false;
      }
      if (_this.testRadio=="") {
        Toast("请选择是否参加考试！");
        return false;
      }
      if (_this.resultRadio=="false" && _this.CheckRemark=="") {
        Toast("拒绝通过请填写审批备注！");
        return false;
      }
      if (_this.testRadio=="true" && _this.score =="" ) {
        Toast("参加考试，必选填写分数！");
        return false;
      }
      if ( _this.score =="" ) {
        _this.score = "0";
      }
      _this.btnShow  =  true;
      post(RLearnSave+"?LoginId="+sessionStorage.getItem('token')+"&ContractItemID="
      +_this.$route.query.ContractItemID+"&RequestRLearnID="+_this.$route.query.RequestRLearnID+"&IsJoinExam="+_this.testRadio+"&State="+_this.resultRadio+
      "&Score="+_this.score+"&CheckRemark="+_this.CheckRemark)
          .then(function (res) {
            // Code=200 为成功 1或者其他为失败
            if (res.Code == "200") {
              Toast("提交成功！");
              router.go(-1);
            } else {
              Toast(res.Msg);
            }
            _this.btnShow  =  false;
          })
          .catch(function (error) {
            _this.btnShow  =  false;
            console.log(error);
          });
    },
    OnClickReturn() {
      router.go(-1); //返回上一层
    },
  },
};
</script>

<style scoped>
.reviewRelearn{
  height: 100%;
}

.basicInformation{
  padding: 0.1rem;
  border-bottom: 1px solid;
}

.basicInformation ul li{
  width: 3.55rem;
  /* height: 0.35rem; */
  line-height: 0.35rem;
  margin: 0 auto ;
  font-size: 0.15rem;
}
.reviewTitle{
  font-weight: bold;
}
.reviewtips{
  color: #ff0000;
  font-size: 0.12rem;
}

.between{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/deep/ .van-checkbox__label{
  font-size: 0.15rem;
}
.Status{
  height: 0.35rem;
  font-size: 0.15rem;
  color:#004995 ;
  text-align: right;
}
.score{
  width: 1rem;
  height: 0.3rem;
  font-size: 0.15rem;
}
.remarks{
  width: 100%;
  height: 2rem;
  font-size: 0.15rem;
}
.lin{
  line-height: 0.35rem;
}
.hei2{
  height: 2rem !important;
}


.submitBtn{
  display: flex;
  justify-content: center;
  margin-top: 0.3rem;
  margin-bottom: 2rem;
}
.submitBtn button{
  display: block;
  width: 1.5rem;
  height: 0.35rem;
  border-radius: 5px;
  border: none;
  font-size: 0.15rem;
  color:#fff ;
  background: #004995;
}

/deep/ .van-uploader{
  width: 265px;
}
/deep/ .van-uploader__upload{
  display: none;
}
</style>
