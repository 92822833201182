<template>
  <div class="reviewRelearn">
    <!-- 基本信息 -->
    <div class="basicInformation" v-for="(item,index) in relearnList" :key="index">
      <ul>
        <li>
          <span class="reviewTitle">产品名称：</span>
          <span class="reviewContent">{{item.ProductName}}</span>
        </li>
        <li>
          <span class="reviewTitle">操作人：</span>
          <span class="reviewContent">{{item.OperationUserName}}</span>
        </li>
        <li>
          <span class="reviewTitle">操作时间：</span>
          <span class="reviewContent">{{item.CreatedDate}}</span>
        </li>
        <li>
          <span class="reviewTitle">操作后状态：</span>
          <span class="reviewContent">{{item.OperationState}}</span>
        </li>
        <li>
          <span class="reviewTitle">冻结次数：</span>
          <span class="reviewContent">{{item.Number}}</span>
        </li>
        <li>
          <span class="reviewTitle">备注：</span>
          <span class="reviewContent">{{item.Remark}}</span>
        </li>
      </ul>
    </div>
    <div class="return" @click="OnClickReturn">返回</div>
  </div>
</template>
<script>
import router from "@/router/index.js";
import { Toast } from "vant";
import {get} from '@/request/http'
import {GetFreezeRecords}  from '@/request/api'
export default {
  name: "reviewRelearn",
  data() {
    return {
          relearnList: [],
    };
  },
  mounted(){
    this.RelearnCheck();
  },
  methods: {
    RelearnCheck(){
      var _this = this;
      get(GetFreezeRecords+'?ProductName='+_this.$route.query.ProductName+'&CustomerID='+_this.$route.query.CustomerID+'&ContractItemID='+_this.$route.query.ContractItemID)
      .then(function (res) {
        // Code=200 为成功 Code=0为数据为空
        if (res.Code == "200") {
          
          if (res.Data.Table.length > 0) {
            _this.relearnList = res.Data.Table;
          } else {
            Toast("无数据");
            router.go(-1);
          }
        } else {
          Toast(res.Msg);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    },

    OnClickReturn() {
      router.go(-1); //返回上一层
    },
  },
};
</script>

<style scoped>
.reviewRelearn{
  height: 100%;
}

.basicInformation{
  padding: 0.1rem;
  border-bottom: 1px solid;
}

.basicInformation ul li{
  width: 3.55rem;
  line-height: 0.35rem;
  margin: 0 auto ;
  font-size: 0.15rem;
  display: flex;
}
.reviewTitle{
  font-weight: bold;
  width: 1rem;
  display: block;
}
.reviewContent{
  display: block;
   width: 2.55rem;
}
</style>
