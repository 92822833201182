import axios from "axios"
import baseUrl from './baseURL'
import Vue from 'vue'
import router from '../router'
var vm = new Vue({
    router,
});

axios.create({
    baseURL: baseUrl,
    responseType: "json",
    headers: {
        'Access-Control-Allow-Origin': '*',
        'accept': '*/*',
        'Content-Type': 'application/json;charset=utf-8',
    },
    timeout: 5000,
    withCredentials: true

});
// request 请求拦截
axios.interceptors.request.use(config => {
    return config
})
//response请求成功后拦截
axios.interceptors.response.use(function(response) {
    // if (response.data.code == '30000') {
    //     vm.$router.push('/login')
    // }
    return response;
}, function(error) {
    return Promise.reject(error);
});
// export default service;

/**
 * 
 * @param {string} url     接口地址
 * @param {object} params  请求参数
 */
export function post(url, params) {
    return new Promise((resolve, reject) => {
        axios
            .post(url, params)
            .then(res => {
                resolve(res.data)
            }, err => {
                reject(err.data)
            })
            .catch(err => {

            })
    })

}
/**
 * 
 * @param {string} url   接口地址
 */
export function get(url, params) {
    return new Promise((resolve, reject) => {
        axios.get(url, params)
            .then(res => {
                resolve(res.data)
            }, err => {
                reject(err.data)
            })
            .catch(err => {

            })
    })

}