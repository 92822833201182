<template>
  <div class="reviewRelearn">
    <!-- 基本信息 -->
    <div class="basicInformation">
      <ul>
        <li>
          <span class="reviewTitle">用户名：</span>
          <span class="reviewContent">{{relearnData.Name}}</span>
        </li>
        <li>
          <span class="reviewTitle">学员姓名：</span>
          <span class="reviewContent">{{relearnData.RealName}}</span>
        </li>
        <li>
          <span class="reviewTitle">身份证：</span>
          <span class="reviewContent">{{relearnData.IdentityCard}}</span>
        </li>
        <li class="between" style="height:100%">
          <span class="reviewTitle" style="width:0.85rem;">产品名称：</span>
          <span class="reviewContent">{{relearnData.ProductName}}</span>
        </li>
        <li class="between">
          <span class="reviews">
            <span class="reviewTitle">购买时间：</span>
            <span class="reviewContent">{{relearnData.ContractDate}}</span>
          </span>
          <span class="reviews">
            <span class="reviewTitle">金额：</span>
            <span class="reviewContent">{{relearnData.Amounts}}</span>
          </span>          
        </li>
        <li class="between">
          <span class="reviews">
            <span class="reviewTitle">学习时间：</span>
            <span class="reviewContent">{{relearnData.LearnTimes}}</span>
          </span>
          <span class="reviews">
            <span class="reviewTitle">合同剩余时间：</span>
            <span class="reviewContent">{{relearnData.ContractDays}}</span>
          </span>          
        </li>
        <li style="display:flex;justify-content: space-between;align-items: center;">
          <van-checkbox v-model="relearnData.ChkisFreezed" shape="square" disabled><span class="reviewTitle">冻结状态</span></van-checkbox>
          <van-checkbox v-model="relearnData.ChkisClosed" shape="square" disabled><span class="reviewTitle">关闭状态</span></van-checkbox>
          <van-checkbox v-model="relearnData.ChkisPassed" shape="square" disabled><span class="reviewTitle">是否终止合同</span></van-checkbox>
        </li>
      </ul>
      <p @click="showStatus=!showStatus" class="Status">{{showStatus==false ? '展开': '收起' }}</p>
      <ul v-show="showStatus==true">
        <li>
          <span class="reviewTitle">协会账号：</span>
          <span class="reviewContent">{{relearnData.Account}}</span>
        </li>
        <li>
          <span class="reviewTitle">协会密码：</span>
          <span class="reviewContent">{{relearnData.Password}}</span>
        </li>
        <li>
          <span class="reviewTitle">报考省市：</span>
          <span class="reviewContent">{{relearnData.City}}</span>
        </li>
        <li>
            <span class="reviewTitle">申请时间：</span>
            <span class="reviewContent">{{relearnData.ApplicationTime}}小时</span>      
        </li>
        <li class="between" style="height:100%">
          <span class="reviewTitle">成绩截图：</span>
          <van-uploader v-model="fileList" :deletable="false" :disabled="true" />
        </li>
        <li>
          <span class="reviewTitle">当前期数：</span>
          <span class="reviewContent">{{relearnData.Periods}}</span>        
        </li>
        <li>
          <span class="reviewTitle">审核次数：</span>
          <span class="reviewContent">{{relearnData.Frequency}}</span>         
        </li>
        <li>
          <span class="reviewTitle">申请原因：</span>
          <span class="reviewContent">{{relearnData.Reason}}</span>
        </li>
      </ul>
    </div>
    <div class="basicInformation">
       <ul>
        <li>          
          <span class="reviewTitle">审核结果：</span>
          <span class="reviewContent">{{relearnData.CheckStatus}}</span>
        </li>
        <li>          
          <span class="reviewTitle">是否参加考试：</span>
          <span class="reviewContent">{{relearnData.IsJoinExam== true ? '是' : '否'  }}</span>
        </li>
        <li>
          <span class="reviewTitle">分数：</span>
          <span class="reviewContent">
            {{relearnData.Score}}
          </span>
        </li>
        <li>
          <span class="reviewTitle lin">审核备注：</span> <br>
        </li>
        <li class="hei2">
          <textarea  class="remarks" v-model="CheckRemark"></textarea>
        </li>
      </ul>
    </div>
    <div class="return" @click="OnClickReturn">返回</div>

  </div>
</template>
<script>
import router from "@/router/index.js";
import { Toast } from "vant";
import {post} from '@/request/http'
import {GetCSRLean}  from '@/request/api'
export default {
  name: "relearnCheck",
  data() {
    return {
      relearnData:{},
      CheckRemark:"",
      status:[],//状态数组
      showStatus:false,
      resultRadio:'',
      testRadio:'',
      score:'',
      fileList:[],
      //冻结状态
      ChkisFreezed:false,
      // 关闭状态
      ChkisClosed:false,
      // 是否终止
      ChkisPassed:false,
    };
  },
  mounted() {
    this.RelearnCheck();
  },
  methods: {
    RelearnCheck(){
      var _this = this;
      post(GetCSRLean+'?ContractItemID='+_this.$route.query.ContractItemID+'&RequestRLearnID='+_this.$route.query.RequestRLearnID)
      .then(function (res) {
        // Code=200 为成功 Code=0为数据为空
        if (res.Code == "200") {
          _this.relearnData = res.Data;
          _this.CheckRemark = res.Data.CheckRemark;
          _this.score = parseFloat(res.Data.Score);
          if (res.Data.ScorePictureUrl!="" ||res.Data.ScorePictureUrl!=null ) _this.fileList.push({url:res.Data.ScorePictureUrl})
        } else {
          Toast(res.Msg);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    OnClickReturn() {
      router.go(-1); //返回上一层
    },
  },
};
</script>

<style scoped>
.reviewRelearn{
  height: 100%;
  padding-bottom: 2rem;
}

.basicInformation{
  padding: 0.1rem;
}

.basicInformation ul li{
  width: 3.55rem;
  height: 0.35rem;
  line-height: 0.35rem;
  margin: 0 auto ;
  font-size: 0.15rem;
}
.reviewTitle{
  font-weight: bold;
}
.reviewtips{
  color: #ff0000;
  font-size: 0.12rem;
}

.between{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/deep/ .van-checkbox__label{
  font-size: 0.15rem;
}
.Status{
  height: 0.35rem;
  font-size: 0.15rem;
  color:#004995 ;
  text-align: right;
}
.score{
  width: 1rem;
  height: 0.3rem;
  font-size: 0.15rem;
}
.remarks{
  width: 100%;
  height: 2rem;
  font-size: 0.15rem;
}
.lin{
  line-height: 0.35rem;
}
.hei2{
  height: 2rem !important;
}


.submitBtn{
  display: flex;
  justify-content: center;
  margin-top: 0.3rem;
  margin-bottom: 2rem;
}
.submitBtn button{
  display: block;
  width: 1.5rem;
  height: 0.35rem;
  border-radius: 5px;
  border: none;
  font-size: 0.15rem;
  color:#fff ;
  background: #004995;
}

/deep/ .van-uploader{
  width: 265px;
}
/deep/ .van-uploader__upload{
  display: none;
}
</style>
