import baseUrl from './baseURL'


export const POGetOrderDetails = baseUrl + 'api/PO/GetOrderDetails'      //查看订单详情
export const PODiscountReview = baseUrl + 'api/PO/DiscountReview'       //折扣审批
export const POUploadImg =  baseUrl + 'api/PO/UploadImg_SaveFile'     //折扣审批上传凭证照片

//直播评价
export const GetCategory =  baseUrl + 'api/PO/GetCategory'     //获取分类列表
export const GetSubject =  baseUrl + 'api/PO/GetSubjectByCategoryId'     //获取科目列表
export const GetLiveBrodcastList =  baseUrl + 'api/Values/GetLiveBrodcastList'     //获取直播评价列表
export const PublishAppraise =  baseUrl + 'api/Values/PublishAppraise'     //发起直播评价

//升级更换
export const UpgradeChangeCoursesListNew =  baseUrl + 'api/Customer/Load_UpgradeChangeCoursesListNew'     //获取升级更换列表
export const GetUpgradeChangeCourses =  baseUrl + 'api/Customer/GetUpgradeChangeCourses'     // 升级更换详情查看(未审核时审核查看)
export const UpgradeAndChangeCoursesNew =  baseUrl + 'api/Customer/UpgradeAndChangeCoursesNew'     // 升级更换审批保存
export const UpgradeAndChangeCoursesNewRefuse =  baseUrl + 'api/Customer/UpgradeAndChangeCoursesNewRefuse'     // 升级更换审批拒绝

//申请重学

export const GetCSRLeanList =  baseUrl + 'api/Customer/GetCSRLeanList'   //查询申请重学列表
export const GetFreezeRecords =  baseUrl + 'api/Customer/GetFreezeRecords'   //查询冻结记录
export const GetCSRLean =  baseUrl + 'api/Customer/GetCSRLean'   //查看申请重学
export const RLearnSave =  baseUrl + 'api/Customer/SetRequestRLearnSave'   //重学审批


//折扣审批
export const GetDiscountReviewList =  baseUrl + 'api/Customer/GetDiscountReviewList'   //折扣审核列表查询
export const LookDiscountReview =  baseUrl + 'api/Customer/DiscountReviewProcessing'   //折扣审核列表详情查看
export const CheckApproveNew =  baseUrl + 'api/Approvals/CheckApproveNew'   //折扣审核




