<template>
  <div class="container-manager">
    <div class="return" @click="OnClickReturn" v-if="returnData">返回</div>
    <div class="nav">
      <span class="spantext">电话</span>
      <van-search
        v-model="PhoneNumber"
        left-icon="none"
        right-icon="none"
        background="#EBEBEB"
        maxlength="13"
        input-align="left"
        @search="onSearch"
      />
      <button class="searchBox" @click="onSearch">搜索</button>
    </div>
    <div class="form" v-for="(item, index) in dataList" :key="index">
      <div class="list">
        <div class="listLeft">订单号</div>
        <div class="listRight">{{ item.PONo }}</div>
      </div>
      <div class="list">
        <div class="listLeft">客户姓名</div>
        <div class="listRight">{{ item.FollowUser }}</div>
      </div>
      <div class="list">
        <div class="listLeft">订单状态</div>
        <div class="listRight">{{ item.POStatus }}</div>
      </div>
      <div class="list">
        <div class="listLeft">订单价格</div>
        <div class="listRight">{{ item.FinalAmount }}</div>
      </div>
      <div class="list">
        <div class="listLeft">支付方式</div>
        <div class="listRight">{{ item.PaymentMethod }}</div>
      </div>
      <div class="list">
        <div class="listLeft">订单备注</div>
        <div class="listRight">{{ item.Remark }}</div>
      </div>
      <div class="list">
        <div class="listLeft">订单创建时间</div>
        <div class="listRight">{{ item.CreatedDate.replace("T", "   ").slice(0,19) }}</div>
      </div>
      <div class="list">
        <div class="listLeft">完成时间</div>
        <div class="listRight">{{ item.CompleteTime }}</div>
      </div>
      <!-- <div class="list">
        <div class="listLeft">会员余额</div>
        <div class="listRight">{{ item.OrgId }}天</div>
      </div> -->
      <div class="list">
        <div class="listLeft">是否删除</div>
        <div class="listRight">{{ item.IsTest == false ? "否" : "是" }}</div>
      </div>
      <div class="buttonBox" v-if="JobRoleID == 68 ||JobRoleID == 80 ||JobRoleID == 89">
        <button class="bottomButton" @click="seeDetail(item)">
          查看
        </button>
        <!-- <button class="bottomButton bottomButton1" @click="submit1(item)">
          {{ buttonText }}
        </button> -->
        <button class="bottomButton" style="margin-left:0.15rem;"  @click="submitOrder(index)"  v-show="item.ApplyDiscountCount <= 0 || item.PoApproveStatus >= 3 ">
          生成订单
        </button>
      </div>
      <div class="buttonContent" v-else>
        <button  @click="seeDetail(item)">
          查看
        </button>
        <button  @click="discountReview(item)" v-show="item.ApplyPoApprovelID!==null && item.ApplyPoApprovelID!=='' ">
          审核折扣
        </button>
        <button  @click="submitOrder(index)"  v-show="item.ApplyDiscountCount <= 0 || item.PoApproveStatus >= 3 "> 
          生成订单
        </button>
      </div>
      <!-- <div class="buttonBox">
        <button>查看</button>
        <button>审核折扣</button>
        <button class="bottomButton bottomButton1" @click="submit1(item)">
          {{ buttonText }}
        </button>
        <button class="bottomButton" @click="submitOrder(index)">
          生成订单
        </button>
      </div> -->
    </div>
    <van-overlay :show="show" @click="overlayShow" />

    <!-- 弹出的表单 -->
    <div class="manager-form2" v-if="formdata">
      <div class="list">
        <div class="listLeft">学员姓名</div>
        <div class="listRight">{{ dataList3.RealName }}</div>
      </div>
      <div class="list">
        <div class="listLeft">学员手机号</div>
        <div class="listRight">{{ dataList3.Tel }}</div>
      </div>
      <div class="list">
        <div class="listLeft">订单号</div>
        <div class="listRight">{{ dataList3.PONo }}</div>
      </div>

      <div class="more" v-for="(item, index) in dataList2" :key="index">
        <div class="list">
          <div class="listLeft">购买产品</div>
          <div class="listRight">
            {{ item.ItemName == null ? null : item.ItemName }}
          </div>
        </div>
        <div class="list">
          <div class="listLeft">原价</div>
          <div class="listRight">
            ¥{{ item.UnitAmount == null ? null : item.UnitAmount }}
          </div>
        </div>
        <div class="list list2">
          <div class="listLeft">现价</div>
          <div class="listRight">
            ¥{{ item.FinalAmount == null ? null : item.FinalAmount }}
          </div>
        </div>
        <div>
          <van-field
            class="numBox"
            v-model="numArray[index]"
            label="申请折扣金额"
            placeholder="请输入优惠金额"
            input-align="right"
            @blur="onInput"
          />
        </div>
      </div>

      <div>
        <div class="list">
          <div class="listLeft">上传凭证</div>
          <van-uploader
            v-model="fileList"
            multiple
            :max-count="4"
            preview-size="35px"
            :after-read="afterRead"
            :before-delete="beforeDelete"
          />
        </div>
        <div class="list">
          <div class="listLeft">优惠金额</div>
          <div class="listRight">已优惠{{ sum }}元</div>
        </div>
        <div class="list">
          <div class="listLeft">实际支付金额</div>
          <div class="listRight">¥{{ this.NUM - this.sum }}</div>
        </div>
      </div>

      <button class="bottomButton" @click="submit2">提交</button>
    </div>
    <!-- 弹框 -->
    <div class="tankuangBox2" v-if="tankuangData">
      <div>
        <div class="list">
          <div class="listLeft">学员姓名</div>
          <div class="listRight">{{ lookArray.Approve.RealName }}</div>
        </div>
        <div class="list">
          <div class="listLeft">学员手机号</div>
          <div class="listRight">{{ lookArray.Approve.Tel }}</div>
        </div>
        <div class="list">
          <div class="listLeft">订单号</div>
          <div class="listRight">{{ lookArray.Approve.PONO }}</div>
        </div>
      </div>
      <div
        class="more"
        v-for="(item, index) in this.lookArray.ApproveItem"
        :key="index"
      >
        <div class="list">
          <div class="listLeft">购买产品</div>
          <div class="listRight">
            {{ item.ItemName }}
          </div>
        </div>
        <div class="list">
          <div class="listLeft">原价</div>
          <div class="listRight">¥{{ item.UnitAmount }}</div>
        </div>
        <div class="list">
          <div class="listLeft">现价</div>
          <div class="listRight">¥{{ item.FinalAmount }}</div>
        </div>
        <div class="list">
          <div class="listLeft">申请特殊折扣金额</div>
          <div class="listRight">¥{{ item.ApplyDiscountAmount }}</div>
        </div>
      </div>
      <div>
        <div class="list">
          <div class="listLeft">上传凭证</div>
          <div class="listRight imgbox">
            <div v-for="(item, index) in this.lookArray.Picture" :key="index">
              <img :src="item.AttachmentURL" />
            </div>
          </div>
        </div>
        <div class="list">
          <div class="listLeft">优惠金额</div>
          <div class="listRight">
            已优惠{{ lookArray.Approve.DiscountAmount }}元
          </div>
        </div>
        <div class="list">
          <div class="listLeft">实际支付金额</div>
          <div class="listRight">¥{{ lookArray.Approve.FinalAmount }}</div>
        </div>
        <div class="list">
          <div class="listLeft">审核结果</div>
          <div class="listRight">
            {{
              lookArray.Approve.Status == 0
                ? "未审核"
                : lookArray.Approve.Status == 1
                ? "拒绝"
                : "同意"
            }}
          </div>
        </div>
        <div class="list">
          <div class="listLeft">审核备注</div>
          <div class="listRight">{{ lookArray.Approve.ApproveRemark }}</div>
        </div>
      </div>
    </div>
    <!-- 生成订单 -->
    <div class="orderTkuang" v-if="orderTkuang">
      <div class="box">
        <span>支付链接：</span>
        <div class="text">
          <span id="copyMy">{{ imgUrlCode }}</span>
          <button @click="onCopy">复制</button>
        </div>
        <span>支付二维码</span>
        <img class="imageBox" :src="codeImg" />
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router/index.js";
import { Toast } from "vant";
import baseUrl from '@/request/baseURL.js'
import { Notify } from "vant";
export default {
  name: "manager",
  data() {
    return {
      PhoneNumber: "",
      note: "",
      // buttonText: "",
      buttonText: "",
      formdata: false,
      show: false,
      tankuangData: false,
      orderTkuang: false,

      orderBoxData: false,
      returnData: true,
      radio2: "1",
      fromBoxPush: true,
      fromBoxPay: false,
      payBoxData: false,
      radioBoxData: true,

      fileList: [],
      dataList: {},
      dataList2: {},
      dataList3: {},
      dataList4: {},
      fileListNUM: [],
      fileList2: {},

      imgUrlCode: "",
      codeImg: "",
      listImg: "",

      lookArray: { Approve: {}, ApproveItem: {}, Picture: {} },

      numArray: [],
      sum: "",
      NUM: 0,
      JobRoleID:""
    };
  },
  components: {},
  mounted(){
    this.JobRoleID = this.$route.query.JobRoleID;
    if (window.sessionStorage.getItem('PhoneNumber')==null) {
      this.PhoneNumber = "";
    } else {
      this.PhoneNumber = window.sessionStorage.getItem('PhoneNumber');
      this.onSearch();
    }
    console.log()
  },
  methods: {
     //传入跟进信息排序的数组
    MsgSort(obj) {
      return  obj.sort(function(a, b) {
        return new Date(b.CreatedDate) - new Date(a.CreatedDate);
      });
      // obj.sort((a, b) => {
      //   let t1 = new Date(
      //     Date.parse(b.CreatedDate.replace("T", "  ").slice(0, 19))
      //   );
      //   let t2 = new Date(
      //     Date.parse(a.CreatedDate.replace("T", "  ").slice(0, 19))
      //   );
      //   // console.log(t1.getTime(),t2.getTime());
      //   return t1.getTime() - t2.getTime();
      // });
      // return obj;
    },
    onSearch() {
      var _this = this;
      console.log(sessionStorage.getItem("token"));
      if (_this.PhoneNumber != "") {
        // http://newims.phoneapi.lekaowang.cn
        _this.axios
          .get(baseUrl+"api/Approvals/GetLK_PO", {
            params: {
              LoginId: sessionStorage.getItem("token"),
              PhoneNumber: _this.PhoneNumber,
            },
          })
          .then(function (res) {
            _this.dataList = res.data.Data;
            _this.dataList =_this.MsgSort(_this.dataList);
            console.log(_this.dataList, "_this.dataList");
            for (var i = 0; i < res.data.Data.length; i++) {
              console.log(res.data.Data[i].ISApprove);
              if (res.data.Data[i].ISApprove > 0) {
                _this.buttonText = "查看";
              } else {
                _this.buttonText = "申请折扣";
              }
            }
          })
          .catch(function (err) {
            console.log(err);
          });
      } else {
        Notify({
          type: "warning",
          duration: 1000,
          message: "请先输入手机号在搜索",
        });
      }
    },
    onInput() {
      console.log(this.numArray, "numArray");
      let sum = 0;
      this.numArray.forEach((item) => {
        sum = Number(sum) + Number(item);
      });
      console.log(sum, "sum");
      this.sum = sum;
    },
    // 查看详情
    seeDetail(index){
      window.sessionStorage.setItem("PhoneNumber",this.PhoneNumber);
      this.$router.push({ path: "/discountReview", query: {state:'t',POID:index.POId}})
    },
    //申请折扣审核
    discountReview(index){
      window.sessionStorage.setItem("PhoneNumber",this.PhoneNumber);
      this.$router.push({ path: "/discountReview", query: {state:'f',POID:index.POId}})
    },

    submit1(index) {
      var _this = this;
      if (_this.buttonText == "申请折扣") {
        // _this.formdata = true;
        // _this.show = true;
        // var POID = index.POId;
        // console.log(POID);
        // _this.axios
        //   .get(
        //     "http://newims.phoneapi.lekaowang.cn/api/Approvals/GetLK_POSub",
        //     {
        //       params: {
        //         POID: POID,
        //       },
        //     }
        //   )
        //   .then(function (res) {
        //     _this.dataList2 = res.data.Data;
        //     _this.dataList3 = index;
        //     var NUM = 0;
        //     for (var j = 0; j < _this.dataList2.length; j++) {
        //       console.log(_this.dataList2[j], "294行");
        //       _this.dataList4 = _this.dataList2[j];
        //       NUM += _this.dataList2[j].UnitAmount;
        //       console.log(NUM, "NUM");
        //     }
        //     _this.NUM = NUM;
        //   })
        //   .catch(function (err) {
        //     console.log(err);
        //   });
        _this.$router.push({ path: "/discountReview", query: {state:'f',POID:index.POId}})
      } else {
        _this.$router.push({ path: "/discountReview", query: {state:'t',POID:index.POId}})
        // _this.tankuangData = true;
        // _this.show = true;
        // _this.orderdDetail = true;
        // _this.formdata = false;
        // console.log("传给后台的参数", index.POId);
        // _this.axios
        //   .post(
        //     "http://newims.phoneapi.lekaowang.cn/api/Approvals/GetPODetails",
        //     {
        //       params: {
        //         POID: index.POId,
        //       },
        //     }
        //   )
        //   .then(function (res) {
        //     console.log("后台给的数据", res.data.Data);
        //     _this.lookArray = res.data.Data;           
        //   })
        //   .catch(function (err) {
        //     console.log(err);
        //   });
      }
    },
    submit2() {
      if (this.fileList.length < 1) {
        Toast("请至少上传一张图片作为凭证");
      } else {
        for (var i = 0; i < this.fileListNUM.length; i++) {
          this.fileList2 = this.fileListNUM[i].data;
        }

        var a = [];

        for (var j = 0; j < this.dataList2.length; j++) {
          var obj = {};
          obj["POItemSubID"] = this.dataList2[j].POItemSubID;
          obj["UnitAmount"] = this.dataList2[j].UnitAmount;
          obj["FinalAmount"] = this.dataList2[j].FinalAmount;
          obj["DiscountAmount"] = this.numArray[j];
          a.push(obj);
        }

        var b = [];
        console.log(this.fileListNUM);
        for (var y = 0; y < this.fileList.length; y++) {
          var obj2 = {};
          obj2["Name"] = "1";
          obj2["AttachmentURL"] = this.fileListNUM[y][0].data.ImgUrl;
          b.push(obj2);
          // this.codeImg = require(this.fileListNUM[y][0].data.ImgUrl);
        }

        this.axios
          .get(baseUrl+"api/Approvals/Apply", {
            params: {
              LoginId: sessionStorage.getItem("token"),
              POID: this.dataList3.POId,
              POItemSubJson: JSON.stringify(a),
              picturesjson: JSON.stringify(b),
            },
          })
          .then(function (res) {
            console.log(res.data);
            this.onSearch();
          })
          .catch(function (err) {
            console.log(err);
          });
        console.log("edfghj");
        console.log(sessionStorage.getItem("token"));
        console.log(this.dataList3.POId);
        console.log(JSON.stringify(a));
        console.log(JSON.stringify(b));

        this.buttonText = "查看";
        this.formdata = false;
        this.show = false;
      }
    },
    overlayShow() {
      this.formdata = false;
      this.show = false;
      this.tankuangData = false;
      this.orderTkuang = false;
      this.orderBoxData = false;
      this.returnData = true;
      this.payBoxData = false;
      this.orderdDetail = false;
    },
       //  优惠输入框变化
    changeWords(event, CurrentAmount, itemIndex) {
      
      var _this = this;
        if (CurrentAmount - parseFloat(event.target.value) < 0) {
          Notify({
            type: "warning",
            duration: 2000,
            message: "优惠金额不得大于现价",
          });
          event.target.value = 0;
          // event.target.value = event.target.value.slice(
          //   0,
          //   event.target.value.length - 1
          // );
          _this.orderdDetailObject.DataList[itemIndex].DiscountAmount = parseFloat(event.target.value); 
        }
        if (parseFloat(event.target.value) > 0) {
          event.target.value =   parseFloat(event.target.value);
          _this.orderdDetailObject.DataList[itemIndex].DiscountAmount = event.target.value; 
        }
        if (event.target.value == "") {
          event.target.value = 0;
          _this.orderdDetailObject.DataList[itemIndex].DiscountAmount = event.target.value; 
        }
        if (!/^\d+(\.\d{1,2})?$/.test(parseFloat(event.target.value))) {
            event.target.value  =   Math.floor(event.target.value * 100) / 100;
            _this.orderdDetailObject.DataList[itemIndex].DiscountAmount = event.target.value; 
        }
        _this.getPrice();
      
    },
    //获取总价格
    getPrice() {
      var _this = this;
      _this.originalOrderPrice = 0;
      _this.orderDiscounts = 0;
      _this.orderCurrentPrice = 0;
      _this.orderPaymentPrice = 0;
      _this.orderdDetailObject.DataList.forEach((item) => {
      _this.originalOrderPrice = _this.originalOrderPrice + item.UnitAmount;
      _this.orderDiscounts = parseFloat(_this.orderDiscounts) + parseFloat(item.DiscountAmount);
      _this.orderCurrentPrice = _this.orderCurrentPrice + item.FinalAmount;
      _this.orderPaymentPrice = _this.orderPaymentPrice + (parseFloat(item.FinalAmount) - parseFloat(item.DiscountAmount));
      });

    },



    submitOrder(index) {
      var that = this;
      console.log(this.dataList[index].PONo, "pono");
      that.imgUrlCode =
        "http://quickpay.lekaowang.cn/pono=" + this.dataList[index].PONo;
      this.axios
        .get(baseUrl+"api/Approvals/MakeORCode", {
          params: {
            pono: this.dataList[index].PONo,
            UrlPost: that.imgUrlCode,
          },
        })
        .then(function (res) {
          console.log(res.data, "二维码图片");
          that.codeImg = res.data;
          console.log(that.codeImg);
        })
        .catch(function (err) {
          console.log(err);
        });
      this.show = true;
      this.orderTkuang = true;
    },
    toUploader() {},
    lookOrder() {
      this.show = true;
      this.tankuangData = true;
    },
    // 上传图片的回调
    afterRead(file) {
      console.log(111);
      let that = this;
      let url = baseUrl+"api/Approvals/UploadImg_SaveFile";
      let fd = new FormData();
      fd.append("upImgs", file.file);
      //上传至服务器端
      this.axios
        .post(url, fd)
        .then((res) => {
          console.log(res.data, 414);
          let dat = res.data;
          this.fileListNUM.push(res.data);
          if (dat.Code == 1) {
            that.uploadSaveImg({
              url: dat.PhotoPic,
              type: "图片",
            });
          }
          console.log(this.fileList, "382");
        })
        .catch((err) => {
          alert(err);
        });
    },
    // 删除图片的回调
    beforeDelete(file, detail) {
      return new Promise((resolve, reject) => {
        this.$dialog
          .confirm({
            message: "确认删除图片？",
          })
          .then(() => {
            console.log(this.fileList, detail.index);
            this.fileList.splice(detail.index, 1);
            this.$toast.success("删除成功");
            console.log(this.fileList);
            resolve();
          })
          .catch((error) => {
            this.$toast.fail("已取消");
            reject(error);
          });
      });
    },
    onCopy() {
      var val = document.getElementById("copyMy");
      window.getSelection().selectAllChildren(val);
      document.execCommand("Copy");
    },
    OnClickReturn() {
      window.sessionStorage.removeItem('PhoneNumber');
      router.go(-1); //返回上一层
    },

  },
};
</script>

<style>
* {
  padding: 0;
  margin: 0;
}
.container-manager {
  width: 100%;
  height: 100%;
  border: 1px solid #c9c9c9;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  background: #ebebeb;
}
.container-manager .nav {
  width: 3.55rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.container-manager .spantext {
  width: 0.4rem;
  font-size: 0.15rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
}
.container-manager .nav .searchBox {
  width: 0.5rem;
  height: 0.3rem;
  background: #004995;
  border-radius: 0.04rem;
  border: 0px solid;

  line-height: 0.3rem;
  font-size: 0.13rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;

  margin-right: 0.03rem;
}
.container-manager .form {
  width: 3.55rem;
  background: #ffffff;
  border-radius: 0.05rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0.17rem;
  padding-bottom: 0.17rem;
  margin-top: 0.1rem;
  margin-left: 0.1rem;
  margin-bottom: 0.4rem;
}
.container-manager .form .list {
  width: 3.15rem;
  height: 0.32rem;
  border-bottom: 1px solid #ebebeb;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 0.1rem;
}
.list .listLeft {
  height: 0.21rem;
  font-size: 0.15rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
}
.list .listRight {
  height: 0.21rem;
  font-size: 0.15rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #555555;
}
.manager-form2 {
  width: 3.35rem;
  height: 5.15rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  background: #ffffff;
  border-radius: 0.05rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0.05rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  z-index: 999;
}
.manager-form2 .list {
  width: 2.95rem;
  height: 0.32rem;
  border-bottom: 1px solid #ebebeb;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 0.1rem;
}
.manager-form2 .bottomButton {
  display: block;
  width: 3.15rem;
  height: 0.5rem;
  background: #004995;
  border-radius: 0.05rem;
  border: 0px solid;

  font-size: 0.17rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  margin-top: 0.1rem;
}
.tankuangBox2 {
  width: 3.35rem;
  height: 5.17rem;
  overflow: scroll;
  background: #ffffff;
  border-radius: 0.05rem;
  position: absolute;
  top: 0.59rem;
  left: 0.2rem;
  padding-top: 0.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 999;
}
.tankuangBox2 .list {
  width: 2.95rem;
  height: 0.32rem;
  border-bottom: 1px solid #ebebeb;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.tankuangBox2 .list .listLeft {
  font-size: 0.15rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
}
.tankuangBox2 .list .listRight {
  font-size: 0.15rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #555555;
}
.list .imgbox {
  height: 0.55rem;
  display: flex;
  flex-direction: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: right;
}
.list .imgbox img {
  width: 0.35rem;
  height: 0.35rem;
  border: 1px solid #004995;
  border-radius: 0.05rem;
  margin-left: 0.05rem;
}
.van-field {
  padding: 0;
}
.van-field__label {
  font-size: 0.15rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
}
.manager-form2 .numBox {
  width: 2.95rem;
  height: 0.32rem;
  border-bottom: 1px solid #ebebeb;
  padding: 0px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 0.1rem;
}
.list .color {
  color: #004995;
}
.form .buttonBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 0;
}
.form .buttonBox .bottomButton {
  width: 1.5rem;
  height: 0.5rem;
  background: #004995;
  border-radius: 0.05rem;

  font-size: 0.17rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
}
.form .buttonBox .bottomButton1 {
  margin-right: 0.15rem;
}

.orderTkuang {
  width: 3.35rem;
  height: 4.43rem;
  background: #ffffff;
  border-radius: 0.05rem;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}
.orderTkuang .box {
  width: 2.79rem;
  height: 3.5rem;
  margin-top: 0.4rem;
  margin-left: 0.4rem;
  display: flex;
  flex-direction: column;
}
.box span {
  font-size: 0.15rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
  margin-bottom: 0.15rem;
}
.box .text {
  width: 2.78rem;
  height: 0.42rem;
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: 0.3rem;
}
.box .text span {
  width: 2rem;
  font-size: 0.15rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  margin-right: 0.1rem;

  word-wrap: break-word;
}
.box .text button {
  width: 0.43rem;
  height: 0.21rem;
  background: #004995;
  border-radius: 0.04rem;

  font-size: 0.11rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  border: 0px solid;
}
.box .imageBox {
  width: 2.15rem;
  height: 2.15rem;
  margin: auto;
}
.return {
  font-size: 0.15rem;
  color: #fff;
  position: fixed;
  right: 16px;
  bottom: 88px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  z-index: 1500;
  overflow: hidden;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  background-color: #004995;
}

.buttonContent{
  display: flex;
  justify-content: space-between;
  width: 3.15rem;
}
.buttonContent button{
  width: 1rem;
  height: 0.335rem;
  border-radius: 0.1rem;
  background-color: #004995;
  color: #fff;
  font-size: 0.15rem;
}
</style>
