<template>
  <div class="relearnRecords">
    <div class="basicInformation">
      <ul>
        <li>
          <span class="reviewTitle">学员姓名：</span>
          <span class="reviewContent">{{RealName}}</span>         
        </li>
        <li>
          <span class="reviewTitle">手机号：</span>
          <span class="reviewContent">{{Tel}}</span>
        </li>
        <li>
          <span class="reviewTitle">账户余额：</span>
          <span class="reviewContent">{{Amount}}</span>
        </li>
        <li>
          <span class="reviewTitle">销售申请备注：</span>
          <span class="reviewContent">{{Remark}}</span>
        </li>
      </ul>
    </div>
    <p class="productTitle">原合同产品</p>
    <div class="recordList" v-for="(item,index) in OldProduct " :key="index"> 
      <ul class="oldName">
        <li>
          <span class="recordTitle"> 
            产品名称
          </span>
          <span class="recordContent">
            {{item.ProductName}}
          </span>
        </li>
        <li>
          <span class="recordTitle"> 
            合同金额
          </span>
          <span class="recordContent">
            {{item.Amount}}
          </span>
        </li>
        <li>
          <span class="recordTitle"> 
            开课时间
          </span>
          <span class="recordContent">
            {{item.CreatedDate}}
          </span>
        </li>
      </ul>
      <ul class="oldPrice">
        <li>
          <span class="recordTitle"> 
            合同金额
          </span>
          <span class="recordContent">
            {{item.Amount}}
          </span>
        </li>
        <li>
          <span class="recordTitle"> 
            参考实际合同金额
          </span>
          <span class="recordContent">
            {{item.SJContractPrice}}
          </span>
        </li>
        <li>
          <span class="recordTitle"> 
            模考返现
          </span>
          <span class="recordContent">
            {{item.ExamReturnAmount}}
          </span>
        </li>
        <li>
          <span class="recordTitle"> 
            直播返现
          </span>
          <span class="recordContent">
            {{item.LiveAmount}}
          </span>
        </li>
        <li>
          <span class="recordTitle"> 
            合同状态
          </span>
          <span class="recordContent">
            {{item.IsExpire}}
          </span>
        </li>
        <li>
          <span class="recordTitle"> 
            折算价格
          </span>
          <span class="recordContent">
            {{item.ConversionPrice}}
          </span>
        </li>
      </ul>
    </div>
    <div class="oldPriceTotal">
      <p>合计</p>
      <ul class="oldPriceTotalUl1">
        <li>
          <span class="reviewTitle">合同金额</span>
          <span class="reviewContent1">{{oldContractPrice.toFixed(2)}}</span>
        </li>
        <li>
          <span class="reviewTitle">模考返现</span>
          <span class="reviewContent1">{{oldTestPrice.toFixed(2)}}</span>
        </li>
        <li>
          <span class="reviewTitle">直播返现</span>
          <span class="reviewContent1">{{oldLivePrice.toFixed(2)}}</span>
        </li>
      </ul>
      <ul class="oldPriceTotalUl2">
        <li style="width:60%;">
          <span class="reviewTitle">参考实际合同金额</span>
          <span class="reviewContent1">{{oldSJContractPrice.toFixed(2)}}</span>
        </li>
        <li style="width:40%;">
          <span class="reviewTitle">折算价格</span>
          <span class="reviewContent1">{{oldConversionAmount.toFixed(2)}}</span>
        </li>
      </ul>
    </div>



    <p class="productTitle" style="margin-top:0.3rem;">{{ApplyType}}产品</p>
    <div class="recordList" v-for="(item,index1) in NewProduct" :key="'i'+ index1"> 
      <ul>
        <li>
          <span class="recordTitle"> 
            产品名称
          </span>
          <span class="recordContent">
            {{item.ProductName}}
          </span>
        </li>
      </ul>
      <ul class="oldPrice">
        <li>
          <span class="recordTitle"> 
            官网价格
          </span>
          <span class="recordContent">
            {{item.CurrentAmount}}
          </span>
        </li>
        <li>
          <span class="recordTitle"> 
            产品底价
          </span>
          <span class="recordContent">
            {{item.ProductReservePrice}}
          </span>
        </li>
      </ul>
      <ul>
        <li>
          <span class="recordTitle"> 
            是否返现
          </span>
          <span class="recordContent" style="display:flex;">
            <van-checkbox v-model="item.SimulationReturnType" shape="square" icon-size="16px"  >模考返现</van-checkbox>
            <van-checkbox v-model="item.LiveReturnType" shape="square" icon-size="16px" style="margin-left:0.3rem;"  >直播返现</van-checkbox>
          </span>
        </li>
      </ul>
      <ul class="oldPrice">
        <li>
          <span class="recordTitle"> 
            升级金额
          </span>
          <span class="recordContent">
            {{item.UpdateAmount}}
          </span>
        </li>
        <li>
          <span class="recordTitle"> 
            合同金额
          </span>
          <span class="recordContent">
            <input type="text" style="width:0.8rem;text-align: center;" v-model="item.ContractAmount"  @input="changeWords(item.ContractAmount,item.CurrentAmount,index1)" >
          </span>
        </li>
        <li>
          <span class="recordTitle"> 
            服务手续费
          </span>
          <span class="recordContent">
            <input type="text" style="width:0.8rem;text-align: center;" v-model="item.ServiceAmount" @input="changeWords1(item.ServiceAmount,index1)" >
          </span>
        </li>
        <li></li>
      </ul>


    </div>
    <div class="basicInformation">
       <ul>
        <li>
          <span class="reviewTitle lin">审核备注：</span> <br>
        </li>
        <li class="hei2">
          <textarea  class="remarks" v-model="CheckRemark" ></textarea>
        </li>
      </ul>
    </div>
    <div class="submitBtn">
      <button @click="RefuseUpgrade">
        拒绝
      </button>
      <button @click="SubmitUpgrade"  :disabled="btnDisabled">
        保存
      </button>
    </div>
    <div v-show="overlayShow" class="maskBg">
      <div style="display: flex;align-items: center;justify-content: center;height: 100%;">
        <van-loading   size="100px"  color="#004995" />
      </div>
    </div>
    



    <van-dialog v-model="show" title="提示" showCancelButton   showConfirmButton :beforeClose="beforeClose" >
      <p class="dialogP">合同金额低于产品底价</p>
    </van-dialog>
    <div class="return" @click="OnClickReturn">返回</div>
  </div>
</template>
<script>
import router from "@/router/index.js";
import {get,post} from '@/request/http'
import {GetUpgradeChangeCourses,UpgradeAndChangeCoursesNew,UpgradeAndChangeCoursesNewRefuse}  from '@/request/api'
import Vue from 'vue';
import { Toast ,Dialog } from "vant";
Vue.use(Dialog);
export default {
  name: "upgradeReview",
  data() {
    return {
      relearnList: [],
      OldProduct:[],
      NewProduct:[],
      ApplyType:"",
      CheckRemark:"",
      RealName:"",
      Tel:"",
      Amount:"",
      Remark:"",
      //原有产品模考返现合计
      oldTestPrice:0,
      //原有直播模考返现合计
      oldLivePrice:0,
      //原有合同金额合计
      oldContractPrice:0,
      //原有参考实际合同金额合计
      oldSJContractPrice:0,
      //原有折算金额
      oldConversionAmount:0,
      show:false,
      overlayShow:false,
      btnDisabled:false
    };
  },
  mounted(){
    this.getRelearnList();
  },
  methods: {
    getRelearnList(){
      var _this = this;
      post(GetUpgradeChangeCourses+'?ApplyID='+_this.$route.query.ApplyID+'&CustomerID='+_this.$route.query.CustomerID)
          .then(function (res) {
            // Code=200 为成功 Code=0为数据为空
            if (res.Code == "200") {
              _this.OldProduct= res.Data.OldProduct;
              res.Data.NewProduct.forEach(element => {
                element.ContractAmount = 0;
                element.ServiceAmount = 0;
                element.SimulationReturnType =  (element.SimulationReturnType == 'True' ? true : false)  ;
                element.LiveReturnType =  (element.LiveReturnType == 'True' ? true : false)  ;
              });
              _this.NewProduct= res.Data.NewProduct;
              _this.RealName= res.Data.RealName;
              _this.Tel= res.Data.Tel;
              _this.Amount= res.Data.Amount;
              _this.Remark= res.Data.Remarks;
              _this.ApplyType = _this.$route.query.ApplyType== 1 ? '升级' : '更换' ;
              // 使用reduce方法计算价格的总和
              _this.oldTestPrice = res.Data.OldProduct.reduce((accumulator, currentValue) => {
                return accumulator + parseFloat(currentValue.ExamReturnAmount);
              }, 0);
              _this.oldTestPrice = res.Data.OldProduct.reduce((accumulator, currentValue) => {
                return accumulator + parseFloat(currentValue.LiveAmount);
              }, 0);
              _this.oldSJContractPrice = res.Data.OldProduct.reduce((accumulator, currentValue) => {
                return accumulator + parseFloat(currentValue.SJContractPrice);
              }, 0);
              _this.oldContractPrice = res.Data.OldProduct.reduce((accumulator, currentValue) => {
                return accumulator + parseFloat(currentValue.Amount);
              }, 0);
              _this.oldConversionAmount = res.Data.OldProduct.reduce((accumulator, currentValue) => {
                return accumulator + parseFloat(currentValue.ConversionPrice);
              }, 0);
            } else {
              Toast(res.Msg);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    changeWords(amount,CurrentAmount,index) {
      amount = amount
        .replace(/[^\d.]/g, "") //只能输入数字
        .replace(/^()*(\d+)\.(\d\d).*$/, "$1$2.$3") //只能输入两个小数
        .replace(/\.{2,}/g, "."); //出现多个点时只保留第一个
      // 第一位不让输小数点
      if (amount == ".") {
        amount = "";
      }
      // 如果第一位是0，第二位必须大于0或者小数点
      if (amount.substring(0, 1) == 0) {
        if (amount.substring(1, 2) > 0) {
          amount = amount.substring(1, 2);
        } else if (
          amount.substring(1, 2) === 0 ||
          amount.substring(1, 2) === "0"
        ) {
          amount = "0";
        }
      } else {
        // 如果第一位数字大于0（不等于0肯定就大于0），仅需考虑第二位是小数点的情况
        if (amount.indexOf(".") !== -1) {
          if (amount.substring(0, 1) > 0) {
            console.log("第一位大于0");
          } else {
            console.log("第一位等于0");
            if (amount.substring(2, 3) > 0) {
              console.log("小数点后第一位大于0");
            } else {
              console.log("小数点后第一位等于0");
              amount = "0.";
            }
          }
        } else {
          console.log("没有小数点，正常输入");
        }
      }
      
      if (amount == "") {
        amount = "0";
      }
      if ((parseFloat(CurrentAmount)- (parseFloat(amount))).toFixed(2) < 0) {
         Toast("合同金额不得大于官网价格");
          amount = "0";
      }
      this.NewProduct[index].ContractAmount = amount;
    },
    changeWords1(amount,index) {
      amount = amount
        .replace(/[^\d.]/g, "") //只能输入数字
        .replace(/^()*(\d+)\.(\d\d).*$/, "$1$2.$3") //只能输入两个小数
        .replace(/\.{2,}/g, "."); //出现多个点时只保留第一个
      // 第一位不让输小数点
      if (amount == ".") {
        amount = "";
      }
      // 如果第一位是0，第二位必须大于0或者小数点
      if (amount.substring(0, 1) == 0) {
        if (amount.substring(1, 2) > 0) {
          amount = amount.substring(1, 2);
        } else if (
          amount.substring(1, 2) === 0 ||
          amount.substring(1, 2) === "0"
        ) {
          amount = "0";
        }
      } else {
        // 如果第一位数字大于0（不等于0肯定就大于0），仅需考虑第二位是小数点的情况
        if (amount.indexOf(".") !== -1) {
          if (amount.substring(0, 1) > 0) {
            console.log("第一位大于0");
          } else {
            console.log("第一位等于0");
            if (amount.substring(2, 3) > 0) {
              console.log("小数点后第一位大于0");
            } else {
              console.log("小数点后第一位等于0");
              amount = "0.";
            }
          }
        } else {
          console.log("没有小数点，正常输入");
        }
      }
      
      if (amount == "") {
        amount = "0";
      }
      this.NewProduct[index].ServiceAmount = amount;
    },
    //审核保存提交按钮
    SubmitUpgrade(){
      var _this = this;
      let ProductReservePriceLength = _this.NewProduct.filter(item => item.ProductReservePrice  == "");
      if (_this.NewProduct - ProductReservePriceLength == 0) {
        _this.saveUpgrade();
      } else {
        let exceed = _this.NewProduct.filter(item => parseFloat(item.ContractAmount) - (item.ProductReservePrice =="" ?  0  : parseFloat(item.ProductReservePrice)) < 0);
        if (exceed.length > 0) {
          _this.show = true;
        } else {
          _this.saveUpgrade();
        }
      }
    },
    //保存
    saveUpgrade(){
      var _this = this;
      _this.NewProduct.forEach(i => {
        if (i.ContractAmount == ""||i.ContractAmount == null) {
          i.ContractAmount = 0;
        }
        if (i.ServiceAmount == ""||i.ServiceAmount == null) {
          i.ServiceAmount = 0;
        }
      });
      let ClassItemStr = _this.NewProduct.map(item => `${item.UpdateAmount}@@@${item.ContractAmount}@@@${item.ServiceAmount}@@@${item.SimulationReturnType}@@@${item.LiveReturnType}@@@${item.ProductItemId}|||`).join('');
      let obj = {
        LoginId:sessionStorage.getItem("token"),
        ApplyID:_this.$route.query.ApplyID,
        ApplyType:_this.$route.query.ApplyType,
        isExample:"1",
        ClassItem:ClassItemStr,
        Remark:_this.CheckRemark,
        ExamReturnAmount:_this.oldTestPrice,
        TotalLiveAmount:_this.oldLivePrice,
        TotalConversionPrice:_this.oldConversionAmount,
        TotalOldAmount:_this.oldContractPrice
      };
      _this.btnDisabled = true;
      _this.overlayShow = true;

      post(UpgradeAndChangeCoursesNew,obj)
        .then(function (res) {
          _this.btnDisabled = false;
          _this.overlayShow = false;
          if (res.Code == "200") {
            Toast("保存成功！");
            router.go(-1);
          } else {
            Toast(res.Msg);
          }
        })
        .catch(function (error) {
          _this.btnDisabled = false;
          _this.overlayShow = false;
          console.log(error);
        });
    },
    // 提示弹窗
    beforeClose(action, done) {
      var _this = this;
      if (action === 'confirm') {
        _this.saveUpgrade();
        done();
      } else {
        done();
      }
    },

    //审核拒绝提交
    RefuseUpgrade(){
      var _this = this;
      if (_this.CheckRemark.trim() ==""  ) {
        Toast("拒绝时备注不得为空！");
        return false;
      }
      post(UpgradeAndChangeCoursesNewRefuse+"?LoginId="+sessionStorage.getItem("token")+"&ApplyID="+_this.$route.query.ApplyID+"&Remark="+_this.CheckRemark,{})
        .then(function (res) {
          if (res.Code == "200") {
            Toast("提交成功！");
            router.go(-1);
          } else {
            Toast(res.Msg);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    OnClickReturn() {
      router.go(-1); //返回上一层
    },
  },
};
</script>


<style>
.maskBg{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, .7);
}
</style>

<style scoped>
.relearnRecords{
  background: #fff;
  height: 100%;
}
.basicInformation{
  padding: 0.2rem;
}

.basicInformation ul li{
  width: 3.55rem;
  height: 0.35rem;
  line-height: 0.35rem;
  margin: 0 auto ;
  font-size: 0.15rem;
}
.reviewTitle{
  font-weight: bold;
}
.productTitle{
  font-size: 0.15rem;
  font-weight: bold;
  padding-left: 0.2rem;
}
.remarks{
  width: 95%;
  height: 2rem;
  font-size: 0.15rem;
}
.lin{
  line-height: 0.35rem;
}
.hei2{
  height: 2rem !important;
}
.reviewtips{
  color: #ff0000;
  font-size: 0.12rem;
}

.recordList{
  background: rgb(244, 244, 244);
  width: 3.4rem;
  margin: 0.2rem auto;
}
.recordList ul{
  margin: 0 auto;
}
.recordList ul li{
  display: flex;
  justify-content: space-between;
  font-size: 0.15rem;
  width:100%;
  align-items: center;
}
.recordList ul li  .recordTitle{
  width: 30%;
  display: block;
  /* text-align: center; */
  /* padding: 0.1rem 0; */
}
.recordList ul li  .recordContent{
  width: 70%;
  display: block;
  /* text-align: center; */
  padding: 0.1rem 0;
  
}

.recordList .oldPrice{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

}
.recordList .oldPrice li{
  display: flex;
  justify-content: space-between;
  width: 50%;
}


.recordList .oldPrice li .recordTitle{
  width: 65% !important;
  display: block;
  /* text-align: center; */
  /* padding: 0.1rem 0; */
}
.recordList .oldPrice li .recordContent{
  text-align: center;
}
.oldPriceTotal{
  padding: 0 0.15rem;
}
.oldPriceTotal p{
  font-size:0.15rem;
  line-height: 0.4rem;
}
.oldPriceTotal ul{
  display: flex;
  justify-content: space-between;
}
.oldPriceTotal ul li{
  font-size:0.15rem;
  line-height: 0.4rem;
}
.oldPriceTotalUl1 li{
  width: 33%;
}
.oldPriceTotalUl1 .reviewContent1{
  text-align: center;
  display: inline-block;
  width: 0.5rem;
}
.oldPriceTotalUl2 .reviewContent1{
  text-align: center;
  display: inline-block;
  width: 0.5rem;
}
.dialogP{
  text-align: center;
  line-height: 1rem;
}

.submitBtn{
  display: flex;
  justify-content: space-around;
  margin-top: 0.3rem;
  margin-bottom: 2rem;
}
.submitBtn button{
  display: block;
  width: 1.2rem;
  height: 0.35rem;
  border-radius: 5px;
  border: none;
  font-size: 0.15rem;
  color:#fff ;
  background: #004995;
}
</style>
