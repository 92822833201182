
<script>
//线上接口地址域名
// const apiUrl = "http://192.168.20.165";
const apiUrl = "https://newims.phoneapi.lekaowang.cn";

export default {
  apiUrl,
};
</script>
