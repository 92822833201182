<template>
  <div class="relearn">
     <div class="nav">
      <span class="spantext">电话</span>
      <van-search
        v-model="PhoneNumber"
        ref="PhoneNumber"
        left-icon="none"
        right-icon="none"
        background="#EBEBEB"
        input-align="left"
        maxlength="13"
        @search="onSearch"
      />
      <button class="searchBox" @click="onSearch">搜索</button>
    </div>
    <div class="form" v-for="(item, index) in dataList" :key="index">
      <div class="list">
        <div class="listLeft">学员姓名</div>
        <div class="listRight">{{ item.RealName }}</div>
      </div>
      <div class="list">
        <div class="listLeft">所属客服</div>
        <div class="listRight">{{ item.UserName }}</div>
      </div>
      <div class="list">
        <div class="listLeft">申请类型</div>
        <div class="listRight">{{ item.ApplyType }}</div>
      </div>
      <div class="list">
        <div class="listLeft">申请时间</div>
        <div class="listRight">{{ item.Createdate | timeDate  }}</div>
      </div>
      <div class="list">
        <div class="listLeft">处理人</div>
        <div class="listRight">{{ item.ApproveUserName}}</div>
      </div>
      <div class="list">
        <div class="listLeft">处理时间</div>
        <div class="listRight">{{ item.ApproveDate | timeDate  }}</div>
      </div>
      <div class="list">
        <div class="listLeft">处理状态</div>
        <div class="listRight">{{ item.Status}}</div>
      </div>
      <div class="list">
        <div class="listLeft">审批备注</div>
        <div class="listRight">{{ item.ApproveRemark }}</div>
      </div>
      <div class="buttonBox" v-if="item.Status=='已同意'||item.Status=='已拒绝'">
        <button class="bottomButton" @click="upgradeCheck(item)">
          查看
        </button>
      </div>
      <div class="buttonBox" v-else>
        <button class="bottomButton" @click="upgradeReview(item)">
          审核
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import router from "@/router/index.js";
import { Toast } from "vant";
import {get} from '@/request/http'
import {UpgradeChangeCoursesListNew,}  from '@/request/api'
import { transformTimestamp } from "@/common/common.js";

export default {
  name: "upgrade",
  data() {
    return {
      PhoneNumber: "",
      dataList:[],
    };
  },
  filters: {
    timeDate(time) {
        return transformTimestamp(time);
    }
  },

  mounted() {
    this.onSearch();
  },
  methods: {
    onSearch() {
      var _this = this;
      get(UpgradeChangeCoursesListNew+'?Tel='+this.PhoneNumber,{}).then(res=>{
        if (res.Code=='200') {
          _this.dataList = res.Data;
        }else{
          Toast(res.Msg);
        }
      },err=>{
          console.log(err) 
      }).catch()
    },
    //输入框清除按钮点击后操作
    clearPhoneNumber(){
      this.dataList = [];
    },
    //输入框手机号变化时操作
    phoneNumberChange(){
      if (this.PhoneNumber=="") {
         this.dataList = [];
      }
    },
    OnClickReturn() {
      router.go(-1); //返回上一层
    },
    upgradeReview(item){
      window.localStorage.setItem('upgradePhone',this.PhoneNumber);
      router.push({ path: "/upgradeReview", query: { CustomerID: item.CustomerID ,ApplyID: item.ApplyID ,  ApplyType: item.ApplyType== '升级' ? 1 : 2  } });
    },
    upgradeCheck(item){
      window.localStorage.setItem('upgradePhone',this.PhoneNumber);
      router.push({ path: "/upgradeRecords", query: { CustomerID: item.CustomerID ,ApplyID: item.ApplyID ,ApplyType: item.ApplyType== '升级' ? 1 : 2  } });
    },

  },
};
</script>

<style scoped>
.relearn {
  width: 100%;
  height: 100%;
  border: 1px solid #c9c9c9;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  background: #ebebeb;
}
.relearn .nav {
  width: 3.55rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 0;
}
.relearn .spantext {
  width: 0.4rem;
  font-size: 0.14rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
}
.relearn .searchBox {
  width: 0.5rem;
  height: 0.3rem;
  background: #004995;
  border-radius: 0.04rem;
  border: 0px solid;
  line-height: 0.3rem;
  font-size: 0.13rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  margin-right: 0.03rem;
}
.van-search__content {
  width: 1.8rem;
}
.van-field__control {
  height: 0.3rem;
  line-height: 0.3rem;
  font-size: 0.14rem;
}




.form{
  margin-left: 0 !important;
  margin-bottom: 0 !important;
}

.relearn .form {
  width: 3.75rem;
  /* height: 5.02rem; */
  background: #ffffff;
  border-radius: 0.05rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding-top: 0.17rem; */
  padding-bottom: 0.15rem;
  margin-top: 0.2rem;
}


.relearn .form .list {
  width: 3.15rem;
  height: 0.32rem;
  border-bottom: 1px solid #ebebeb;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 0.1rem;
}
.list .listLeft {
  height: 0.21rem;
  font-size: 0.15rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
  width: 0.8rem;
}
.list .listRight {
  height: 0.21rem;
  font-size: 0.15rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #555555;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 2.25rem;
}
.buttonBox{
  display: flex;
  justify-content: space-evenly;
  width: 85%;
}


.bottomButton {
  width: 1.5rem;
  height: 0.5rem;
  background: #004995;
  border-radius: 0.05rem;
  font-size: 0.17rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  margin-top: 0.2rem;
}
.return {
  font-size: 0.15rem;
  color: #fff;
  position: fixed;
  right: 16px;
  bottom: 88px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  z-index: 15;
  overflow: hidden;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  background-color: #004995;
}

/deep/ .van-field__button{
  padding-left: 25px;
}

</style>
