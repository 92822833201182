<template>
	<div class="container1">
		<img src="../images/loginImg.png" />
		<span class="spanBox">点趣教育管理系统</span>
		<van-form class="formBox">
			<div class="inputBox1">
				<van-field class="value1" v-model="loginForm.email" placeholder="请输入邮箱账号" name="email" clearable
					clickable center @keyup="mousemove" @focus="focus" />
			</div>
			<div class="inputBox2">
				<van-field class="value2" v-model="loginForm.password" name="text" center placeholder="请输入动态码"
					@keyup="mousemove" @focus="focus">
					<template #button>
						<van-button size="small" type="primary" @click="getCode">获取动态码</van-button>
					</template>
				</van-field>
			</div>
			<!-- <div class="inputBox2">
				<van-field class="value2" v-model="loginForm.password" name="text" type="password" placeholder="请输入密码"
					clearable clickable center @keyup="mousemove" @focus="focus">
				</van-field>
			</div> -->
			<!-- <div class="checkBox">
				<van-checkbox v-model="checked" icon-size="15px" checked-color="#004995" shape="square"
					@click="checkBoxFun">记住密码</van-checkbox>
			</div> -->
			<div class="buttonBox1">
				<button class="button" id="button" @click="onLogin">登录</button>
			</div>
		</van-form>
	</div>
</template>

<script>
	import {
		Toast
	} from "vant";
	import router from "@/router/index.js";
	import $ from "jquery";
	import BASEURL from "@/request/baseURL.js";
	export default {
		name: "login",
		components: {},
		data() {
			return {
				loginForm: {
					email: sessionStorage.getItem("email")||"",
					password: "",
				},
				checked: false,
				userToken: "",
				style: "",
			};
		},
		methods: {
			getCode() {
				console.log(BASEURL)
				var _this = this;
				if (_this.loginForm.email === "") {
					Toast("邮箱账号不能为空");
					return
				}
				_this.axios
					.get(BASEURL + "api/login/SendLoginIdentifyingCode", {
						params: {
							// 传参
							email: _this.loginForm.email,
						},
					})
					.then(function(res) {
						console.log(res);
						// code=1 请求成功；code=0 请求失败
						if (res.data.Code == 1) {
							if (res.data.Data == "") {
								Toast(res.data.Messages);
							}

							console.log(res);
						} else {
							Toast(res.data.Messages);
						}
					})
					.catch(function(error) {
						console.log(error);
					});
			},
			// 点击登录
			onLogin() {
				console.log(localStorage.getItem("s"))
				var _this = this;
				if (_this.loginForm.email === "" || _this.loginForm.password === "") {
					Toast("邮箱账号或密码不能为空");
				} else {
					console.log("开始发送请求");
					// http://newims.phoneapi.lekaowang.cn
					_this.axios
						.get(BASEURL + "api/login/LoginsH5", {
							params: {
								// 传参
								email: _this.loginForm.email,
								LoginCode: _this.loginForm.password,
							},
						})
						.then(function(res) {
							console.log(res);
							// code=1 请求成功；code=0 请求失败
							if (res.data.Code == "1") {
								const LoginId = res.data.Data.LoginId;
								console.log(LoginId, "LoginId");
								sessionStorage.setItem("token", res.data.Data.LoginId);
								sessionStorage.setItem("email", _this.loginForm.email);

								let userInfo = {
									email: _this.loginForm.email,
									password: _this.loginForm.password,
								}
								if (_this.checked == "true") {
									localStorage.setItem("userInfo", userInfo);
								}

								console.log(res.data.Data.JobRoleID, "JobRoleID");
								// 跳转到一个公共页面
								router.push({
									path: "/enterIndex",
									query: {
										JobRoleID: res.data.Data.JobRoleID
									},
								});
							} else {
								Toast(res.data.Messages);
							}
						})
						.catch(function(error) {
							console.log(error);
						});
				}
			},
			// onLogin() {
			// 	console.log(localStorage.getItem("s"))
			// 	var _this = this;
			// 	if (_this.loginForm.email === "" || _this.loginForm.password === "") {
			// 		Toast("邮箱账号或密码不能为空");
			// 	} else {
			// 		console.log("开始发送请求");
			// 		// http://newims.phoneapi.lekaowang.cn
			// 		_this.axios
			// 			.get("http://newims.phoneapi.lekaowang.cn/api/login/Logins", {
			// 				params: {
			// 					// 传参
			// 					email: _this.loginForm.email,
			// 					password: _this.loginForm.password,
			// 				},
			// 			})
			// 			.then(function(res) {
			// 				console.log(res);
			// 				// code=1 请求成功；code=0 请求失败
			// 				if (res.data.Code == "1") {
			// 					const LoginId = res.data.Data.LoginId;
			// 					console.log(LoginId, "LoginId");
			// 					sessionStorage.setItem("token", res.data.Data.LoginId);
			// 					let userInfo = {
			// 						email: _this.loginForm.email,
			// 						password: _this.loginForm.password,
			// 					}
			// 					if (_this.checked == "true") {
			// 						localStorage.setItem("userInfo", userInfo);
			// 					}

			// 					console.log(res.data.Data.JobRoleID, "JobRoleID");
			// 					// 跳转到一个公共页面
			// 					router.push({
			// 						path: "/enterIndex",
			// 						query: {
			// 							JobRoleID: res.data.Data.JobRoleID
			// 						},
			// 					});
			// 				} else {
			// 					Toast(res.data.Messages);
			// 				}
			// 			})
			// 			.catch(function(error) {
			// 				console.log(error);
			// 			});
			// 	}
			// },
			// 记住密码
			checkBoxFun() {
				this.checked = true; //修改登录状态为true
				localStorage.setItem("s", this.checked);
				console.log(localStorage.s);
			},
			// 账号密码不为空时，登录按钮变色
			mousemove() {
				if (this.loginForm.email !== "" && this.loginForm.password != "") {
					$(".button").css("background", "#004995");
					return false;
				}
				return false;
			},
			// 点击输入框时，边框变色
			focus() {
				$(".value1").css("border", "1px solid #004995");
				$(".value2").css("border", "1px solid #004995");
				console.log(this.loginForm.email)
			},
		},
	};
</script>

<style>
	* {
		padding: 0;
		margin: 0;
	}

	.van-button--small {
		min-width: 60px;
		height: 30px;
		padding: 0 !important;
		font-size: 12px;
	}

	.van-button--primary {
		color: #fff;
		background-color: #004995 !important;
		border: 1px solid #004995 !important;
	}

	.container1 {
		width: 100%;
		height: 6.67rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		background: #ffffff;
	}

	.container1 img {
		width: 0.8rem;
		height: 0.69rem;
		margin-top: 0.8rem;
	}

	.container1 .spanBox {
		height: 0.25rem;
		font-size: 0.18rem;
		font-family: PingFang SC;
		font-weight: 600;
		color: #333333;
		margin-top: 0.19rem;
	}

	.formBox {
		width: 2.95rem;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.formBox .inputBox1 {
		width: 2.95rem;
		height: 0.5rem;
		margin-top: 0.1rem;
		font-size: 0.16rem;
	}

	.formBox .inputBox2 {
		width: 2.95rem;
		height: 0.5rem;
		margin-top: 0.17rem;
	}

	.formBox .value1 {
		width: 2.95rem;
		height: 0.5rem;
		background: #ffffff;
		border: 1px solid #818181;
		border-radius: 0.05rem;
	}

	.formBox .value2 {
		width: 2.95rem;
		height: 0.5rem;
		background: #ffffff;
		border: 1px solid #818181;
		border-radius: 0.05rem;
	}

	.checkBox {
		margin-top: 0.36rem;
	}

	.buttonBox1 {
		width: 2.95rem;
		height: 0.5rem;
		margin-top: 0.1rem;
	}

	.buttonBox1 .button {
		width: 2.95rem;
		height: 0.5rem;
		background: #b2b2b2;
		border-radius: 0.05rem;
		border: 0px solid;

		font-size: 0.17rem;
		font-family: PingFang SC;
		font-weight: 400;
		color: #ffffff;
	}

	.van-checkbox__label {
		height: 0.21rem;
		font-size: 0.16rem;
		font-family: PingFang SC;
		font-weight: 400;
		color: #b2b2b2;
	}
</style>