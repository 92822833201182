<template>
    <div class="discountReview">
        <!-- 查看订单详情 -->
        <div class="orderdDetailBox" v-if="orderdDetail">
            <ul class="ulContent">
                <li>
                    <span>订单号：</span>
                    <span>{{ orderdDetailObject.PONO }}</span>
                </li>
                <li></li>
            </ul>
            <ul class="ulContent">
                <li>
                    <span>姓名：</span>
                    <span>{{ orderdDetailObject.RealName }}</span>
                </li>
                <li>
                    <span>购买手机号：</span>
                    <span>{{ orderdDetailObject.Tel}}</span>
                </li>
            </ul>
            <div id="PUSH" style="width:100%;">
                <p>购买产品</p>
                <div class="productContent" v-for="(item,j) in orderdDetailObject.DataList" :key="'product'+j"> 
                    <div class="productOne">
                        <ul class="ulContent">
                            <li style="width:100%;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;">产品名称：<span>{{item.ItemName}}</span></li>
                        </ul>
                        <ul class="ulContent">
                            <li>原价：<span>{{item.UnitAmount}}</span></li>
                            <li>现价：<span>{{item.FinalAmount}} </span></li>
                        </ul>
                        <ul class="ulContent">
                            <li>申请优惠价格：<input type="number" v-model="item.DiscountAmount"    :disabled="orderdDetail" ></li>
                            <li>实际支付价格：<span>{{(item.UnitAmount - item.DiscountAmount).toFixed(2)}}</span>  </li>
                        </ul>                        
                    </div> 
                </div>
            </div>
            <div class="orderTotal">
                <p>订单合计</p>
                <ul class="ulContent">
                    <li>原价：{{originalOrderPrice}} </li>
                    <li>现价：{{orderCurrentPrice}}</li>
                </ul>
                <ul class="ulContent">
                    <li>申请优惠：{{orderDiscounts}}</li>
                    <li>实际支付：{{orderPaymentPrice}}</li>
                </ul>
            </div>
        </div>
        <div class="orderdDetailBox" v-else>
            <ul class="ulContent">
                <li>
                    <span>订单号：</span>
                    <span>{{ orderdDetailObject.PONO }}</span>
                </li>
                <li></li>
            </ul>
            <ul class="ulContent">
                <li>
                    <span>姓名：</span>
                    <span>{{ orderdDetailObject.RealName }}</span>
                </li>
                <li>
                    <span>购买手机号：</span>
                    <span>{{ orderdDetailObject.Tel}}</span>
                </li>
            </ul>
            <div id="PUSH" style="width:100%;">
                <p>购买产品</p>
                <div class="productContent" v-for="(item,j) in orderdDetailObject.DataList" :key="'product'+j"> 
                    <div class="productOne">
                        <ul class="ulContent">
                            <li style="width:100%;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;">产品名称：<span>{{item.ItemName}}</span></li>
                        </ul>
                        <ul class="ulContent">
                            <li>原价：<span>{{item.UnitAmount}}</span></li>
                            <li>现价：<span>{{item.FinalAmount}} </span></li>
                        </ul>
                        <ul class="ulContent">
                            <li>申请优惠价格：<input type="number" v-model="item.DiscountAmount"  @input="changeWords(item.DiscountAmount,item.FinalAmount,j)" ></li>
                            <li>实际支付价格：<span> {{(item.UnitAmount - item.DiscountAmount).toFixed(2)}}</span>  </li>
                        </ul>
                    </div> 
                </div>
            </div>
            <div class="orderTotal">
                <p>订单合计</p>
                <ul class="ulContent">
                    <li>原价：{{originalOrderPrice}} </li>
                    <li>现价：{{orderCurrentPrice}}</li>
                </ul>
                <ul class="ulContent">
                    <li>申请优惠：{{orderDiscounts}}</li>
                    <li>实际支付：{{orderPaymentPrice}}</li>
                </ul>
            </div>
            <div class="uploadFile">
                <p>上传凭证</p>
                <van-uploader v-model="fileList" :after-read="afterRead" />
            </div>
            <div class="submitOrderDetail">
                <button @click="submitOrder()">提交</button>
            </div>  
        </div>
        <div class="return" @click="OnClickReturn" v-if="returnData">返回</div>
    </div>
</template>
<script>
import Vue from "vue";
import router from "@/router/index.js";
import { Notify,Uploader} from "vant";
import {post} from '@/request/http'
import * as imageConversion from 'image-conversion'
import {POGetOrderDetails,PODiscountReview,POUploadImg}  from '@/request/api'
Vue.use(Uploader);
export default {
    name: "discountReview",
    data() {
        return {
            PhoneNumber: "",
            //订单详情展示
            orderdDetail:true,
            orderdDetailObject:{},
            originalOrderPrice: 0, //订单原价
            orderDiscounts: 0, //订单优惠价格
            orderCurrentPrice: 0, //订单现价
            orderPaymentPrice: 0, //订单付款价格
            returnData:true,
            fileList:[],
            POApproveID:""  
        };
    },
    components: {},
    
    mounted() {
        this.getDetail();
    },
    methods: {
        //  优惠输入框变化
        changeWords(amount, CurrentAmount, itemIndex) {
            amount = amount
                .replace(/[^\d.]/g, "") //只能输入数字
                .replace(/^()*(\d+)\.(\d\d).*$/, "$1$2.$3") //只能输入两个小数
                .replace(/\.{2,}/g, "."); //出现多个点时只保留第一个
            // 第一位不让输小数点
            if (amount == ".") {
                amount = "";
            }
            // 如果第一位是0，第二位必须大于0或者小数点
            if (amount.substring(0, 1) == 0) {
                if (amount.substring(1, 2) > 0) {
                amount = amount.substring(1, 2);
                } else if (
                amount.substring(1, 2) === 0 ||
                amount.substring(1, 2) === "0"
                ) {
                amount = "0";
                }
            } else {
                // 如果第一位数字大于0（不等于0肯定就大于0），仅需考虑第二位是小数点的情况
                if (amount.indexOf(".") !== -1) {
                if (amount.substring(0, 1) > 0) {
                    console.log("第一位大于0");
                } else {
                    console.log("第一位等于0");
                    if (amount.substring(2, 3) > 0) {
                    console.log("小数点后第一位大于0");
                    } else {
                    console.log("小数点后第一位等于0");
                    amount = "0.";
                    }
                }
                } else {
                console.log("没有小数点，正常输入");
                }
            }
            
            if (amount == "") {
                amount = "0";
            }
            if ((parseFloat(CurrentAmount)- (parseFloat(amount))).toFixed(2) < 0) {
                Notify({
                    type: "warning",
                    duration: 2000,
                    message: "优惠金额不得大于现价",
                });
                amount = "0";
            }
            this.orderdDetailObject.DataList[itemIndex].DiscountAmount = amount;
            this.getPrice();
        },
        //获取总价格
        getPrice() {
            var _this = this;
            _this.originalOrderPrice = 0;
            _this.orderDiscounts = 0;
            _this.orderCurrentPrice = 0;
            _this.orderPaymentPrice = 0;
            _this.orderdDetailObject.DataList.forEach((item) => {
                _this.originalOrderPrice = (parseFloat(_this.originalOrderPrice) + parseFloat(item.UnitAmount)).toFixed(2);
                _this.orderDiscounts =   (parseFloat(_this.orderDiscounts) + parseFloat(item.DiscountAmount)).toFixed(2); 
                _this.orderCurrentPrice = (parseFloat(_this.orderCurrentPrice) + parseFloat(item.FinalAmount)).toFixed(2);
                _this.orderPaymentPrice = (parseFloat(_this.orderPaymentPrice)+(parseFloat(item.UnitAmount) - parseFloat(item.DiscountAmount)) ) .toFixed(2);
            });
        },
        OnClickReturn() {
            router.go(-1); //返回上一层
        },
        getDetail(){
            if (this.$route.query.state=='t') {
                this.orderdDetail = true;
                this.getOrderdDetail();
            } else {
                this.orderdDetail = false;
                this.getOrderdDetail();
            }
        },
        getOrderdDetail(){
            post(POGetOrderDetails+'?POID='+this.$route.query.POID,{}).then(res=>{
                if (res.Code=='200') {
                    this.orderdDetailObject = res.Data;
                    this.POApproveID = res.Data.DataList[0].POApproveID;
                    this.getPrice();
                } else  if(res.Code == '0'){
                    Notify({type: "danger",duration: 2000, message: '无数据'});
                    router.go(-1);
                }else{
                    Notify({type: "danger",duration: 2000, message: res.msg});
                }
            },err=>{
                console.log(err) 
            }).catch() 
        },
        //折扣审批图片上传
        async afterRead(file){
            var that = this;
            let   fileSize  = file.file.size / 1024 ;
            if (fileSize > 600) {
                // 将图片压缩 参数可选 1-0.1   得到blob
                const blob = await imageConversion.compress(file.file, 0.5)
                // 将blob 转为  file
                const newFi =  new File([blob],file.file.name,{type:blob.type})
                const formdata = new FormData()
                formdata.append('file',newFi);
                formdata.append("LoginId",window.sessionStorage.getItem("token"));
                formdata.append("POApproveID",that.POApproveID);
                post(POUploadImg,formdata).then(res=>{
                    if (res[0].code=='200') {
                        Notify({type: "success",duration: 2000, message: "上传成功！"});
                    } else {
                        Notify({type: "danger",duration: 2000, message: res.msg});
                    }
                },err=>{
                    console.log(err)
                }).catch() 
            } else {
                const formdata = new FormData()
                formdata.append("file",file.file);
                formdata.append("LoginId",window.sessionStorage.getItem("token"));
                formdata.append("POApproveID",that.POApproveID);
                post(POUploadImg,formdata).then(res=>{
                    if (res[0].code=='200') {
                        Notify({type: "success",duration: 2000, message: "上传成功！"});
                    } else {
                        Notify({type: "danger",duration: 2000, message: res.msg});
                    }
                },err=>{
                    console.log(err)
                }).catch() 
            }
		},
        submitOrder(){
            if (this.fileList.length == 0) {
                Notify({type: "danger",duration: 2000, message: '上传凭证不得为空！'});
                return false;
            }
            var orderList = [];
            this.orderdDetailObject.DataList.forEach((item) => {
                let obj = {};
                obj.poid = item.POID;
                obj.pOApproveID  = item.POApproveID;
                obj.pOApproveSubID  = item.POApproveSubID;
                obj.unitamount  = parseFloat(item.UnitAmount);
                obj.finalamount  = parseFloat(item.FinalAmount);
                obj.discount  = parseFloat(item.DiscountAmount);
                obj.itemshijizhifuAmount   = parseFloat(item.FinalAmount -item.DiscountAmount);
                orderList.push(obj);
            });
            post(PODiscountReview+'?LoginId='+window.sessionStorage.getItem("token")+'&POApproveID='+this.POApproveID+'&paramorders='+JSON.stringify(orderList),{}).then(res=>{
                    if (res.Code == '1') {
                        Notify({type: "success",duration: 2000, message: res.Msg});
                        router.go(-1);
                    } else {
                        Notify({type: "warning",duration: 2000, message: res.Msg});
                    }
                },err=>{
                    console.log(err);
            }).catch() 
        }

  },
}; 
</script>
<style scoped>
.orderdDetailBox{
    height: 6.67rem;
    font-size: 0.13rem;
    overflow: scroll;
    background: #ffffff;
    padding: 0.1rem;
    display: flex;
    flex-direction: column;
}
.orderdDetailBox>>>p{
    height: 0.35rem;
    line-height: 0.35rem;
    font-size: 0.14rem;
    font-weight: bold;
}
.submitOrderDetail button{
    display: block;
    width: 1.5rem;
    height: 0.45rem;
    background: #004995;
    border-radius: 0.3rem;
    margin: 30px auto  100px auto;
    font-size: 0.16rem;
    color: #ffffff;
}


</style>