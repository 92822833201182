<template>
  <div></div>
</template>
<script>
import router from "@/router/index.js";
import baseUrl from '@/request/baseURL.js'
export default {
  name: null,
  data() {
    return {};
  },
  created() {
    var URL = window.location.href;
    var temp2 = URL.split("?")[1];
    var pram2 = new URLSearchParams("?" + temp2);
    var optionsData = pram2.get("loginid");
    console.log(URL, "当前页面URL");
    console.log(optionsData, "URL的loginid值");
    var _this = this;
    _this.axios
      .get(baseUrl+"api/Login/LoginByID", {
        params: {
          loginid: optionsData,
        },
      })
      .then(function (res) {
        console.log(res);
        const LoginId = res.data.LoginId;
        console.log(LoginId, "LoginId");
        sessionStorage.setItem("token", res.data.LoginId);
        // 跳转
        router.push({
          path: "/enterIndex",
          query: { JobRoleID: res.data.JobRoleID },
        });
      })
      .catch(function (err) {
        console.log(err);
      });
  },
  methods: {},
};
</script>
<style>
</style>