<template>
  <div class="container-approval">
    <div class="return" @click="OnClickReturn" v-if="returnData">返回</div>
    <div class="nav">
      <span class="spantext">电话</span>
      <van-search
        v-model="PhoneNumber"
        ref="PhoneNumber"
        left-icon="none"
        right-icon="none"
        background="#EBEBEB"
        input-align="left"
        maxlength="13"
        @search="onSearch"
      />
      <button class="searchBox" @click="onSearch">搜索</button>
    </div>

    <div class="form" v-for="(item, index) in discountList" :key="index">
      <div class="list">
        <div class="listLeft">申请人</div>
        <div class="listRight">{{ item.ApplyUserName }}</div>
      </div>
      <div class="list">
        <div class="listLeft">申请日期</div>
        <div class="listRight">
          {{item.ApplyDate | timeDate}}
        </div>
      </div>
      <div class="list">
        <div class="listLeft">学员姓名</div>
        <div class="listRight">{{ item.RealName }}</div>
      </div>
      <div class="list">
        <div class="listLeft">订单号</div>
        <div class="listRight">{{ item.PONO }}</div>
      </div>
      <div class="list">
        <div class="listLeft">原价</div>
        <div class="listRight">¥{{ item.UnitAmount }}</div>
      </div>
      <div class="list">
        <div class="listLeft">申请优惠价格</div>
        <div class="listRight">¥{{ item.ApplyDiscountAmount }}</div>
      </div>
      <div class="list">
        <div class="listLeft">现价</div>
        <div class="listRight">¥{{item.FinalAmount}}</div>
      </div>
      <div class="list">
        <div class="listLeft">审批人</div>
        <div class="listRight">{{ item.ApproveUserName }}</div>
      </div>
      <div class="list">
        <div class="listLeft">审批时间</div>
        <div class="listRight">
          {{item.ApproveDate | timeDate}}
        </div>
      </div>
      <div class="list">
        <div class="listLeft">直播返现</div>
        <div class="listRight">
          {{ item.IsLiveStreamingCashBack == true ? "是" : "否"}}
        </div>
      </div>
      <div class="list">
        <div class="listLeft">模考返现</div>
        <div class="listRight">
          {{ item.IsMockUpExamRecurrence == true ? "是" : "否"}}
        </div>
      </div>
      <div class="list">
        <div class="listLeft">审批结果</div>
        <div class="listRight">
          {{ item.Status == 1 ? "" : (item.Status == 3 ? "通过" : "不通过") }}
        </div>
      </div>
      <div class="buttonBox">
        <button class="bottomButton" @click="seeDetail(item)">
          {{ item.Buttontext }}
        </button>
      </div>
    </div>

    <van-overlay :show="show" @click="overlayShow" />

    <!-- 审核提交 -->
    <div v-if="formdata">
      <div class="approval-form2">
        <div>
          <div class="list">
            <div class="listLeft">学员姓名</div>
            <div class="listRight">{{ studentInformation.RealName }}</div>
          </div>
          <div class="list">
            <div class="listLeft">学员手机号</div>
            <div class="listRight">{{ studentInformation.Tel }}</div>
          </div>
          <div class="list">
            <div class="listLeft">订单号</div>
            <div class="listRight">{{ studentInformation.PONO }}</div>
          </div>
        </div>
        <div class="more" v-for="(item, index) in classDetail" :key="index">
          <div class="list" style="height:0.64rem;">
            <div class="listLeft" style="width: 0.85rem;">购买产品</div>
            <div class="listRight1">
              {{ item.ItemName == null ? null : item.ItemName }}
            </div>
          </div>
          <div class="list">
            <div class="listLeft">原价</div>
            <div class="listRight">
              ¥{{ item.UnitAmount == null ? null : item.UnitAmount }}
            </div>
          </div>
          <div class="list">
            <div class="listLeft">现价</div>
            <div class="listRight">
              ¥{{ item.FinalAmount == null ? null : item.FinalAmount }}
            </div>
          </div>
          <div class="list">
            <div class="listLeft">申请特殊折扣金额</div>
            <div class="listRight">
              ¥{{
                item.ApplyDiscountAmount == null
                  ? null
                  : item.ApplyDiscountAmount
              }}
            </div>
          </div>
        </div>
        <div class="more2">
          <div class="list">
            <div class="listLeft">上传凭证</div>
            <div class="listRight imgbox">
              <div v-for="(item, index) in Attachment" :key="index">
                <viewer>
                   <img :src="(item.AttachmentURL.indexOf('http') != -1 ) == true  ? item.AttachmentURL : ('https://newims.phoneapi.lekaowang.cn'+item.AttachmentURL) " />
                </viewer>
              </div>
            </div>
          </div>
          <div class="list">
            <div class="listLeft">优惠金额</div>
            <div class="listRight">已优惠{{ discountAmount }}元</div>
          </div>
          <div class="list">
            <div class="listLeft">实际支付金额</div>
            <div class="listRight">¥{{ OverAmount.toFixed(2) }}</div>
          </div>
          <van-field
            name="radio"
            class="numBox"
            label="审核结果"
            input-align="right"
          >
            <template #input>
              <van-radio-group v-model="reviewStatus" direction="horizontal">
                <van-radio name="3" radio="3">同意</van-radio>
                <van-radio name="2" radio="2">拒绝</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-field
            class="numBox"
            label="直播返现"
            input-align="right"
          >
            <template #input>
              <van-radio-group v-model="liveBack" direction="horizontal">
                <van-radio name="1" radio="1">是</van-radio>
                <van-radio name="0" radio="0">否</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-field
            class="numBox"
            label="模考返现"
            input-align="right"
          >
            <template #input>
              <van-radio-group v-model="testBack" direction="horizontal">
                <van-radio name="1" radio="1">是</van-radio>
                <van-radio name="0" radio="0">否</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-field
            class="numBox"
            v-model="remark"
            label="审核备注"
            input-align="right"
          />
          <button class="bottomButton" @click="submitAuditing()">确认</button>
        </div>
      </div>
    </div>

    <!-- 审核查看 -->
    <div class="tankuangBox2" v-if="ReviewView">
      <div>
        <div class="list">
          <div class="listLeft">学员姓名</div>
          <div class="listRight">{{ studentInformation.RealName }}</div>
        </div>
        <div class="list">
          <div class="listLeft">学员手机号</div>
          <div class="listRight">{{ studentInformation.Tel  }}</div>
        </div>
        <div class="list">
          <div class="listLeft">订单号</div>
          <div class="listRight">{{ studentInformation.PONO }}</div>
        </div>
      </div>
      <div class="more" v-for="(item, index) in classDetail" :key="index">
        <div class="list" style="height:0.64rem">
          <div class="listLeft"  style="width: 0.85rem;">购买产品</div>
          <div class="listRight1">
            {{ item.ItemName == null ? null : item.ItemName }}
          </div>
        </div>
        <div class="list">
          <div class="listLeft">原价</div>
          <div class="listRight">
            ¥{{ item.UnitAmount == null ? null : item.UnitAmount }}
          </div>
        </div>
        <div class="list">
          <div class="listLeft">现价</div>
          <div class="listRight">
            ¥{{ item.FinalAmount == null ? null : item.FinalAmount }}
          </div>
        </div>
        <div class="list">
          <div class="listLeft">申请特殊折扣金额</div>
          <div class="listRight">
            ¥{{
              item.ApplyDiscountAmount == null
                ? null
                : item.ApplyDiscountAmount
            }}
          </div>
        </div>
      </div>
      <div>
        <div class="list">
          <div class="listLeft">上传凭证</div>
          <div class="listRight imgbox">
            <div v-for="(item, index) in Attachment" :key="index">
              <viewer>
                <img :src="(item.AttachmentURL.indexOf('http') != -1 ) == true  ? item.AttachmentURL : ('https://newims.phoneapi.lekaowang.cn'+item.AttachmentURL) " />
              </viewer>
            </div>
          </div>
        </div>
        <div class="list">
          <div class="listLeft">直播返现</div>
          <div class="listRight">{{studentInformation.IsLiveStreamingCashBack == true ? '是' :'否'  }}</div>
        </div>
        <div class="list">
          <div class="listLeft">模考返现</div>
          <div class="listRight">{{studentInformation.IsMockUpExamRecurrence  == true ? '是' :'否'  }}</div>
        </div>
       <div class="list">
          <div class="listLeft">优惠金额</div>
          <div class="listRight">已优惠{{ discountAmount }}元</div>
        </div>
        <div class="list">
          <div class="listLeft">实际支付金额</div>
          <div class="listRight">¥{{ OverAmount.toFixed(2) }}</div>
        </div>
        <div class="list">
          <div class="listLeft">审核结果</div>
          <div class="listRight">
            {{
              studentInformation.Status == 1
                ? "待审批"
                : (studentInformation.Status == 3
                ? "通过"
                : "拒绝")
            }}
          </div>
        </div>
        <div class="list">
          <div class="listLeft">审核备注</div>
          <div class="listRight">{{ studentInformation.ApproveRemark }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import router from "@/router/index.js";
import {transformTimestamp} from '@/common/common';
import baseUrl from '@/request/baseURL.js'
import {get,post} from '@/request/http'
import {GetDiscountReviewList,LookDiscountReview,CheckApproveNew}  from '@/request/api'
import { Notify } from "vant";
import { Toast } from "vant";
export default {
  name: "approval",
  data() {
    return {
      Buttontext: "审核",
      PhoneNumber: "",
      remark: "",
      reviewStatus: "",
      formdata: false,
      //遮罩层
      show: false,
      //审核查看状态
      ReviewView: false,
      orderBoxData: false,
      //返回按钮显示状态
      returnData: true,
      // 审核列表
      discountList: [],
      //学员信息
      studentInformation: {},
      // 课程信息
      classDetail: [],
      //附件信息
      Attachment:[],
      //优惠金额
      discountAmount:0,
      //实际支付金额
      OverAmount:0,
      //直播返现
      liveBack:"0",
      //模考返现
      testBack:"0",
    };
  },
  filters:{
    timeDate(time){
        return transformTimestamp(time);
    }
  },
  mounted() {
    this.onSearch();
  },
  methods: {
    onSearch() {
      var _this = this;
      get(GetDiscountReviewList+'?Tel='+_this.PhoneNumber)
        .then(function (res) {
          if (res.Code == "200") {
            res.Data.forEach(item=>{
              if (item.Status != 1) {
                item.Buttontext = "查看";
              } else {
                item.Buttontext = "审核";
              }
            })
            _this.discountList = res.Data;
          }else{
            Toast(res.Msg);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //查看详情
    seeDetail(item) {
      this.returnData = true;
      var _this = this;
      _this.studentInformation  = {};
      _this.classDetail    = [];
      _this.Attachment = [];
      _this.discountAmount = 0;
      _this.OverAmount = 0;
      if (item.Buttontext == "审核") {
        _this.formdata = true;
        _this.show = true;
        _this.radio = "";
        _this.remark = "";
        get(LookDiscountReview+'?POApproveID='+item.POApproveID)
        .then(function (res) {
          if (res.Code == "200") {
            _this.studentInformation  = res.Data.View_LK_PO_Approve[0];
            _this.classDetail    = res.Data.View_LK_PO_ApproveItem;
            _this.Attachment = res.Data.Attachment;
            _this.discountAmount = res.Data.Applyyouhuiamount;
            _this.OverAmount = res.Data.OverAmount;

          }else{
            Toast(res.Msg);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      } else {
        _this.ReviewView = true;
        _this.show = true;
        _this.radio = "";
        _this.remark = "";
        get(LookDiscountReview+'?POApproveID='+item.POApproveID)
        .then(function (res) {
          if (res.Code == "200") {
            _this.studentInformation  = res.Data.View_LK_PO_Approve[0];
            _this.classDetail    = res.Data.View_LK_PO_ApproveItem;
            _this.Attachment = res.Data.Attachment;
            _this.discountAmount = res.Data.Applyyouhuiamount;
            _this.OverAmount = res.Data.OverAmount;

          }else{
            Toast(res.Msg);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      }
    },
    submitAuditing() {
      var _this = this;
      if (_this.remark.trim() == "") {
        Notify({
            type: "warning",
            duration: 2000,
            message: "审核备注不能为空！",
          });
          return  false;
      } 
      _this.formdata = false;
      _this.show = false;
      _this.returnData = true;
      var POApproveID =   _this.studentInformation.POApproveID;
      post(CheckApproveNew+'?LoginId='+sessionStorage.getItem("token")+'&POApproveID='+POApproveID+'&Status='+_this.reviewStatus+'&Remark='+_this.remark+'&LiveStreamingCashBack='+_this.liveBack+'&MockUpExamRecurrence='+_this.testBack)
      .then(function (res) {
        Notify({
          type: "warning",
          duration: 2000,
          message: res.Messages,
        });
        _this.onSearch();
      })
      .catch(function (err) {
        console.log(err);
      });
    },
    overlayShow() {
      this.formdata = false;
      this.show = false;
      this.ReviewView = false;
      this.orderBoxData = false;
      this.returnData = true;
    },
    toUploader() {},
    OnClickReturn() {
      router.go(-1); //返回上一层
    },
  },
};
</script>
<style scoped>
.listRight1{
    font-size: 0.15rem;
    font-family: PingFang SC;
    font-weight: 400;
    color: #555555;
    text-align: right;
}
</style>
<style>
* {
  padding: 0;
  margin: 0;
}
.container-approval {
  width: 100%;
  height: 100%;
  border: 1px solid #c9c9c9;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  background: #ebebeb;
}
.container-approval .nav {
  width: 3.55rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 0;
}
.container-approval .spantext {
  width: 0.4rem;
  font-size: 0.14rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
}
.container-approval .searchBox {
  width: 0.5rem;
  height: 0.3rem;
  background: #004995;
  border-radius: 0.04rem;
  border: 0px solid;
  line-height: 0.3rem;
  font-size: 0.13rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  margin-right: 0.03rem;
}
.van-search__content {
  width: 1.4rem;
}
.van-field__control {
  height: 0.3rem;
  line-height: 0.3rem;
  font-size: 0.14rem;
}

.pushBox {
  width: 0.7rem;
  height: 0.3rem;
  background: #004995;
  border-radius: 0.04rem;
  border: 0px solid;

  line-height: 0.3rem;
  font-size: 0.13rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
}
.container-approval .form {
  width: 3.55rem;
  height: 5.02rem;
  background: #ffffff;
  border-radius: 0.05rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0.17rem;
  padding-bottom: 0.15rem;
  margin-top: 0.2rem;
}
.container-approval .form .list {
  width: 3.15rem;
  height: 0.32rem;
  border-bottom: 1px solid #ebebeb;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 0.1rem;
}
.list .listLeft {
  height: 0.21rem;
  font-size: 0.15rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
}
.list .listRight {
  height: 0.21rem;
  font-size: 0.15rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #555555;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bottomButton {
  width: 1.5rem;
  height: 0.5rem;
  background: #004995;
  border-radius: 0.05rem;

  font-size: 0.17rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
}

.approval-form2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  width: 3.35rem;
  height: 5.95rem;
  background: #ffffff;
  border-radius: 0.05rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0.05rem;
  z-index: 999;
}
.approval-form2 .list {
  width: 2.95rem;
  height: 0.32rem;
  border-bottom: 1px solid #ebebeb;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 0.1rem;
}

.approval-form2 .bottomButton {
  width: 3.15rem;
  height: 0.5rem;
  background: #004995;
  border-radius: 0.05rem;
  border: 0px solid;

  font-size: 0.17rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  margin-top: 0.1rem;
}
.van-field {
  padding: 0;
}
.van-field__label {
  font-size: 0.15rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
}
.approval-form2 .numBox {
  width: 2.95rem;
  height: 0.32rem;
  border-bottom: 1px solid #ebebeb;
  padding: 0px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 0.1rem;
}
.more2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tankuangBox2 {
  width: 3.35rem;
  height: 5.17rem;
  overflow: scroll;
  background: #ffffff;
  border-radius: 5px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-top: 0.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 999;
}
.tankuangBox2 .list {
  width: 2.95rem;
  height: 0.32rem;
  border-bottom: 1px solid #ebebeb;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 0.1rem;
}
.tankuangBox2 .list .listLeft {
  font-size: 0.15rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
}
.tankuangBox2 .list .listRight {
  width: 1.55rem;
  font-size: 0.15rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #555555;
  text-align: right;
}
.list .imgbox {
  height: 0.55rem;
  display: flex;
  flex-direction: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: right;
}
.list .imgbox img {
  width: 0.35rem;
  height: 0.35rem;
  border: 1px solid #004995;
  border-radius: 0.05rem;
  margin-left: 0.05rem;
}
.return {
  font-size: 0.15rem;
  color: #fff;
  position: fixed;
  right: 16px;
  bottom: 88px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  z-index: 15;
  overflow: hidden;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  background-color: #004995;
}
.orderBox {
  width: 3.35rem;
  height: 5.17rem;
  border: 1px solid rgb(92, 89, 89);
  font-size: 0.13rem;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: scroll;
  background: #ffffff;
  border-radius: 0.05rem;
  padding: 0.1rem;
  display: flex;
  flex-direction: column;
  z-index: 999;
}
.orderBox .Tip {
  color: rgb(228, 89, 89);
  font-size: 0.11rem;
}
.radioBox {
  width: 1.8rem;
  height: 0.2rem;
  margin-top: 0.1rem;
  font-size: 0.13rem;
}
.formBox {
  width: 3.3rem;
  height: 100%;
  overflow: scroll;
  margin: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.2rem;
}
.formBox .list {
  width: 3.2rem;
  height: 0.22rem;
  border-bottom: 1px solid #ebebeb;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  line-height: 0.22rem;
  margin-bottom: 0.1rem;
  font-size: 0.13rem;
}
.formBox .options {
  width: 3.2rem;
  height: 1.7rem;
  border-bottom: 1px solid #ebebeb;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.1rem;
  font-size: 0.13rem;
}
.selectListBox {
  display: flex;
  flex-direction: column;
  width: 2.2rem;
  height: 1.5rem;
}
.selectListBox .selectList {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 2.2rem;
  height: 0.3rem;
}
.pushButton {
  box-sizing: border-box;
  margin-bottom: 0.2rem;
  margin-top: 0.1rem;
  width: 1.2rem;
  height: 0.6rem;
  text-align: center;
  color: #ffffff;
}
.selectListBox .imgBox {
  width: 0.25rem;
  height: 0.25rem;
  background: #d7eaff;
  margin-top: 0.02rem;
}

.formBox2 {
  width: 3.3rem;
  height: 100%;
  overflow: scroll;
  margin: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.2rem;
}
.formBox2 .list {
  width: 3.2rem;
  height: 0.32rem;
  border-bottom: 1px solid #ebebeb;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 0.1rem;
  font-size: 0.13rem;
}
.formBox2 button {
  margin-bottom: 0.2rem;
  margin-top: 0.1rem;
  width: 1.2rem;
  height: 0.4rem;
  text-align: center;
  color: #ffffff;
}
.formBox2 .list .numInput {
  width: 1.5rem;
  height: 0.3rem;
  color: #333333;
  font-size: 0.13rem;
  margin-bottom: 0.08rem;
}
.payBox {
  width: 3.3rem;
  height: 4rem;
  overflow: scroll;
  margin: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: scroll;
  background: #ffffff;
  border-radius: 0.05rem;
  padding: 0.1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 999;
}
.payBox .list {
  width: 2.8rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 0.11rem;
  margin-bottom: 0.1rem;
}
.payBox .list .text {
  width: 1.6rem;
  height: 0.4rem;
  border: 1px solid #333333;
  word-wrap: break-word;
  font-size: 0.12rem;
}
.payBox .list button {
  width: 0.6rem;
  height: 0.3rem;
  font-size: 0.12rem;
  color: #ffffff;
}
.payBox .button {
  width: 0.8rem;
  height: 0.35rem;
  font-size: 0.14rem;
  color: #ffffff;
}
.payBox img {
  width: 2.5rem;
  height: 2.5rem;
  border: 1px solid #333333;
  margin-top: 0.5rem;
}
#input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -10;
}
</style>
