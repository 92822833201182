import Vue from 'vue'
import App from './App.vue'

import router from "./router"
// import store from "./store/index"
import Vant from 'vant';
import 'vant/lib/index.css';

import './rem';

// import VConsole from 'vconsole';

import apiUrl from './components/Global.vue'
Vue.prototype.apiUrl = apiUrl.apiUrl;

Vue.config.productionTip = false;

import axios from 'axios'
import VueAxios from "vue-axios";
Vue.prototype.$axios = axios;
Vue.config.productionTip = false;

Vue.use(VueAxios, axios);

import { Form, Button } from 'vant';
import { Field } from 'vant';
import { Checkbox, CheckboxGroup } from 'vant';
import { Overlay } from 'vant';
import { Uploader } from 'vant';
import { Toast } from 'vant';
import { RadioGroup, Radio } from 'vant';
import { DropdownMenu, DropdownItem } from 'vant';
import { Tab, Tabs } from 'vant';
import { Empty } from 'vant';
import { Swipe, SwipeItem } from 'vant';
import { Loading } from 'vant';

import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer)
Viewer.setDefaults({
    Options: { 'inline': true, 'button': true, 'navbar': true, 'title': true, 'toolbar': true, 'tooltip': true, 'movable': true, 'zoomable': true, 'rotatable': true, 'scalable': true, 'transition': true, 'fullscreen': true, 'keyboard': true, 'url': 'data-source' }
})

Vue.use(Vant);
Vue.use(Form);
Vue.use(Uploader);
Vue.use(Overlay);
Vue.use(Field);
Vue.use(Checkbox);
Vue.use(Button);
Vue.use(CheckboxGroup);
Vue.use(Toast);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Empty);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Loading);


new Vue({
    router,
    // store,
    render: h => h(App),

}).$mount('#app')

// 如果需要在特定条件下才初始化vConsole，可以添加逻辑判断
// if (process.env.NODE_ENV === 'development') {
//   const vConsole = new VConsole();
// }

// 添加请求拦截器，在请求头中加token
// axios.interceptors.request.use(config => {
//   if (localStorage.getItem('Authorization')) {
//     config.headers.Authorization = localStorage.getItem('Authorization');
//   }
//   return config;
// },
// error => {
//   return Promise.reject(error);
// });