<template>
  <div class="auditManagement">
    <div class="return" @click="OnClickReturn">返回</div>
    <div class="vanTabs" v-if="loginID =='DB56CA68-918F-4D83-8A8C-68B6DD3D13D3'|| loginID=='C72C8527-000E-4AFF-A865-C3A65CEB4BBC' || loginID=='F397D0D6-698D-44CC-B073-67948D79F0C9' ">
        <van-tabs v-model="activeName" title-active-color="#004995" title-inactive-color="#000000" background="#ffffff" color="#004995" @click="getTabName" >
            <van-tab title="申请重学" name="relearn">
                <relearn style="margin-top:0.1rem;"></relearn>
            </van-tab>
            <van-tab title="折扣审核" name="approval">
                <approval style="margin-top:0.1rem;"></approval>
            </van-tab>
            <!-- <van-tab title="赠送审核" name="gift">
                <gift style="margin-top:0.1rem;"></gift>
            </van-tab> -->
            <van-tab title="升级更换" name="upgrade">
                <upgrade style="margin-top:0.1rem;"></upgrade>
            </van-tab>
        </van-tabs>
    </div>
    <div class="vanTabs" v-else>
        <van-tabs v-model="activeName" title-active-color="#004995" title-inactive-color="#000000" background="#ffffff" color="#004995"  @click="getTabName" >
            <van-tab title="申请重学" name="relearn">
                <relearn style="margin-top:0.1rem;"></relearn>
            </van-tab>
            <van-tab title="升级更换" name="upgrade">
                <upgrade style="margin-top:0.1rem;"></upgrade>
            </van-tab>
        </van-tabs>
    </div>
  </div>
</template>

<script>
import router from "@/router/index.js";
import approval from './approval.vue';
import Relearn from './relearn.vue';
// import Gift from './gift.vue';
import Upgrade from './upgrade.vue';
// import { Notify } from "vant";
export default {
  components: { approval, Relearn, Upgrade },
//   components: { approval, Relearn, Gift, Upgrade },
    data() {
        return {
            returnData: true,
            activeName: 'relearn',
            JobRoleID:"",
            loginID:""
        };
    },
    created() {
        this.JobRoleID = this.$route.query.JobRoleID;
        this.loginID  =  sessionStorage.getItem("token").toUpperCase();
    },
    mounted(){
        if (localStorage.getItem("tabName")!=""&&localStorage.getItem("tabName")!=null&&localStorage.getItem("tabName")!=undefined) {
            this.activeName = localStorage.getItem("tabName")
        }
    },
    methods: {
        //返回上一层
        OnClickReturn() {
            router.go(-1);
        },
        getTabName(name){ 
            localStorage.setItem('tabName',name);
        }
    },
    beforeDestroy(){
        localStorage.setItem('tabName',this.activeName);
    }
};
</script>

<style>
.auditManagement {
  width: 100%;
  height: 100%;
}
.auditManagement .vanTabs {
  width: 100%;
  height: 100%;
}




</style>
