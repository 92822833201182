<template>
  <div class="container-pushOrder">
    <div class="return" @click="OnClickReturn" v-if="returnData">返回</div>
    <div class="nav">
      <span class="spantext">电话</span>
      <van-search
        v-model="PhoneNumber"
        ref="PhoneNumber"
        left-icon="none"
        right-icon="none"
        background="#EBEBEB"
        input-align="left"
        maxlength="13"
      />
      <button class="pushBox" @click="onPush">创建订单</button>
    </div>
    <van-overlay :show="show" @click="overlayShow" />
    <!-- 创建订单弹框 -->
    <div class="orderBox orderBoxHeight" v-if="orderBoxData">
      <div class="Tip">
        <span
          >注：若学员选择微信支付请将链接发送到学员微信号上。若学员选择支付宝支付请复制链接从浏览器上打开</span
        >
      </div>
      <div class="radioBox" v-if="radioBoxData">
        <van-radio-group
          v-model="radio2"
          shape="round"
          direction="horizontal"
          checked-color="#004995"
          icon-size="16px"
          @change="radioClcik"
        >
          <van-radio name="1">支付订单</van-radio>
          <van-radio name="2">充值订单</van-radio>
        </van-radio-group>
      </div>
      <!-- 支付订单 -->
      <div class="formBox" v-if="fromBoxPush" style="position: relative;">
        <ul class="ulContent">
          <li>
            <span>姓名：</span>
            <span>{{ NAME }}</span>
          </li>
          <li>
            <span>购买手机号：</span>
            <span>{{ PHONE }}</span>
          </li>
        </ul>
        <!-- <div class="list">
          <span>姓名</span>
          <span>{{ NAME }}</span>
        </div>
        <div class="list">
          <span>购买手机号</span>
          <span>{{ PHONE }}</span>
        </div> -->

        <div id="PUSH" style="width: 100%;height: 2.9rem;overflow-y: scroll;" >
          <p>购买产品</p>
          <!-- <div v-for="(item, index) in DPUSH" :key="index"> -->
          <div>
            <ul  class="ulContent">
              <li>
                <span>分类：</span>
                <span>
                  <select
                    class="selectList"
                    name="select1"
                    id="selectId1"
                    v-model="categorySelectId"
                    @change="selectListFun1()"
                  >
                    <option disabled value="">请选择</option>
                    <option
                      :value="item1.CategoryId"
                      v-for="(item1, index1) in categoryArr"
                      :key="index1"
                    >
                      {{ item1.CategoryName }}
                    </option>
                  </select>
                </span>
              </li>
              <li>
                <span>科目：</span>
                <span>
                  <select
                    class="selectList"
                    name="select2"
                    id="selectId2"
                    v-model="subjectSelectId"
                    @change.stop="selectListFun2()"
                  >
                    <option
                      :value="item2.SubjectId"
                      v-for="(item2, index2) in subjectArr"
                      :key="index2"
                    >
                      {{ item2.SubjectName }}
                    </option>
                  </select>
                </span>
              </li>
            </ul>
            <ul  class="ulContent">
              <li>
                <span>级别：</span>
                <span>
                  <select
                      class="selectList"
                      name="select3"
                      id="selectId3"
                      v-model="levelSelectId"
                      @change.stop="selectListFun3()"
                    >
                      <option
                        :value="item3.SubjectTypeId"
                        v-for="(item3, index3) in levelArr"
                        :key="index3"
                      >
                        {{ item3.SubjectType }}
                      </option>
                    </select>
                </span>
              </li>
              <li>
                <span>课程：</span>
                <span>
                  <select
                    class="selectList"
                    name="select4"
                    id="selectId4"
                    v-model="classSelectId"
                    @change.stop="selectListFun4()"
                  >
                    <option
                      :value="item4.CourseId"
                      v-for="(item4, index4) in classArr"
                      :key="index4"
                    >
                      {{ item4.CourseName }}
                    </option>
                  </select>
                </span>
              </li>
            </ul>
            <van-radio-group
              v-model="payID"
              shape="round"
              direction="horizontal"
              checked-color="#004995"
              icon-size="16px"
              @change="selectPay()"
            >
              <van-radio :name="item.PaymentID"  v-for="(item,index) in  payForList"   :key="index"   :disabled="item.disabled"  >{{item.PaymentTitle}}</van-radio>
            </van-radio-group>


            <ul  class="ulContent" v-show="payForList.length > 0 ">
              <li>
                <span>产品：</span>
                <span>
                    <select
                      class="selectList"
                      name="select5"
                      id="selectId5"
                      v-model="productSelectId"
                      @change.stop="selectListFun5()"
                    >
                      <option
                        :value="item5.ProductId"
                        v-for="(item5, index5) in productArr"
                        :key="index5"
                      >
                        {{ item5.ProductName }}
                      </option>
                    </select>
                </span>
              </li>
              <li style="text-align: center;">
                <span style="color:#1989fa;" @click="addProductList">添加</span>
              </li>
            </ul>
            <!-- <ul  class="ulContent">
              <li>
                <span>分类：</span>
                <span>
                  <select
                    class="selectList"
                    name="select1"
                    id="selectId1"
                    v-model="item.value1"
                    @change="selectListFun1($event, index)"
                  >
                    <option disabled value="">请选择</option>
                    <option
                      :value="index1"
                      v-for="(item1, index1) in item.Array1"
                      :key="index1"
                    >
                      {{ item1.CategoryName }}
                    </option>
                  </select>
                </span>
              </li>
              <li>
                <span>科目：</span>
                <span>
                  <select
                    class="selectList"
                    name="select2"
                    id="selectId2"
                    v-model="item.value2"
                    @change.stop="selectListFun2($event, index)"
                  >
                    <option
                      :value="index2"
                      v-for="(item2, index2) in item.Array2"
                      :key="index2"
                    >
                      {{ item2.SubjectName }}
                    </option>
                  </select>
                </span>
              </li>
            </ul>
            <ul  class="ulContent">
              <li>
                <span>级别：</span>
                <span>
                  <select
                      class="selectList"
                      name="select3"
                      id="selectId3"
                      v-model="item.value3"
                      @change.stop="selectListFun3($event, index)"
                    >
                      <option
                        :value="index3"
                        v-for="(item3, index3) in item.Array3"
                        :key="index3"
                      >
                        {{ item3.SubjectType }}
                      </option>
                    </select>
                </span>
              </li>
              <li>
                <span>课程：</span>
                <span>
                  <select
                    class="selectList"
                    name="select4"
                    id="selectId4"
                    v-model="item.value4"
                    @change.stop="selectListFun4($event, index)"
                  >
                    <option
                      :value="index4"
                      v-for="(item4, index4) in item.Array4"
                      :key="index4"
                    >
                      {{ item4.CourseName }}
                    </option>
                  </select>
                </span>
              </li>
            </ul>
            <ul  class="ulContent">
              <li>
                <span>产品：</span>
                <span>
                    <select
                      class="selectList"
                      name="select5"
                      id="selectId5"
                      v-model="item.value5"
                      @change.stop="selectListFun5($event, index)"
                    >
                      <option
                        :value="index5"
                        v-for="(item5, index5) in item.Array5"
                        :key="index5"
                      >
                        {{ item5.ProductName }}
                      </option>
                    </select>
                </span>
              </li>
              <li style="text-align: center;">
                <span style="color:#1989fa;" @click="addProductList">添加</span>
              </li>
            </ul> -->
          </div>
          <div class="productContent" v-for="(item,j) in productList" :key="'product'+j"> 
            <div class="productTwo" v-if="item.productItemList.length > 1" >
              <p  style="width:100%;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;">产品名称：{{ProductName}}</p>
              <div v-for="(i,p) in item.productItemList" :key="p">
                <ul class="ulContent">
                  <li style="width:100%;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;">产品子名称：<span>{{i.ItemName}}</span></li>
                </ul>
                <ul class="ulContent">
                  <li>原价：<span>{{i.OriginalAmount}}</span> 现价：<span>{{i.CurrentAmount}} </span></li>
                </ul>
                <ul class="ulContent">
                  <li>申请优惠价格：<input type="text"  v-model="i.DiscountAmount"   style="width:0.5rem;"    @input="changeWords(i.DiscountAmount,i.CurrentAmount,p,j)"  > </li>
                  <li>实际支付价格：<span>{{(i.CurrentAmount - i.DiscountAmount).toFixed(2)}}</span>   </li>
                </ul>
                <p style="text-align: right;width: 95%;line-height: 0.3rem;">
                  <span style="color:#1989fa;"  @click="deleteProduct(item,i.ProductItemId,j)">删除</span>
                </p>
              </div>
            </div>
            <div class="productOne" v-else-if="item.productItemList.length = 1 " >
              <ul class="ulContent">
                <li style="width:100%;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;">产品名称：<span>{{item.productItemList[0].ItemName}}</span></li>
              </ul>
              <ul class="ulContent">
                <li>原价：<span>{{item.productItemList[0].OriginalAmount}}</span></li>
                <li>现价：<span>{{item.productItemList[0].CurrentAmount}} </span></li>
              </ul>
              <ul class="ulContent">
                <li>申请优惠价格：<input type="text" v-model="item.productItemList[0].DiscountAmount"  style="width:0.5rem;"  @input="changeWords(item.productItemList[0].DiscountAmount,item.productItemList[0].CurrentAmount,0,j)" ></li>
                <li>实际支付价格：<span>{{(item.productItemList[0].CurrentAmount - item.productItemList[0].DiscountAmount).toFixed(2)}}</span>  </li>
              </ul>
              <p style="text-align: right;width: 95%;line-height: 0.3rem;" >
                <span style="color:#1989fa;" @click="deleteProduct(item,item.productItemList[0].ProductItemId,j)">
                  删除
                </span>
              </p>
            </div> 
          </div>
        </div>
        <div class="orderCombination">
            <div class="orderTotal">
              <p>订单合计</p>
              <ul class="ulContent">
                <li>原价：{{originalOrderPrice}} </li>
                <li>现价：{{orderCurrentPrice}}</li>
              </ul>
              <ul class="ulContent">
                <li>申请优惠：{{orderDiscounts}}</li>
                <li>实际支付：{{orderPaymentPrice}}</li>
              </ul>
            </div>
            <!-- <button class="pushButton" @click="openPayBox">生成订单</button> -->
            <button class="pushOrderButton" @click="discountOrders"  :disabled="orderShow">生成订单</button>
        </div>
      </div>
      <!-- 充值订单 -->
      <div class="formBox2" v-if="fromBoxPay">
        <div class="list">
          <span>电话</span>
          <span>{{ PHONE }}</span>
        </div>
        <div class="list">
          <span>金额</span>
          <input
            class="numInput"
            type="number"
            placeholder="请输入金额"
            v-model="numValue"
          />
        </div>
        <button @click="payOrderFun">生成订单</button>
      </div>
    </div>

    <!-- 新 -->
    <div class="payBox" v-if="payBoxData">
      <div class="topBox">
        <div class="list">
          <div class="listLeft">注册账号：</div>
          <div class="listRight">{{ this.PhoneNumber }}</div>
        </div>
        <div class="list">
          <div class="listLeft">订单号：</div>
          <div class="listRight">{{ this.newPono }}</div>
        </div>
        <div class="list list2">
          <div class="listLeft">购买产品名称：</div>
          <div class="columnBox">
            <div class="listRight" v-for="(item, index) in orderProductList" :key="index">
              {{ item.ItemName }}
            </div>
          </div>
        </div>
        <div class="list">
          <div class="listLeft">实收金额：</div>
          <div class="listRight" style="color: #ff9133; font-weight: bold">
            {{ NUMtodayNUM }}元
          </div>
        </div>
      </div>
      <div class="bottomBox">
        <div class="list">
          <div class="listLeft">注册账号：</div>
          <button @click="copy">复制</button>
          <textarea id="input">不能删掉</textarea>
        </div>
        <span class="text" id="text" style="word-wrap: break-word">{{
          cardMessage
        }}</span>
        <div class="ImgBox">
          <div class="left">
            <span>乐考收款码</span>
            <div class="imgBottom">
              <img class="img1" src="../images/zfb2.png" />
              <img class="img2" src="../images/wx2.png" />
            </div>
          </div>
          <div class="right">
            <img class="cord" :src="imgCord" />
          </div>
        </div>
      </div>
      <span class="wran"
        >温馨提示：公司严禁销售人员代收款，若有此现象请及时拨打400电话进行举报。</span
      >
      <div class="phoneBox">
        <img src="../images/phone.png" />
        <span>4006-211-229</span>
      </div>
      <!-- <div class="list">
        <span>二维码地址</span>
        <span class="text" id="text">{{ cardMessage }}</span>
        <button @click="copy">复制</button>
        <textarea id="input">不能删掉</textarea>
      </div>
      <button class="button" @click="createImg">生成二维码</button>
      <div class="ImgBox">
        <span>注册账号：{{ this.PhoneNumber }}</span>
        <span style="margin-bottom:0.1rem;">订单号：{{ this.newPono }}</span>
        <span>购买产品名称：</span>
        <span v-for="(item, index) in DPUSH" :key="index">{{item.data5}}</span>
        <span>实收金额：<span style="color:brown;font-weight:bold;">{{NUMtodayNUM}}</span>元</span>
        <img class="cord" :src="imgCord" />
        <div>
          <img class="icon" src="../images/dh.png" />
          <span>4006-211-229</span>
          <img class="icon" src="../images/wx.png" />
          <img class="icon" src="../images/zfb.png" />
        </div>
        <span class="wran"
          >温馨提示：公司严禁销售人员代收款,若有此现象请及时拨打400电话进行举报</span
        >
      </div> -->
    </div>
  </div>
</template>

<script>
import router from "@/router/index.js";
import baseUrl from '@/request/baseURL.js'
import { Notify } from "vant";
export default {
  data() {
    return {
      // 18932901612
      PhoneNumber: "",
      orderBoxData: false,
      radio2: "1",
      show: false,
      fromBoxPush: true,
      fromBoxPay: false,
      payBoxData: false,
      radioBoxData: true,
      hiddenPUSH: false,

      NAME: "",
      PHONE: "",
      NArray: [],
      returnData: true,
      payID:"",
      payForList:[],
      NUMtodayNUM: 0,
      OptionsArray: [
        {
          ProductId: "",
          UnitAmount: "",
          FinalAmount: "",
          DiscountAmount: "",
        },
      ],
      numValue: "",
      newPono: "",
      newMess: "",
      cardMessage: "",
      selected: "",
      imgCord: "",
      imgUrl: require("../images/jia.png"),
      DPUSH: [
        {
          text1: "",
          text2: "",
          text3: "",
          text4: "",
          text5: "",
          NUMbefore: "选完产品自动带入原价",
          NUMcenter: 0,
          NUMafter: "选完产品自动带入现价",
          imgUrl: require("../images/jia.png"),
          data1: "",
          data2: "",
          data3: "",
          data4: "",
          data5: "",
          NUMtoday: 0,
          value1: "",
          value2: "",
          value3: "",
          value4: "",
          value5: "",

          Array1: [],
          Array2: [],
          Array3: [],
          Array4: [],
          Array5: [],
        },
      ],
      productList: [],
      productId: "",
      productName: "",
      originalOrderPrice: 0, //订单原价
      orderDiscounts: 0, //订单优惠价格
      orderCurrentPrice: 0, //订单现价
      orderPaymentPrice: 0, //订单付款价格
      customerId: "",
      categoryArr: [], //分类下拉框数组
      subjectArr: [], //科目数组
      levelArr: [], //级别数组
      classArr: [], //分类数组
      productArr: [], //产品数组
      categorySelectId: "", //选中的分类id
      subjectSelectId: "", //选中的科目
      levelSelectId: "", //选中的级别
      classSelectId: "", //选中的分类
      productSelectId: "", //选中的产品
      orderProductList:[], //支付订单中的产品
      orderShow:false
    };
  },
  // watch: {
  //   productList: {
  //     handler(newValue, oldValue) {
  //       console.log(oldValue);
  //       var _this = this;
  //       newValue.forEach(element => {
  //         element.productItemList.forEach(item => {
  //           _this.originalOrderPrice =  _this.originalOrderPrice + item.OriginalAmount;
  //           _this.orderDiscounts = _this.orderDiscounts + item.DiscountAmount;
  //           _this.orderCurrentPrice = _this.orderCurrentPrice + item.CurrentAmount;
  //           _this.orderPaymentPrice = _this.orderPaymentPrice +  (item.CurrentAmount - item.DiscountAmount);
  //         })
  //       });
  //     },
  //   },
  // },
  created() {
    var JobRoleID = this.$route.query.JobRoleID;
    this.JobRoleID = JobRoleID;
  },
  methods: {
    // 判断+创建订单
    onPush() {
      var _this = this;
      // var item;
      // _this.DPUSH = [
      //   {
      //     text1: "",
      //     text2: "",
      //     text3: "",
      //     text4: "",
      //     text5: "",
      //     NUMbefore: "选完产品自动带入原价",
      //     NUMcenter: 0,
      //     NUMafter: "选完产品自动带入现价",
      //     imgUrl: require("../images/jia.png"),
      //     data1: "",
      //     data2: "",
      //     data3: "",
      //     data4: "",
      //     data5: "",
      //     NUMtoday: 0,
      //     value1: "",
      //     value2: "",
      //     value3: "",
      //     value4: "",
      //     value5: "",

      //     Array1: [],
      //     Array2: [],
      //     Array3: [],
      //     Array4: [],
      //     Array5: [],
      //   },
      // ];
      // for (var j = 0; j < _this.DPUSH.length; j++) {
      //   item = _this.DPUSH[j];
      //   item.value1 = "";
      //   item.value2 = "";
      //   item.value3 = "";
      //   item.value4 = "";
      //   item.value5 = "";
      //   item.data1 = "";
      //   item.data2 = "";
      //   item.data3 = "";
      //   item.data4 = "";
      //   item.data5 = "";
      //   item.text1 = "";
      //   item.text2 = "";
      //   item.text3 = "";
      //   item.text4 = "";
      //   item.text5 = "";
      //   _this.NUMtodayNUM = 0;
      // }
      _this.numValue = "";
      console.log(sessionStorage.getItem("token"), "OwnerId");
      _this.valuePush(event);
      if (_this.PhoneNumber !== "") {
        _this.axios
          .get(baseUrl+"api/PO/ISCustomer", {
            params: {
              OwnerID: sessionStorage.getItem("token"),
              ClientPhone: _this.PhoneNumber,
            },
          })
          .then(function (res) {
            console.log(res.data, "判断手机号有无的数据");
            if (res.data.Code == 0) {
              console.log("该销售下有" + _this.PhoneNumber + "手机号");
              _this.NArray = res.data.Data;
              _this.NAME = res.data.Data.RealName;
              _this.PHONE = res.data.Data.Tel;
              _this.axios
                .get(baseUrl+"api/PO/GetCategory")
                .then(function (res) {
                  // item.Array1 = res.data;
                  _this.categoryArr = res.data;
                  _this.subjectArr = [];
                  _this.levelArr = [];
                  _this.classArr = [];
                  _this.productArr = [];
                  _this.subjectSelectId = "";
                  _this.levelSelectId = "";
                  _this.classSelectId = "";
                  _this.productSelectId = "";
                  // console.log(item.Array1, "item.Array1");
                  _this.getPayForList();
                })
                .catch(function (err) {
                  console.log(err);
                });

              _this.show = true;
              _this.orderBoxData = true;
              _this.returnData = false;
              _this.customerId = res.data.Data.CustomerID;
              _this.productList = [];
              _this.orderProductList = [];
              _this.originalOrderPrice = 0;
              _this.orderDiscounts = 0;
              _this.orderCurrentPrice = 0;
              _this.orderPaymentPrice = 0;
            } else {
              Notify({
                type: "warning",
                duration: 1000,
                message: "该销售下无此手机号",
              });
            }
          })
          .catch(function (err) {
            console.log(err);
          });
      } else {
        Notify({
          type: "warning",
          duration: 1000,
          message: "请先输入手机号",
        });
      }
    },
    // 点击遮罩层
    overlayShow() {
      this.formdata = false;
      this.show = false;
      this.orderBoxData = false;
      this.payBoxData = false;
      this.returnData = true;
    },

    // 五个下拉菜单
    selectListFun1() {
      var _this = this;
      _this.subjectArr = [];
      _this.levelArr = [];
      _this.classArr = [];
      _this.productArr = [];
      _this.subjectSelectId = "";
      _this.levelSelectId = "";
      _this.classSelectId = "";
      _this.productSelectId = "";
      _this.axios
        .get(baseUrl+"api/PO/GetSubjectByCategoryId", {
          params: {
            CategoryId: _this.categorySelectId,
          },
        })
        .then(function (res) {
          _this.subjectArr = res.data;
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    selectListFun2() {
      var _this = this;
      _this.levelArr = [];
      _this.classArr = [];
      _this.productArr = [];
      _this.levelSelectId = "";
      _this.classSelectId = "";
      _this.productSelectId = "";
      _this.axios
        .get(baseUrl+"api/PO/GetSubjectTypeBySubjectID", {
          params: {
            SubjectID: _this.subjectSelectId,
          },
        })
        .then(function (res) {
          _this.levelArr = res.data;
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    selectListFun3() {
      var _this = this;
      // 切换时让下拉框为空
      _this.classArr = [];
      _this.productArr = [];
      _this.classSelectId = "";
      _this.productSelectId = "";
      _this.axios
        .get(baseUrl+"api/PO/GetCourseBySubjectTypeID", {
          params: {
            SubjectTypeId: _this.levelSelectId,
          },
        })
        .then(function (res) {
          _this.classArr = res.data;
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    selectListFun4() {
      var _this = this;
      _this.productArr = [];
      _this.productSelectId = "";
      if (_this.payID !="") {
        _this.axios
        .get(baseUrl+"api/Values/GetSubjectType_Product",{
          params: {
            CourseId: _this.classSelectId,
            productType: _this.payID,
          },

        })
        .then(function (res) {
          _this.productArr = res.data;
        })
        .catch(function (err) {
          console.log(err);
        });
      }


    },
    getPayForList(){
      var _this = this;
      _this.axios
        .get(baseUrl+"api/Values/GetPayment")
        .then(function (res) {
          res.data.forEach(item=>{
            item.disabled = false;
          });
          _this.payForList = res.data;
        })
        .catch(function (err) {
          console.log(err);
        });
    },



    selectPay(){
      var _this = this;
      if (_this.classSelectId !=""  && _this.payID !="") {
        _this.axios
        .get(baseUrl+"api/Values/GetSubjectType_Product",{
          params: {
            CourseId: _this.classSelectId,
            productType: _this.payID,
          },

        })
        .then(function (res) {
          _this.productArr = res.data;
        })
        .catch(function (err) {
          console.log(err);
        });
      } 
    },
    selectListFun5() {
      var _this = this;
      _this.ProductId = _this.productSelectId;
      _this.ProductName = _this.productArr.find(
        (v) => v.ProductId === _this.ProductId
      ).ProductName;
    },

    // 处理原价现价的生成
    valuePush(event) {
      var _this = this;
      var item;
      for (var j = 0; j < _this.DPUSH.length; j++) {
        item = _this.DPUSH[j];
      }
      if (
        item.text1 !== "" &&
        item.text2 !== "" &&
        item.text3 !== "" &&
        item.text4 !== "" &&
        item.text5 !== ""
      ) {
        console.log("生成价格");
        _this.value5 = event.target.value;
        // 原价
        item.NUMbefore = item.Array5[_this.value5].OriginalAmount;
        // 现价
        item.NUMafter = item.Array5[_this.value5].CurrentAmount;
        // 折扣价
        item.NUMcenter = item.NUMbefore - item.NUMafter;
        item.NUMtoday = item.NUMafter;
        var newArr = [];
        for (var i = 0; i < _this.DPUSH.length; i++) {
          newArr.push(_this.DPUSH[i].NUMafter);
        }
        _this.NUMtodayNUM = eval(newArr.join("+"));
      } else {
        console.log("继续");
        item.NUMbefore = "选完自动带入原价";
        item.NUMafter = "选完自动带入现价";
        item.NUMcenter = 0;
        item.NUMtoday = 0;
      }
    },
    // 控制切换的单选框
    radioClcik() {
      if (this.radio2 == "1") {
        this.fromBoxPush = true;
        this.fromBoxPay = false;
        console.log("现在是支付订单页面");
      } else {
        this.fromBoxPush = false;
        this.fromBoxPay = true;
        console.log("现在是充值订单页面");
      }
    },
    // 新增按钮+删除按钮
    pushBox(item, index) {
      var _this = this;
      console.log(index, "index");
      var Inx = index + _this.DPUSH.length;
      _this.axios
        .get(baseUrl+"api/PO/GetCategory")
        .then(function (res) {
          _this.DPUSH[Inx].Array1 = res.data;
          console.log(_this.DPUSH[Inx].Array1, "_this.DPUSH[Inx].Array1");
        })
        .catch(function (err) {
          console.log(err);
        });

      if (item.imgUrl == require("../images/jia.png")) {
        var pushArr = {
          text1: "",
          text2: "",
          text3: "",
          text4: "",
          text5: "",
          NUMbefore: "选完产品自动带入原价",
          NUMcenter: 0,
          NUMafter: "选完产品自动带入现价",
          imgUrl: require("../images/jian.png"),
          data1: "",
          data2: "",
          data3: "",
          data4: "",
          data5: "",
          NUMtoday: 0,
          value1: "",
          value2: "",
          value3: "",
          value4: "",
          value5: "",

          Array1: [],
          Array2: [],
          Array3: [],
          Array4: [],
          Array5: [],
        };
        var pushArr2 = {
          ProductId: "",
          UnitAmount: "",
          FinalAmount: "",
          DiscountAmount: "",
        };
        console.log("新增一个");
        _this.DPUSH.push(pushArr);
        _this.OptionsArray.push(pushArr2);
        _this.valuePush();
        console.log(_this.DPUSH, "新增之后的DPUSH");
        console.log(_this.OptionsArray, "新增之后的OptionsArray");
      } else {
        console.log("删除一个");
        _this.DPUSH.pop();
        _this.OptionsArray.pop();
        console.log(_this.DPUSH, "删除之后的DPUSH");
        console.log(_this.OptionsArray, "新增之后的OptionsArray");
        var newArr = [];
        for (var i = 0; i < _this.DPUSH.length; i++) {
          newArr.push(_this.DPUSH[i].NUMafter);
        }
        _this.NUMtodayNUM = eval(newArr.join("+"));
      }
    },
    // 生成订单
    openPayBox() {
      var _this = this;
      _this.cardMessage = "";
      _this.imgCord = "";
      console.log(_this.DPUSH, "购买产品数据");
      var item;
      for (var j = 0; j < _this.DPUSH.length; j++) {
        item = _this.DPUSH[j];
      }
      if (
        item.text1 !== "" &&
        item.text2 !== "" &&
        item.text3 !== "" &&
        item.text4 !== "" &&
        item.text5 !== "" &&
        item.data1 != "" &&
        item.data2 != "" &&
        item.data3 != "" &&
        item.data4 != "" &&
        item.data5 != "" &&
        item.text1 != "" &&
        item.text2 != "" &&
        item.text3 != "" &&
        item.text4 != "" &&
        item.text5 != ""
      ) {
        console.log(
          sessionStorage.getItem("token"),
          _this.NArray.CustomerID,
          eval(item.NUMcenter),
          eval(item.NUMtoday),
          _this.OptionsArray,
          "给后台传的参数"
        );
        _this.axios
          .get(baseUrl+"api/PO/CreateOrderNew", {
            params: {
              loginid: sessionStorage.getItem("token"),
              Customerid: _this.NArray.CustomerID,
              DiscountAmount: eval(item.NUMcenter),
              FinalAmount: _this.NUMtodayNUM,
              orderitem: JSON.stringify(_this.OptionsArray),
            },
          })
          .then(function (res) {
            console.log(res.data, "生成订单");

            _this.orderBoxData = false;
            _this.payBoxData = true;
            _this.newPono = res.data.Data;
            _this.newMess = res.data.Data;
            _this.axios
              .get(baseUrl+"api/Approvals/MakeORCode", {
                params: {
                  Pono: _this.newPono,
                  UrlPost: "http://quickpay.lekaowang.cn/pono=" + _this.newMess,
                },
              })
              .then(function (res) {
                console.log(res.data, "二维码链接地址");
                _this.cardMessage =
                  "http://quickpay.lekaowang.cn/pono=" + _this.newMess;
                _this.createImg();
              })
              .catch(function (err) {
                console.log(err);
              });
          })
          .catch(function (err) {
            console.log(err);
          });
      } else {
        Notify({
          type: "warning",
          duration: 1000,
          message: "请将信息补全",
        });
      }
    },
    // 生成订单
    payOrderFun() {
      var _this = this;

      _this.cardMessage = "";
      _this.imgCord = "";
      _this.cardMessage = "";
      _this.imgCord = "";
      console.log(this.numValue);
      if (_this.numValue !== "") {
        _this.axios
          .get(baseUrl+"api/PO/GetAmountPoNo", {
            params: {
              amount: _this.numValue,
              CustomerID: _this.NArray.CustomerID,
              OwnerID: _this.NArray.OwnerID,
            },
          })
          .then(function (res) {
            console.log(res, "充值订单");
            _this.orderBoxData = false;
            _this.payBoxData = true;
            _this.newPono = res.data.Messages;
            _this.newMess = res.data.Data;
            console.log("1111", _this.newPono);
            _this.axios
              .get(baseUrl+"api/Approvals/MakeORCode", {
                params: {
                  Pono: _this.newPono,
                  UrlPost: "http://quickpay.lekaowang.cn/pono=" + _this.newPono,
                },
              })
              .then(function (res) {
                console.log(res.data, "二维码链接地址");
                _this.cardMessage =
                  "http://quickpay.lekaowang.cn/pono=" + _this.newPono;
                _this.createImg();
              })
              .catch(function (err) {
                console.log(err);
              });
          })
          .catch(function (err) {
            console.log(err);
          });
      } else {
        Notify({
          type: "warning",
          duration: 1000,
          message: "请输入充值金额",
        });
      }
    },
    // 复制二维码地址
    copy() {
      var text = document.getElementById("text").innerText;
      var input = document.getElementById("input");
      input.value = text; // 修改文本框的内容
      input.select(); // 选中文本
      document.execCommand("copy"); // 执行浏览器复制命令
      Notify({
        background: "#004995",
        duration: 1000,
        message: "复制成功",
      });
    },
    // 生成二维码图片
    createImg() {
      var _this = this;
      console.log(_this.newPono, "22222");
      _this.axios
        .get(baseUrl+"api/Approvals/MakeORCode", {
          params: {
            Pono: _this.newPono,
            UrlPost: "http://quickpay.lekaowang.cn/pono=" + _this.newPono,
          },
        })
        .then(function (res) {
          console.log(res.data, "二维码图片地址");
          _this.imgCord = res.data;
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    getPrice() {
      var _this = this;
      _this.originalOrderPrice = 0;
      _this.orderDiscounts = 0;
      _this.orderCurrentPrice = 0;
      _this.orderPaymentPrice = 0;
      _this.productList.forEach((element) => {
        element.productItemList.forEach((item) => {
          _this.originalOrderPrice = (parseFloat(_this.originalOrderPrice) + parseFloat(item.OriginalAmount)).toFixed(2);
          _this.orderDiscounts =   (parseFloat(_this.orderDiscounts) + parseFloat(item.DiscountAmount)).toFixed(2); 
          _this.orderCurrentPrice = (parseFloat(_this.orderCurrentPrice) + parseFloat(item.CurrentAmount)).toFixed(2);
          _this.orderPaymentPrice = (parseFloat(_this.orderPaymentPrice)+(parseFloat(item.CurrentAmount) - parseFloat(item.DiscountAmount)) ) .toFixed(2);
        });
      });
    },

    // 15127186008
    //点击添加，产品列表增加
    addProductList() {
      var _this = this;
      if (_this.productSelectId !=="" && _this.payID !=="") {
        _this.axios
        .post(baseUrl+
          "api/PO/PoOpStringNew?ProductId=" +
            _this.ProductId +
            "&ProductName=" +
            _this.ProductName,
          {}
        )
        .then(function (res) {
          if (res.data.Code == "200") {
            res.data.Data.ProductName = _this.ProductName;
            res.data.Data.productItemList.forEach((item) => {
              //折扣金额
              item.DiscountAmount = 0;
            });
            _this.productList.push(res.data.Data);
            _this.getPrice();
            if (_this.productList.length > 0) {
              _this.payForList.forEach(item=>{
              if (item.PaymentID == _this.payID) {
                item.disabled = false;
              }else{
                item.disabled = true;
              }
              });
              console.log(_this.payForList);
            // _this.payForList = _this.payForList;
            }


          }
        })
        .catch(function (err) {
          console.log(err);
        });
      }else{
        if (_this.payID ==="") {
          Notify({
            type: "warning",
            duration: 1000,
            message: "请选择支付产品类型",
          });
          return  false;
        }
        if (_this.productSelectId ==="") {
          Notify({
            type: "warning",
            duration: 1000,
            message: "产品不得为空",
          });
          return  false;
        }
      }

      
    },
    //删除产品列表中的产品
    deleteProduct(item, id, index) {
      var _this = this;
      if (item.productItemList.length > 1) {
        _this.productList[index].productItemList.splice(
          _this.productList[index].productItemList.findIndex(
            (v) => v.KeyId === item.KeyId
          ),
          1
        );
        _this.getPrice();
      } else {
        _this.productList.splice(
          _this.productList.findIndex((v) => v.KeyId === item.KeyId),
          1
        );
        _this.getPrice();
      }
      if(_this.productList.length == 0){
        _this.payForList.forEach(item=>{
          item.disabled = false;
        });
      }
      // _this.productList
    },
    //  优惠输入框变化
    changeWords(amount, CurrentAmount, itemIndex, ListIndex) {
       amount = amount
        .replace(/[^\d.]/g, "") //只能输入数字
        .replace(/^()*(\d+)\.(\d\d).*$/, "$1$2.$3") //只能输入两个小数
        .replace(/\.{2,}/g, "."); //出现多个点时只保留第一个
      // 第一位不让输小数点
      if (amount == ".") {
        amount = "";
      }
      // 如果第一位是0，第二位必须大于0或者小数点
      if (amount.substring(0, 1) == 0) {
        if (amount.substring(1, 2) > 0) {
          amount = amount.substring(1, 2);
        } else if (
          amount.substring(1, 2) === 0 ||
          amount.substring(1, 2) === "0"
        ) {
          amount = "0";
        }
      } else {
        // 如果第一位数字大于0（不等于0肯定就大于0），仅需考虑第二位是小数点的情况
        if (amount.indexOf(".") !== -1) {
          if (amount.substring(0, 1) > 0) {
            console.log("第一位大于0");
          } else {
            console.log("第一位等于0");
            if (amount.substring(2, 3) > 0) {
              console.log("小数点后第一位大于0");
            } else {
              console.log("小数点后第一位等于0");
              amount = "0.";
            }
          }
        } else {
          console.log("没有小数点，正常输入");
        }
      }
      
      if (amount == "") {
        amount = "0";
      }
      if ((parseFloat(CurrentAmount)- (parseFloat(amount))).toFixed(2) < 0) {
          Notify({
            type: "warning",
            duration: 2000,
            message: "优惠金额不得大于现价",
          });
          amount = "0";
      }
      this.productList[ListIndex].productItemList[itemIndex].DiscountAmount = amount;
      this.getPrice();
    },
    //生成折扣订单
    discountOrders() {
      var _this = this;
      let arr = [];
      if (_this.productList.length > 0) {
        _this.productList.forEach((i) => {
          i.productItemList.forEach((item) => {
        
            let obj = {};
            obj.keyid = i.KeyId;
            obj.productId = item.ProductId;
            obj.productitemid = item.ProductItemId;
            obj.originalamount = item.OriginalAmount;
            obj.currentamount = item.CurrentAmount;
            obj.productItemDiscount = parseFloat(item.DiscountAmount);
            obj.productItemFinalAmount = parseFloat(item.CurrentAmount) - parseFloat(item.DiscountAmount);
            obj.ItemName = item.ItemName;
            arr.push(obj);
            _this.orderProductList = arr;
          });
        });
        let obj1 ={
          LoginId:sessionStorage.getItem("token"),
          Customerid:_this.customerId,
          paramorders:arr,
          productType:_this.payID
        }

        _this.orderShow = true;
        _this.axios
          .post(baseUrl+
            "api/PO/GenerateOrder",
            // "api/PO/GenerateOrder?LoginId=" +
            //   sessionStorage.getItem("token") +
            //   "&Customerid=" +
            //   _this.customerId +
            //   "&paramorders=" +
            //   JSON.stringify(arr),
            obj1
          )
          .then(function (res) {
            if (res.data.Code == "1") {
              _this.orderBoxData = false;
              Notify({
                type: "warning",
                duration: 3000,
                message: "订单价格超过底价优惠，已申请特殊折扣，请联系主管审核折扣!",
              });
              _this.show = false;
            }else if(res.data.Code == "2"){
              _this.NUMtodayNUM = _this.orderPaymentPrice;
              _this.orderBoxData = false;
              _this.payBoxData = true;
              _this.newPono = res.data.PONo;
              _this.cardMessage = res.data.Url;
              _this.createImg();
            }
            _this.orderShow = false;
          })
          .catch(function (err) {
            console.log(err);
            _this.orderShow = false;
          });
      } else {
        Notify({
          type: "warning",
          duration: 1000,
          message: "商品列表不得为空！",
        });
        return false;
      }
    },

    //返回上一层
    OnClickReturn() {
      router.go(-1);
    },
  },
};
</script>

<style>
* {
  padding: 0;
  margin: 0;
}
.container-pushOrder {
  width: 100%;
  height: 100%;
  border: 1px solid #c9c9c9;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  background: #ebebeb;
}
.container-pushOrder .nav {
  width: 3.55rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 0;
}
.container-pushOrder .spantext {
  font-size: 0.15rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
}
.container-pushOrder .searchBox {
  width: 0.8rem;
  height: 0.3rem;
  background: #004995;
  border-radius: 0.04rem;
  border: 0px solid;
  line-height: 0.3rem;
  font-size: 0.13rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
}
.van-search__content {
  width: 1.8rem;
}
.van-field__control {
  height: 0.3rem;
  line-height: 0.3rem;
  font-size: 0.14rem;
}

.orderBox {
  width: 3.35rem;
  height: 5.17rem;
  border: 1px solid rgb(92, 89, 89);
  font-size: 0.13rem;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: scroll;
  background: #ffffff;
  border-radius: 0.05rem;
  padding: 0.1rem;
  display: flex;
  flex-direction: column;
  z-index: 999;
}
.orderBoxHeight{
  height: 5.5rem;
}
.orderBox .Tip {
  color: rgb(228, 89, 89);
  font-size: 0.11rem;
}
.radioBox {
  width: 1.8rem;
  height: 0.2rem;
  margin-top: 0.1rem;
  font-size: 0.13rem;
}
.formBox {
  width: 3.3rem;
  height: 100%;
  overflow: scroll;
  margin: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.2rem;
}
.formBox .list {
  width: 3.2rem;
  height: 0.22rem;
  border-bottom: 1px solid #ebebeb;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  line-height: 0.22rem;
  margin-bottom: 0.1rem;
  font-size: 0.13rem;
}
.formBox .options {
  width: 3.2rem;
  height: 1.7rem;
  border-bottom: 1px solid #ebebeb;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.1rem;
  font-size: 0.13rem;
}
.selectListBox {
  display: flex;
  flex-direction: column;
  width: 2.2rem;
  height: 1.5rem;
}
.selectListBox .selectList {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 2.2rem;
  height: 0.3rem;
}
.pushButton {
  /* box-sizing: border-box; */
  margin-bottom: 0.1rem !important;
  margin-top: 0.1rem;
  width: 1.2rem;
  height: 0.3rem;
  line-height: 0.3rem;
  text-align: center;
  color: #ffffff;
  display: block;

  background: #004995;
}
.selectListBox .imgBox {
  width: 0.25rem;
  height: 0.25rem;
  background: #d7eaff;
  margin-top: 0.02rem;
}

.formBox2 {
  width: 3.3rem;
  height: 100%;
  overflow: scroll;
  margin: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.2rem;
}
.formBox2 .list {
  width: 3.2rem;
  height: 0.32rem;
  border-bottom: 1px solid #ebebeb;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 0.1rem;
  font-size: 0.13rem;
}
.formBox2 button {
  margin-bottom: 0.2rem;
  margin-top: 0.1rem;
  width: 1.2rem;
  height: 0.4rem;
  text-align: center;
  color: #ffffff;
  background: #004995;
}
.formBox2 .list .numInput {
  width: 1.5rem;
  height: 0.3rem;
  color: #333333;
  font-size: 0.13rem;
  margin-bottom: 0.08rem;
}
.payBox {
  width: 3.35rem;
  height: 4.5rem;
  overflow: scroll;
  border: 1px solid brown;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  border-radius: 0.05rem;
  display: flex;
  flex-direction: column;
  z-index: 999;
}
.payBox .topBox {
  width: 2.95rem;
  display: flex;
  flex-direction: column;
  font-size: 0.15rem;
  border-bottom: 1px solid #ebebeb;
  padding: 0.1rem 0.1rem 0 0.1rem;
}
.payBox .topBox .list {
  width: 2.95rem;
  font-size: 0.15rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #666666;
  margin-bottom: 0.05rem;
}
.payBox .topBox .list2 {
  width: 2.95rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.payBox .topBox .list .listRight {
  width: 1.9rem;
  text-align: right;
  word-wrap: break-word;
  margin-bottom: 0.08rem;
}

.payBox .bottomBox {
  width: 2.95rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.15rem;
  padding: 0.1rem 0.1rem 0 0.1rem;
}
.payBox .bottomBox .list {
  width: 2.95rem;
}
.payBox .bottomBox .list button {
  width: 0.43rem;
  height: 0.21rem;
  background: #004995;
  border: 0px solid;
  border-radius: 0%;
}
.payBox .bottomBox .text {
  width: 2.95rem;
  height: 0.5rem;
  font-size: 0.15rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
}
.payBox .bottomBox .ImgBox {
  width: 2rem;
  height: 0.8rem;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin-top: 0.1rem;
}
.payBox .bottomBox .ImgBox img {
  width: 0.25rem;
  height: 0.25rem;
  margin-top: 0px;
  border: 0px solid;
}
.payBox .bottomBox .ImgBox .img1 {
  margin-right: 0.2rem;
}
.payBox .bottomBox .ImgBox .left {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 0.75rem;
  height: 0.5rem;
}
.payBox .bottomBox .ImgBox .right {
  width: 0.8rem;
  height: 0.8rem;
}
.payBox .bottomBox .ImgBox .right img {
  width: 100%;
  height: 100%;
}
.payBox .bottomBox .ImgBox .left span {
  font-size: 0.15rem;
  font-weight: bold;
}
.payBox .bottomBox .ImgBox .left .imgBottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.payBox .wran {
  height: 0.3rem;
  font-size: 0.11rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #666666;
  margin-top: 0.2rem;
}
.payBox .phoneBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 1.33rem;
  height: 0.2rem;
  margin-top: 0.2rem;

  font-size: 0.15rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #666666;
}
.payBox .phoneBox img {
  width: 0.15rem;
  height: 0.15rem;
  margin-right: 0.05rem;
  margin-top: 0rem;
  border: 0px solid;
}

#input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -10;
}
.imgBox img {
  width: 0.25rem;
  height: 0.25rem;
  line-height: 0.25rem;
}

.return {
  font-size: 0.15rem;
  color: #fff;
  position: fixed;
  right: 16px;
  bottom: 88px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  z-index: 1500;
  overflow: hidden;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  background-color: #004995;
}

.ulContent {
  display: flex;
  align-items: center;
  width: 100%;
}
.ulContent li {
  width: 50%;
  height: 0.35rem;
  line-height: 0.35rem;
}
.ulContent li span select {
  width: 1.2rem;
  height: 0.25rem;
}
.ulContent li input[type="number"] {
  width: 0.5rem;
  height: 0.25rem;
  text-align: center;
}
/* .productContent {
  height: 150px;
  overflow-y: scroll;
} */
.orderTotal {
  width: 100%;
  /* height: 80px; */
}

.orderCombination{
  position: absolute;
  top: 3.15rem;
  width: 100%;

}
.orderCombination p{
  height: 0.3rem;
  line-height: 0.3rem;
}
.pushOrderButton{
  margin: 0.1rem auto;
  margin-bottom: 0.1rem !important;
  width: 1.2rem;
  height: 0.3rem;
  line-height: 0.3rem;
  text-align: center;
  color: #ffffff;
  display: block;
  background: #004995;
}


</style>
