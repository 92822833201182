<template>
  <div class="relearn">
    <div class="nav">
      <span class="spantext">电话</span>
      <van-search
        v-model="PhoneNumber"
        ref="PhoneNumber"
        left-icon="none"
        right-icon="none"
        background="#EBEBEB"
        input-align="left"
        maxlength="13"
        @search="onSearch"
      />
      <button class="searchBox" @click="onSearch">搜索</button>
    </div>
    <div class="form" v-for="(item, index) in dataList" :key="index">
      <div class="list">
        <div class="listLeft">课程名称</div>
        <div class="listRight">{{ item.CourseName  }}</div>
      </div>
      <div class="list">
        <div class="listLeft">产品名称</div>
        <div class="listRight">{{ item.ProductName }}</div>
      </div>
      <div class="list">
        <div class="listLeft">学员姓名</div>
        <div class="listRight">{{ item.RealName }}</div>
      </div>
      <div class="list">
        <div class="listLeft">审核状态</div>
        <div class="listRight">{{ item.CheckStatus }}</div>
      </div>
      <div class="list">
        <div class="listLeft">申请时间</div>
        <div class="listRight">
          {{ item.RequestDate }}
        </div>
      </div>
      <div class="list">
        <div class="listLeft">审核人</div>
        <div class="listRight">{{ item.CheckUserName   }}</div>
      </div>
      <div class="list">
        <div class="listLeft">审核时间</div>
        <div class="listRight">
          {{ item.CheckDate}}
        </div>
      </div>
      <div class="buttonBox" v-if="item.CheckStatus=='已审核'">
        <button class="bottomButton" @click="relearnRecords(item)">
          冻结记录
        </button>
        <button class="bottomButton" @click="RelearnCheck(item)">
          查看
        </button>
      </div>
      <div class="buttonBox" v-else-if="item.CheckStatus=='未审核'">
        <button class="bottomButton" @click="relearnRecords(item)">
          冻结记录
        </button>
        <button class="bottomButton" @click="reviewRelearn(item,2)">
          审核
        </button>
      </div>
      <div class="buttonBox" v-else>
        <button class="bottomButton" @click="reviewRelearn(item,1)">
          二次修改
        </button>
        <button class="bottomButton" @click="relearnRecords(item)">
          冻结记录
        </button>
        <button class="bottomButton" @click="RelearnCheck(item)">
          查看
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import router from "@/router/index.js";
import {get} from '@/request/http'
import {GetCSRLeanList}  from '@/request/api'
import { Toast } from "vant";
export default {
  name: "relearn",
  data() {
    return {
      PhoneNumber: "",
      dataList:[],
    };
  },
  mounted() {
    this.onSearch();
    // if (localStorage.getItem('relearnPhone')!==''&& localStorage.getItem('relearnPhone')!== null && localStorage.getItem('relearnPhone')!== undefined && localStorage.getItem('tabName')=='relearn'){
    //   this.PhoneNumber = localStorage.getItem('relearnPhone');
      
    // }
  },
  methods: {
    //通过手机号查询该账号下的重学记录
    onSearch() {
      var _this = this;
      get(GetCSRLeanList+'?Tel='+_this.PhoneNumber)
        .then(function (res) {
          // Code=200 为成功 Code=0为数据为空
          if (res.Code == "200") {
            _this.dataList = res.Data;
          } else {
            Toast(res.Msg);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //查看
    RelearnCheck(item){
      // window.localStorage.setItem('relearnPhone',this.PhoneNumber);
      router.push({ path: "/relearnCheck", query: { JobRoleID: this.$route.query.JobRoleID,ContractItemID:item.ContractItemID,RequestRLearnID:item.RequestRLearnID} });
    },
    OnClickReturn() {
      router.go(-1); //返回上一层
    },
    //输入框清除按钮点击后操作
    clearPhoneNumber(){
      this.dataList = [];
    },
    //输入框手机号变化时操作
    phoneNumberChange(){
      if (this.PhoneNumber=="") {
         this.dataList = [];
      }
    },
    reviewRelearn(item,id){
      // id  1 二次修改  2审核
      if (id==1) {
        window.localStorage.setItem('relearnPhone',this.PhoneNumber);
        router.push({ path: "/reviewRelearn", query: { JobRoleID: this.$route.query.JobRoleID,ContractItemID:item.ContractItemID,RequestRLearnID:item.RequestRLearnID,id:id} });
      } else {
        window.localStorage.setItem('relearnPhone',this.PhoneNumber);
        router.push({ path: "/reviewRelearn", query: { JobRoleID: this.$route.query.JobRoleID,ContractItemID:item.ContractItemID,RequestRLearnID:item.RequestRLearnID,id:id} });
      }
      
    },
    relearnRecords(item){
      window.localStorage.setItem('relearnPhone',this.PhoneNumber);
      router.push({ path: "/relearnRecords", query: { ProductName: item.ProductName,ContractItemID:item.ContractItemID,CustomerID:item.CustomerID} });
    },

  },
  //keepAlive缓存组件后 可通过该方法进行数据刷新
  activated() {
    if (localStorage.getItem('tabName') == 'relearn'&& this.PhoneNumber !== "" ) {
      this.onSearch(); // 重新加载
    }
  },

};
</script>

<style scoped>
.relearn {
  width: 100%;
  height: 100%;
  border: 1px solid #c9c9c9;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  background: #ebebeb;
}
.relearn .nav {
  width: 3.55rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 0;
}
.relearn .spantext {
  width: 0.4rem;
  font-size: 0.14rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
}
.relearn .searchBox {
  width: 0.5rem;
  height: 0.3rem;
  background: #004995;
  border-radius: 0.04rem;
  border: 0px solid;
  line-height: 0.3rem;
  font-size: 0.13rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  margin-right: 0.03rem;
}
.van-search__content {
  width: 1.8rem;
}
.van-field__control {
  height: 0.3rem;
  line-height: 0.3rem;
  font-size: 0.14rem;
}
.form{
  margin-left: 0 !important;
  margin-bottom: 0 !important;
}

.relearn .form {
  width: 3.75rem;
  /* height: 5.02rem; */
  background: #ffffff;
  border-radius: 0.05rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding-top: 0.17rem; */
  padding-bottom: 0.15rem;
  margin-top: 0.2rem;
}


.relearn .form .list {
  width: 3.15rem;
  height: 0.32rem;
  border-bottom: 1px solid #ebebeb;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 0.1rem;
}
.list .listLeft {
  height: 0.21rem;
  font-size: 0.15rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
  width: 0.8rem;
  display: block;
}
.list .listRight {
  height: 0.21rem;
  font-size: 0.15rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #555555;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.buttonBox{
  display: flex;
  justify-content: space-evenly;
  width: 85%;
}


.bottomButton {
  width: 1.5rem;
  height: 0.5rem;
  background: #004995;
  border-radius: 0.05rem;
  font-size: 0.17rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  margin-top: 0.2rem;
}
.return {
  font-size: 0.15rem;
  color: #fff;
  position: fixed;
  right: 16px;
  bottom: 88px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  z-index: 15;
  overflow: hidden;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  background-color: #004995;
}
/deep/ .van-field__button{
  padding-left: 25px;
}


</style>
