<template>
  <div class="container-information">
    <div class="return" @click="OnClickReturn" v-if="returnData">返回</div>
    <van-overlay
      :show="show"
      @click="
        show = false;
        returnData = true;
        lookHidden = false;
        hiddenAdd = false;
      "
    />
    <div class="nav">
      <select
        class="selectList"
        name="select1"
        id="selectId1"
        v-model="value1"
        @change="selectChange($event)"
      >
        <option value="index1">电话</option>
        <option :value="index2">用户名</option>
        <option :value="index3">姓名</option>
        <option :value="index4">身份证</option>
        <option :value="index5">QQ</option>
        <option :value="index6">微信</option>
      </select>
      <input type="text" class="searchInput"   v-model="searchValue">
      <!-- <van-search
        v-model="searchValue"
        ref="searchValue"
        right-icon="search"
        left-icon="none"
        background="#EBEBEB"
        input-align="left"
      /> -->
      <button class="searchBox" @click="onSearch">搜索</button>
      <button class="addBox" @click="onAdd">录入</button>
      <button class="addBox" @click="toAddBox">添加</button>
      <button class="addBox" @click="giveUp">放弃</button>
    </div>
    <div class="mainBox">
      <van-tabs
        v-model="activeName"
        title-active-color="#004995"
        title-inactive-color="#000000"
        background="#ffffff"
        color="#004995"
        @click="onClick"
      >
        <van-tab title="基本信息" name="a">
          <van-empty
            image="search"
            description="没有基本信息"
            v-if="statedata1"
          />
          <div class="formBox" v-else>
            <div class="list">
              <div class="listLeft">用户名</div>
              <div class="listRight">{{ info1.Name }}</div>
            </div>
            <div class="list">
              <div class="listLeft">电话</div>
              <div class="listRight">{{ info1.Tel }}</div>
            </div>
            <div class="list">
              <div class="listLeft">真实姓名</div>
              <div class="listRight">{{ info1.RealName }}</div>
            </div>
            <div class="list">
              <div class="listLeft">身份证号码</div>
              <div class="listRight">{{ info1.IdentityCard }}</div>
            </div>
            <div class="list">
              <div class="listLeft">所属客服</div>
              <div class="listRight">{{ info1.UserName }}</div>
            </div>
            <div class="list">
              <div class="listLeft">所属事业部</div>
              <div class="listRight">{{ info1.CustomerLabel }}</div>
            </div>
            <div class="list">
              <div class="listLeft">部门</div>
              <div class="listRight">{{ info1.TeamName }}</div>
            </div>
            <div class="list">
              <div class="listLeft">组别</div>
              <div class="listRight">{{ info1.OrgName }}</div>
            </div>
            <div class="list">
              <div class="listLeft">是否非调库</div>
              <div class="listRight">
                {{ (info1.NotDiaoKu == "" ? "否":"是" ) }}
              </div>
            </div>
            <div class="list">
              <div class="listLeft">会员余额</div>
              <div class="listRight">{{ info1.RemainingAmount }}</div>
            </div>
            <div class="list">
              <div class="listLeft">调库天数</div>
              <div class="listRight">{{ info1.DiaoKuDays }}</div>
            </div>
            <div class="list">
              <div class="listLeft">客户类型</div>
              <div class="listRight">{{ info1.UpSource }}</div>
            </div>
            <div class="list">
              <div class="listLeft">合并备注</div>
              <div class="listRight">{{ info1.MergeContractRemark }}</div>
            </div>
          </div>
        </van-tab>
        <van-tab title="跟进信息" name="b">
          <van-empty
            image="search"
            description="没有跟进信息"
            v-if="statedata2"
          />
          <div class="formBox" v-else>
            <div v-for="(item, index) in info2" :key="index" class="listBox">
              <div class="list">
                <div class="listLeft">意向科目</div>
                <div class="listRight">{{ item.SubjectName }}</div>
              </div>
              <div class="list">
                <div class="listLeft">客户状态</div>
                <div class="listRight">{{ item.Result }}</div>
              </div>
              <div class="list">
                <div class="listLeft">下次跟进时间</div>
                <div class="listRight">
                  {{ item.NextStepDate.replace("T", "  ") }}
                </div>
              </div>
              <div class="list">
                <div class="listLeft">备注</div>
                <div class="listRight">{{ item.Remark }}</div>
              </div>
              <div class="list">
                <div class="listLeft">跟进客服</div>
                <div class="listRight">{{ item.UserName }}</div>
              </div>
              <div class="list">
                <div class="listLeft">跟进时间</div>
                <div class="listRight">
                  {{ item.createddate.replace("T", "  ").slice(0, 19) }}
                </div>
              </div>
            </div>
          </div>
        </van-tab>
        <van-tab title="课程信息" name="c">
          <van-empty
            image="search"
            description="没有课程信息"
            v-if="statedata3"
          />
          <div class="formBox" v-else>
            <div v-for="(item, index) in info3" :key="index" class="listBox">
              <div class="list">
                <div class="listLeft">合同类型</div>
                <div class="listRight">{{ item.TypeName }}</div>
              </div>
              <div class="list">
                <div class="listLeft">课程名称</div>
                <div class="listRight">{{ item.CourseName }}</div>
              </div>
              <div class="list">
                <div class="listLeft">产品名称</div>
                <div class="listRight">{{ item.ProductName }}</div>
              </div>
              <div class="list">
                <div class="listLeft">剩余时长</div>
                <div class="listRight">{{ item.RemainderTime / 3600 }}小时</div>
              </div>
              <div class="list">
                <div class="listLeft">重学次数</div>
                <div class="listRight">{{ item.RLearnCount }}</div>
              </div>
              <div class="list">
                <div class="listLeft">考试期数</div>
                <div class="listRight">
                  {{ item.ExaminationTimeName }}
                </div>
              </div>
              <div class="list">
                <div class="listLeft">开课时间</div>
                <div class="listRight">
                  {{ item.CreatedDate.replace("T", "  ").slice(0, 19) }}
                </div>
              </div>
              <div class="list">
                <div class="listLeft">成交价</div>
                <div class="listRight">{{ item.Amount }}</div>
              </div>
              <div class="list">
                <div class="listLeft">是否关闭</div>
                <div class="listRight">
                  {{ item.isClosed == true ? "已关闭" : "未关闭" }}
                </div>
              </div>
              <div class="list">
                <div class="listLeft">是否冻结</div>
                <div class="listRight">
                  {{ (item.isFreezed == true ? "已冻结" : "未冻结") }}
                </div>
              </div>
              <div class="list">
                <div class="listLeft">冻结记录</div>
                <div class="listRight underline" @click="openLook(item)">
                  查看冻结记录
                </div>
              </div>
              <div class="list">
                <div class="listLeft">是否终止</div>
                <div class="listRight">
                  {{ (item.isTermination == true ? "已终止" : "未终止") }}
                </div>
              </div>
              <div class="list">
                <div class="listLeft">终止原因</div>
                <div class="listRight">{{ item.TerminationRemark }}</div>
              </div>
              <div class="list">
                <div class="listLeft">所属班主任</div>
                <div class="listRight">{{ item.ClassTeacherName }}</div>
              </div>
              <div class="list">
                <div class="listLeft">是否添加班主任</div>
                <div class="listRight">
                  {{ (item.WeChat == null || item.WeChat == ""  ? "未添加" : "已添加") }}
                </div>
              </div>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <div class="tankuangBox" v-if="lookHidden">
      <table border="1" class="tableBox" width="350">
        <thead>
          <tr>
            <th>序号</th>
            <th>操作</th>
            <th>操作时间</th>
            <th>操作人</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in info4" :key="index">
            <th>{{ index + 1 }}</th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="toaddBox" v-if="hiddenAdd">
      <div class="list">
        <div class="listLeft">姓名</div>
        <input type="text" v-model="Addname" placeholder="请输入客户姓名" />
      </div>
      <div class="list">
        <div class="listLeft">手机号</div>
        <input
          maxlength="13"
          type="number"
          v-model="Addphone"
          placeholder="请输入手机号码"
        />
      </div>
      <div class="list">
        <div class="listLeft">推广来源</div>
        <select
          class="selectList"
          name="select1"
          id="selectId1"
          v-model="select1"
        >
          <!-- <option>老客户转介绍</option>
          <option>公众号</option>
          <option>注册用户</option>
          <option>乐语</option>
          <option>微信二维码</option>
          <option>小程序</option>
          <option>现场面审</option>
          <option>百度信息流</option> -->
          
          <option v-for="(item, index) in methodList" :key="index">
            {{ item.Method }}
          </option>
        </select>
      </div>
      <div class="list">
        <div class="listLeft">推广科目</div>
        <select
          class="selectList"
          name="select2"
          id="selectId2"
          v-model="select2"
        >
          <option v-for="(item, index) in ArrayOptions" :key="index">
            {{ item.ExtendSubject }}
          </option>
        </select>
      </div>
      <button @click="toSubmitAdd">确认</button>
    </div>
  </div>
</template>

<script>
import router from "@/router/index.js";
import baseUrl from '@/request/baseURL.js'
import { Notify } from "vant";
export default {
  data() {
    return {
      returnData: true,
      index1: "电话",
      index2: "用户名",
      index3: "姓名",
      index4: "身份证",
      index5: "QQ",
      index6: "微信",
      value1: "index1",
      INDEX: "",
      TYPE: "tel",
      searchValue: "",
      activeName: "a",
      statedata1: true,
      statedata2: true,
      statedata3: true,
      show: false,
      info1: "",
      info2: [],
      info3: [],
      info4: [],
      infodata: [],
      infodata2: [],
      lookHidden: false,

      //
      hiddenAdd: false,
      Addname: "",
      Addphone: "",
      ArrayOptions: [],
      select1: "",
      select2: "",
      methodList:[]
    };
  },
  methods: {
    //返回上一层
    OnClickReturn() {
      router.go(-1);
    },
    //传入跟进信息排序的数组
    MsgSort(obj) {
      obj.sort((a, b) => {
        let t1 = new Date(
          Date.parse(a.createddate.replace("T", "  ").slice(0, 19))
        );
        let t2 = new Date(
          Date.parse(b.createddate.replace("T", "  ").slice(0, 19))
        );
        // console.log(t1.getTime(),t2.getTime());
        return t2.getTime() - t1.getTime();
      });
      return obj;
    },
    //传入课程信息排序的数组
    MsgSort2(obj) {
      obj.sort((a, b) => {
        let t1 = new Date(
          Date.parse(a.CreatedDate.replace("T", "  ").slice(0, 19))
        );
        let t2 = new Date(
          Date.parse(b.CreatedDate.replace("T", "  ").slice(0, 19))
        );
        // console.log(t1.getTime(),t2.getTime());
        return t2.getTime() - t1.getTime();
      });
      return obj;
    },
    selectChange(event) {
      var _this = this;
      _this.INDEX = event.target.value;
      console.log(_this.value1, _this.INDEX);
      switch (_this.INDEX) {
        case "index1":
          _this.TYPE = "tel";
          console.log(_this.TYPE);
          break;
        case "用户名":
          _this.TYPE = "name";
          break;
        case "姓名":
          _this.TYPE = "realname";
          break;
        case "身份证":
          _this.TYPE = "identitycard";
          break;
        case "QQ":
          _this.TYPE = "qq";
          break;
        case "微信":
          _this.TYPE = "wechat";
      }
      _this.statedata1 = true;
      _this.statedata2 = true;
      _this.statedata3 = true;
      _this.searchValue = "";
    },
    onSearch() {
      var _this = this;

      _this.statedata1 = true;
      _this.statedata2 = true;
      _this.statedata3 = true;
      if (_this.searchValue == "") {
        Notify({
          type: "warning",
          duration: 1000,
          message: "请先输入再搜索",
        });
      } else {
        // 13601324605

        console.log(_this.searchValue, "搜索的内容", _this.TYPE);
        _this.axios
          .get(baseUrl+"api/Information/Load_PubicCustomerInfodetails",
            {
              params: {
                type: _this.TYPE,
                value: _this.searchValue,
                LoginId:sessionStorage.getItem("token")
              },
            }
          )
          .then(function (res) {
            console.log(res.data, "数据");

            _this.info1 = res.data;

            _this.statedata1 = false;
            _this.statedata2 = false;
            _this.statedata3 = false;
            _this.activeName = "a";
          })
          .catch(function (err) {
            console.log(err);
          });
      }
    },
    //放弃
    giveUp() {
      var _this = this;
      // _this.statedata1 = true;
      // _this.statedata2 = true;
      // _this.statedata3 = true;
      if (_this.searchValue == "") {
        Notify({
          type: "warning",
          duration: 1000,
          message: "搜素内容不得为空",
        });
      } else {
        if (_this.statedata1 == true || _this.statedata2 == true  || _this.statedata3 == true) {
          Notify({
            type: "warning",
            duration: 1000,
            message: "请先搜索后放弃",
          });
        } else {
          _this.axios
            .post(
              baseUrl+"api/Customer/ReleaseCustomer?Tel="+_this.searchValue+'&LoginId='+sessionStorage.getItem("token")
            )
            .then(function (res) {
              _this.activeName = "a";
              if (res.data=='放弃客户成功'||res.data=='放弃客户成功，添加放弃客户日志失败') {
                _this.onSearch();
              } else {
                Notify({
                  type: "warning",
                  duration: 1000,
                  message: res.data,
                });
              }  
            })
            .catch(function (err) {
              console.log(err);
            });





        }        
      }
    },
    onClick(name) {
      var _this = this;
      if (name == "b") {
        _this.axios
          .get(
            baseUrl+"api/Information/Load_ActivityList",
            {
              params: {
                customerid: _this.info1.CustomerID,
              },
            }
          )
          .then(function (res) {
            console.log(res.data, "数据");
            _this.info2 = res.data;
            _this.info2 = _this.MsgSort(_this.info2);
            console.log(_this.info2, "排完序的数组");
            if (_this.info2.length == "0") {
              _this.statedata2 = true;
            }
            for (var i = 0; i < _this.info2.length; i++) {
              _this.infodata = _this.info2[i];
            }
            console.log(_this.infodata, "_this.infodata");
          })
          .catch(function (err) {
            console.log(err);
          });
      } else if (name == "c") {
        _this.axios
          .get(
            baseUrl+"api/Information/Load_ActivityContractList",
            {
              params: {
                customerid: _this.info1.CustomerID,
              },
            }
          )
          .then(function (res) {
            // _this.info3 = res.data;
            _this.info3 = _this.MsgSort2(res.data)
            // _this.info3 = _this.MsgSort2(_this.info3);
            // console.log(_this.info3, "排完序的数组");
            if (_this.info3.length == "0") {
              _this.statedata3 = true;
            }

            // for (var j = 0; j < _this.info3.length; j++) {
            //   _this.infodata2 = _this.info3[j];
            // }
            // console.log(_this.infodata2, "_this.infodata2");
          })
          .catch(function (err) {
            console.log(err);
          });
      } else {
        console.log(name);
      }
    },
    openLook(item) {
      var _this = this;
      // console.log(_this.infodata2.ContractItemID, "contractId");
      _this.axios
        .get(
          baseUrl+"api/Information/Load_Freezed",
          {
            params: {
              contractId: item.ContractItemID,
            },
          }
        )
        .then(function (res) {
          console.log(res, "数据");

          _this.info4 = res.data;
        })
        .catch(function (err) {
          console.log(err);
        });

      _this.show = true;
      _this.lookHidden = true;
      _this.returnData = false;
    },
    // 录入
    onAdd() {
      var _this = this;
      if (_this.searchValue == "") {
        Notify({
          type: "warning",
          duration: 1000,
          message: "电话不能为空",
        });
      } else if (_this.searchValue.length !== 11) {
        Notify({
          type: "warning",
          duration: 1000,
          message: "请先输入正确的手机号再查询",
        });
      } else {
        // _this.show = true;
        // _this.returnData = false;

        _this.axios
          .get(
            baseUrl+"api/Information/CurrentInput",
            {
              params: {
                userid: sessionStorage.getItem("token"),
                type: _this.TYPE,
                value: _this.searchValue,
              },
            }
          )
          .then(function (res) {
            console.log(res, "数据");
            console.log(_this.TYPE, "_this.TYPE");
            Notify({
              type: "success",
              duration: 1500,
              message: res.data,
            });
          })
          .catch(function (err) {
            console.log(err);
          });
      }
    },
    toAddBox() {
      var _this = this;
      _this.Addname = "";
      _this.Addphone = "";
      _this.select1 = "";
      _this.select2 = "";
      _this.axios
        .get(baseUrl+"api/Information/GetExtendSubject", {
          params: {
            userid: sessionStorage.getItem("token"),
          },
        })
        .then(function (res) {
          _this.ArrayOptions = res.data;
          console.log(res.data, "数据");

          _this.show = true;
          _this.hiddenAdd = true;
        })
        .catch(function (err) {
          console.log(err);
        });
        _this.getMethod();
    },
    getMethod(){
      var _this = this;
      _this.axios.get(baseUrl+"api/Values/GetBasic_Method", {params: {},})
          .then(function (res) {
            _this.methodList = res.data;
          })
          .catch(function (err) {
            console.log(err);
          });



    },
    toSubmitAdd() {
      var _this = this;
      if (_this.Addphone != "" && _this.select1 != "" && _this.select2 != "") {
        _this.axios
          .get(baseUrl+"api/Information/SaleCustomerAdd", {
            params: {
              userid: sessionStorage.getItem("token"),
              RealName: _this.Addname,
              Tel: _this.Addphone,
              Method: _this.select1,
              Subject: _this.select2,
            },
          })
          .then(function (res) {
            _this.ArrayOptions = res.data;
            _this.hiddenAdd = false;
            _this.show = false;
            console.log(res.data.msg);
            Notify({
              type: "success",
              duration: 2000,
              message: res.data.msg,
            });
          })
          .catch(function (err) {
            console.log(err);
          });
      } else {
        Notify({
          type: "warning",
          duration: 2000,
          message: "请检查手机号、推广来源和推广科目是否填写",
        });
      }
    },
  },
};
</script>

<style>
* {
  padding: 0;
  margin: 0;
}
.container-information {
  width: 100%;
  height: 100vh;
  border: 1px solid #c9c9c9;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  background: #ebebeb;
}
.container-information .nav {
  width: 3.55rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-between; */
  margin-left: 0;
}
.container-information .nav .selectList {
  width: 0.65rem;
  height: 0.3rem;
  margin: 0.05rem 0;
  font-size: 0.14rem;
  border: 0px solid;
  font-family: PingFang SC;
  color: #004995;
}
.container-information .van-search {
  width: 1.5rem;
  margin-right: 0.05rem;
}
.container-information .addBox {
  width: 0.5rem;
  height: 0.3rem;
  background: #004995;
  border-radius: 0.04rem;
  border: 0px solid;
  line-height: 0.3rem;
  font-size: 0.13rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  margin-right: 0.05rem;
}
.container-information .searchBox {
  width: 0.5rem;
  height: 0.3rem;
  background: #004995;
  border-radius: 0.04rem;
  border: 0px solid;
  line-height: 0.3rem;
  font-size: 0.13rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  margin-right: 0.05rem;
}
.return {
  font-size: 0.15rem;
  color: #fff;
  position: fixed;
  right: 16px;
  bottom: 28px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  z-index: 1500;
  overflow: hidden;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  background-color: #004995;
}
.container-information .mainBox {
  width: 3.55rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 0;
  background: #ffffff;
  padding-bottom: 0.5rem;
}
.container-information .mainBox .formBox {
  height: 100%;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
}
.mainBox .van-tabs__nav {
  width: 3.55rem;
  font-size: 0.15rem;
  font-weight: 500;
}
.formBox .list .listLeft {
  font-size: 0.15rem;
}
.formBox .list .listRight {
  width: 2rem;
  text-align: right;
  font-size: 0.15rem;
}
.formBox .list {
  border-bottom: 1px solid #ebebeb;
}
.formBox .listBox {
  margin-bottom: 0.3rem;
  border: 1px solid #004995;
  padding: 0.1rem 0;
}
.list .underline {
  text-decoration: underline;
  color: #004995;
}
.tankuangBox {
  width: 3.5rem;
  height: 4rem;
  background: #ffffff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0.3rem 0;
  z-index: 999;
}

.container-information .toaddBox {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 0.04rem;
  width: 3rem;
  padding: 0.2rem;
  background: #ffffff;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.toaddBox .list {
  width: 3rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  line-height: 0.22rem;
  margin-bottom: 0.1rem;
  font-size: 0.13rem;
  padding-bottom: 0.05rem;
}
.toaddBox .selectList {
  width: 1.92rem;
  height: 0.3rem;
  text-align: left;
  border: 1px solid #b5c4d3;
}
.toaddBox input {
  width: 1.9rem;
  height: 0.3rem;
  text-align: left;
  border: 1px solid #b5c4d3;
   text-indent: 0.1rem;
}
.toaddBox button {
  width: 1rem;
  height: 0.3rem;
  background: #004995;
  border-radius: 0.04rem;
  border: 0px solid;
  line-height: 0.3rem;
  font-size: 0.13rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
}

.searchInput{
  width: 1rem;
  height: 0.3rem;
  margin: 0.05rem 0.05rem;
  border: none;
  border-radius: 0.02rem;
  font-size: 0.13rem; 
}



</style>
