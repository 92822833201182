import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter);

import Login from "../components/login.vue";
import Manager from "../components/manager.vue";
import Approval from "../components/approval.vue";
import Search from "../components/search.vue";
import EnterIndex from "../components/enterIndex.vue";
import PushOrder from "../components/pushOrder.vue";
import Information from "../components/information.vue";
import False from "../components/false.vue";
import Null from "../components/null.vue";
import DiscountReview from "../components/discountReview.vue";
import LiveEvaluation from "../components/liveEvaluation.vue";  //直播评价
import AuditManagement from "../components/auditManagement.vue";   //审核管理
import Relearn from "../components/relearn.vue";   //重学
import ReviewRelearn from "../components/reviewRelearn.vue";   //重学审核/二次修改
import RelearnCheck from "../components/relearnCheck.vue";   // 查看
import RelearnRecords from "../components/relearnRecords.vue";   //冻结记录
import Gift from "../components/gift.vue";   //赠送
import GiftCheck from "../components/giftCheck.vue";   //查看
import GiftReview from "../components/giftReview.vue";   //赠送审核
import Upgrade from "../components/upgrade.vue";   //赠送审核
import UpgradeRecords from "../components/upgradeRecords.vue";   //升级/更换 查看
import UpgradeReview from "../components/upgradeReview.vue";   //升级/更换审核

const router = new VueRouter({
    routes: [{
            path: "/",
            name: "login",
            component: Login
        },
        {
            path: "/manager",
            name: "manager",
            component: Manager
        },
        {
            path: "/approval",
            name: "approval",
            component: Approval
        },
        {
            path: "/search",
            name: "search",
            component: Search
        },
        {
            path: "/enterIndex",
            name: "enterIndex",
            component: EnterIndex
        },
        {
            path: "/pushOrder",
            name: "pushOrder",
            component: PushOrder
        },
        {
            path: "/information",
            name: "information",
            component: Information
        },
        {
            path: "/false",
            name: "false",
            component: False
        },
        {
            path: "/null",
            name: "null",
            component: Null
        },
        {
            path: "/discountReview",
            name: "discountReview",
            component: DiscountReview
        },
        {
            path: "/liveEvaluation",
            name: "liveEvaluation",
            component: LiveEvaluation
        },
        {
            path: "/auditManagement",
            name: "auditManagement",
            component: AuditManagement,
            meta: {
                keepAlive: true, //此组件需要被缓存
            }
           
        },
        {
            path: "/relearn",
            name: "relearn",
            component: Relearn,
            meta: {
                keepAlive: true, //此组件需要被缓存
            }

        },
        {
            path: "/reviewRelearn",
            name: "reviewRelearn",
            component: ReviewRelearn
        },
        {
            path: "/relearnCheck",
            name: "relearnCheck",
            component: RelearnCheck
        },
        {
            path: "/relearnRecords",
            name: "relearnRecords",
            component: RelearnRecords
        },
        {
            path: "/gift",
            name: "gift",
            component: Gift,
            meta: {
                keepAlive: true, //此组件需要被缓存
            }
        },
        {
            path: "/giftCheck",
            name: "giftCheck",
            component: GiftCheck
        },
        {
            path: "/giftReview",
            name: "giftReview",
            component: GiftReview
        },
        {
            path: "/upgrade",
            name: "upgrade",
            component: Upgrade
        },
        {
            path: "/upgradeRecords",
            name: "upgradeRecords",
            component: UpgradeRecords
        },
        {
            path: "/upgradeReview",
            name: "upgradeReview",
            component: UpgradeReview
        },
    ]
});
const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
        return VueRouterPush.call(this, to).catch(err => err)
    }
    // router.beforeEach((to, from, next) => {
    //     if (to.path === '/Index') {
    //         next();
    //     } else {
    //         let token = localStorage.getItem('Authorization');
    //         if (token === 'null' || token === '') {
    //             next('/Index');
    //         } else {
    //             next();
    //         }
    //     }
    // }
    // );
export default router;