<template>
  <div id="app">
    <!-- <Login /> -->
    <router-view class="manager" />
  </div>
</template>

<script>
// import Login from "./components/login.vue";
export default {
  components: {
    // Login,
  },
  data() {
    return {
    };
  },
};
</script>

<style>

</style>
