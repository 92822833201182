<template>
  <div class="reviewRelearn">
    <!-- 基本信息 -->
        <div class="basicInformation">
      <ul>
        <li>
          <span class="reviewTitle">学员姓名：</span>
          <span class="reviewContent">{{giftData.RealName}}</span>
        </li>
        <li>
          <span class="reviewTitle">手机号：</span>
          <span class="reviewContent">{{giftData.Tel}}</span>
        </li>
        <li>
          <span class="reviewTitle lin">申请赠送产品：</span>
        </li>
        <li style="height:100%">
          <p v-for="(item1, index1) in giftData.GiftProduct" :key="index1">{{item1}}</p>
        </li>
        <li>
          <span class="reviewTitle">申请原因：</span>
          <span class="reviewContent">{{giftData.ApplyReason}}</span>
        </li>
        <li>          
              <van-radio-group v-model="resultRadio" direction="horizontal">
                <span class="reviewTitle">审核结果：</span>
                <van-radio name="true">同意</van-radio>
                <van-radio name="false">拒绝</van-radio>
              </van-radio-group>
        </li>
        <li>
          <span class="reviewTitle lin">审核备注：</span>
        </li>
        <li class="hei2">
          <textarea  class="remarks" v-model="ApproveRemark"></textarea>
        </li>
      </ul>
    </div>
    <div class="submitBtn">
      <button @click="SubmitGift()">
        提交
    </button>
    </div>
    <div class="return" @click="OnClickReturn">返回</div>

  </div>
</template>
<script>
import router from "@/router/index.js";
import { Toast } from "vant";
export default {
  name: "reviewRelearn",
  data() {
    return {
      resultRadio:'',
      giftData:{},
      ApproveRemark:''
    };
  },
  mounted(){
    this.getGift();
  },
  methods: {
    getGift(){
      var _this = this;
      _this.axios.post("https://newims.phoneapi.lekaowang.cn/api/Customer/GetGiftVerificationById?ApplyMainId="+_this.$route.query.ApplyMainId)
          .then(function (res) {
            // Code=200 为成功 Code=0为数据为空
            if (res.data.Code == "200") {
              _this.giftData = res.data.Data;
            } else {
              Toast(res.data.Msg);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    SubmitGift(){
      var _this = this;
      if (_this.resultRadio=="") {
        Toast("请选择审核结果！");
        return false;
      }
      if (_this.resultRadio=="false" && _this.ApproveRemark=="") {
        Toast("拒绝通过请填写审批备注！");
        return false;
      }
      _this.axios.post("https://newims.phoneapi.lekaowang.cn/api/Customer/SaveGiftVerification?LoginId="+sessionStorage.getItem('token')+"&ApplyMainId="+_this.$route.query.ApplyMainId+
      "&ApprovalStatus="+_this.resultRadio+"&SaleApproveRemark="+_this.ApproveRemark)
        .then(function (res) {
          // Code=200 为成功 Code=0无数据 Code=-1为内部错误
          if (res.data.Code == "200") {
            Toast("提交成功！");
            router.go(-1);
          } else {
            Toast(res.data.Msg);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    OnClickReturn() {
      router.go(-1); //返回上一层
    },
  },
};
</script>

<style scoped>
.reviewRelearn{
  height: 100%;
}

.basicInformation{
  padding: 0.1rem;
}

.basicInformation ul li{
  width: 3.55rem;
  height: 0.35rem;
  line-height: 0.35rem;
  margin: 0 auto ;
  font-size: 0.15rem;
}
.basicInformation ul li p{
  align-items: center;
  border-top:1px solid ;
  border-left: 1px solid;
  border-right: 1px solid;
  line-height: 0.35rem;
  padding: 0.05rem;
}
.basicInformation ul li p:last-child{
  border-bottom: 1px solid;
}

.reviewTitle{
  font-weight: bold;
}
.reviewtips{
  color: #ff0000;
  font-size: 0.12rem;
}

.between{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/deep/ .van-checkbox__label{
  font-size: 0.15rem;
}
.Status{
  height: 0.35rem;
  font-size: 0.15rem;
  color:#004995 ;
  text-align: right;
}
.score{
  width: 1rem;
  height: 0.3rem;
  font-size: 0.15rem;
}
.remarks{
  width: 100%;
  height: 2rem;
  font-size: 0.15rem;
}
.lin{
  line-height: 0.35rem;
}
.hei2{
  height: 2rem !important;
}


.submitBtn{
  display: flex;
  justify-content: center;
  margin-top: 0.3rem;
  margin-bottom: 2rem;
}
.submitBtn button{
  display: block;
  width: 1.5rem;
  height: 0.35rem;
  border-radius: 5px;
  border: none;
  font-size: 0.15rem;
  color:#fff ;
  background: #004995;
}

/deep/ .van-uploader{
  width: 265px;
}
/deep/ .van-uploader__upload{
  display: none;
}
</style>
