let baseUrl = "";
switch (process.env.NODE_ENV) {
    case 'development':
        // baseUrl = "http://192.168.20.123:12321/"  //开发环境url
        baseUrl = "https://newims.phoneapi.lekaowang.cn/"  //开发环境url
        break
    case 'production':
        baseUrl = "https://newims.phoneapi.lekaowang.cn/"   //生产环境url
        break
    case 'testing':
        baseUrl = "https://newims.phoneapi.lekaowang.cn/"   //测试环境url
        break
}
 
export default baseUrl;